import React from "react";

const DownloadButton = ({ id, className, onClick, content, to, download }) => {
  return (
    <a
      download={download}
      id={id}
      onClick={onClick}
      className={
        "w-full min-w-fit h-fit bg-green text-center text-blue rounded-lg text-base font-semibold hover:brightness-95 " +
        " " +
        className
      }
      href={to}
      target="_blank"
    >
        {content}
    </a>
  );
};

export default DownloadButton;