import React, { useContext, useState } from "react";
import { Tab } from "@headlessui/react";
import TextField from "../../../../../components/input/fields/text-field";
import PrimaryButton from "../../../../../components/buttons/primary-button";
import { UnitField } from "../../../../../components/input/fields/unit-field";
import { CurrencyField } from "../../../../../components/input/fields/currency-field";
import { SelectMenu } from "../../../../../components/input/select/select-menu";
import { DataContext } from "../../..";
import TertiaryButton from "../../../../../components/buttons/tertiary-button";
import { IoTrashBin } from "react-icons/io5";
import { deleteAccomodation, updateAccomodation } from "../../../util/firestore-functions";
import { toast } from "react-toastify";

export const EditAccomodation = ({ accomodationInfo, onClose }) => {
  const context = useContext(DataContext);
  const [index, setIndex] = useState(0);
  const [name, setName] = useState(accomodationInfo.name);
  const [numberRooms, setNumberRooms] = useState(accomodationInfo.numberRooms);
  const [maxPeople, setMaxPeople] = useState(accomodationInfo.maxPeople);
  const [numberBathroom, setNumberBathroom] = useState(accomodationInfo.bathroom);
  const [numberDoubleBed, setNumberDoubleBed] = useState(accomodationInfo.doubleBed);
  const [numberSingleBed, setNumberSingleBed] = useState(accomodationInfo.singleBed);
  const [touristTaxRate, setTouristTaxRate] = useState(accomodationInfo.tax);
  const [city, setCity] = useState(accomodationInfo.address.city);
  const [street, setStreet] = useState(accomodationInfo.address.street);
  const [state, setState] = useState(accomodationInfo.address.state);
  const [cap, setCap] = useState(accomodationInfo.address.cap);
  const [owner, setOwner] = useState(accomodationInfo.owner);
  const [status, setStatus] = useState(accomodationInfo.status);
  const [contractType, setContractTypology] = useState(accomodationInfo.contractTypology);

  var ownersOptions = null;

  const formValues = {
    name: name,
    numberRooms: numberRooms,
    contractTypology: contractType,
    maxPeople: maxPeople,
    numberBathroom: numberBathroom,
    numberDoubleBed: numberDoubleBed,
    numberSingleBed: numberSingleBed,
    touristTaxRate: touristTaxRate,
    address: {
      city: city,
      street: street,
      state: state,
      cap: cap,
    },
    owner: owner,
    status: status
  };

  //#region Options Select
  const contractTypology = [
    {
      label: "Fixed Rent",
      value: "fixed-rent",
    },
    {
      label: "Revenue Share",
      value: "revenue-share",
    },
    {
      label: "Commission-Based",
      value: "commission-based"
    },
    {
      label: "Guaranteed Income",
      value: "guaranteed-income"
    },
    {
      label: "Only Agency",
      value: "only-agency"
    },
    {
      label: "Fee-Based",
      value: "fee-based"
    },
    {
      label: "Hybrid Models",
      value: "hybrid-models"
    },
    {
      label: "Tiered Revenue Share",
      value: "tiered-revenue-share"
    },
    {
      label: "Performance-Based Bonus",
      value: "performance-based-bonus"
    },
    {
      label: "Booking Fee Model",
      value: "booking-fee-model"
    },
    {
      label: "Full Service with Variable Fee",
      value: "full-service-with-variable-fee"
    },
    {
      label: "Retainer Plus Commission",
      value: "retainer-plus-commission"
    },
    {
      label: "Equity Share Model",
      value: "equity-share-model"
    },
    {
      label: "Zero-Fee with Upsells",
      value: "zero-fee-with-upsells"
    },
  ];

  const statusTypology = [
    {
      label: "Active",
      value: "Active"
    },
    {
      label: "Disabled",
      value: "Disabled"
    }
  ];
  //#endregion
  //#region Checking fields
  const update = () => {
    if (
      formValues.name === accomodationInfo.name &&
      formValues.address.cap === accomodationInfo.address.cap &&
      formValues.address.city === accomodationInfo.address.city &&
      formValues.address.state === accomodationInfo.address.state &&
      formValues.address.street === accomodationInfo.address.street &&
      formValues.owner === accomodationInfo.owner &&
      formValues.numberBathroom === accomodationInfo.bathroom &&
      formValues.numberDoubleBed === accomodationInfo.doubleBed &&
      formValues.numberSingleBed === accomodationInfo.singleBed &&
      formValues.touristTaxRate === accomodationInfo.tax &&
      formValues.maxPeople === accomodationInfo.maxPeople &&
      formValues.contractTypology === accomodationInfo.contractTypology &&
      formValues.status === accomodationInfo.status &&
      formValues.numberRooms === accomodationInfo.numberRooms
    ) {
      toast.error("No changes made");
      return;
    }

     if (formValues.name === "") {
        toast.error("An accommodation needs a name");
        return;
      }

      if (city === "") {
        toast.error("An accommodation needs a city");
        return;
      }

      if (!formValues.owner) {
        toast.error("An accommodation needs an owner");
        return;
      }

      updateAccomodation(accomodationInfo.id, formValues, context.organizationId)
      .then(() => {
        context.updateData("accomodations");
        onClose();
      })
      .catch((err) =>
            {
              toast.error("An error occurred: " + err);
            });
  };
  //#endregion
  
  if (!useContext(DataContext).loading) {
    ownersOptions = context.ownersList.map((item) => {
      if (item.id != accomodationInfo.id)
        return {
          label: item.data.lastName + " " + item.data.firstName,
          value: item.id,
        };
    });
  }

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  let categories = {
    Details: [
      {
        input: (
          <>
          <TextField
            label={"Name"}
            inputClassName={"p-1 mb-5"}
            placeholder={"Accommodation name"}
            defaultValue={name}
            onChange={(e) => setName(e.target.value)}
          />

          <SelectMenu
          label={"Contract Typology"}
          className={"ml-0 mb-5"}
          isSearchable={false}
          isClearable={false}
          defaultValue={contractType}
          options={contractTypology}
          placeholder={"Select the Typology of contract"}
          onChange={(e) => setContractTypology(e)}
        />
        <SelectMenu
              label={"Status"}
              className={"ml-0 mb-5"}
              isSearchable={false}
              isClearable={false}
              defaultValue={status}
              options={statusTypology}
              placeholder={"Select the status"}
              onChange={(e) => setStatus(e)}
            />
        </>
        ),
      },
      {
        input: (
          <div className="flex flex-wrap justify-around my-1">
            <div className="flex justify-between w-full">
            <UnitField
              label={"N° of rooms"}
              className={"w-fit"}
              inputClassName={"w-[130px] p-1"}
              placeholder={"0"}
              defaultValue={numberRooms}
              onChange={(e) => setNumberRooms(Number(e.target.value))}
            />
              <UnitField
                label={"Max people"}
                inputClassName={"w-[130px] p-1"}
                placeholder={"0"}
                defaultValue={maxPeople}
                className={"w-fit"}
                onChange={(e) => setMaxPeople(Number(e.target.value))}
              />
                            <UnitField
                label={"N°. double bed"}
                inputClassName={"w-[130px] p-1"}
                placeholder={"0"}
                defaultValue={numberDoubleBed}
                className={"w-fit"}
                onChange={(e) => setNumberDoubleBed(Number(e.target.value))}
              />
            </div>
            <div className="flex justify-between w-full my-1 mt-10 mb-10">
              <UnitField
                label={"N°. single bed"}
                inputClassName={"w-[130px] p-1"}
                placeholder={"0"}
                defaultValue={numberSingleBed}
                className={"w-fit"}
                onChange={(e) => setNumberSingleBed(Number(e.target.value))}
              />
              <UnitField
                label={"N°. bathroom"}
                inputClassName={"w-[130px] p-1 "}
                placeholder={"0"}
                defaultValue={numberBathroom}
                className={"w-fit"}
                onChange={(e) => setNumberBathroom(Number(e.target.value))}
              />
              <CurrencyField
                label={"Tourist tax rate"}
                inputClassName={"w-[100px] p-1"}
                placeholder={"0.00"}
                value={touristTaxRate}
                className={"w-fit"}
                onChange={(e) => setTouristTaxRate(Number(e.target.value))}
              />
            </div>
          </div>
        ),
      },
    ],
    Address: [
      {
        input: (
          <TextField
            label={"Street"}
            inputClassName={"p-1 mb-4"}
            placeholder={"Insert..."}
            defaultValue={street}
            onChange={(e) => setStreet(e.target.value)}
          />
        ),
      },
      {
        input: (
          <div className="flex flex-wrap">
            <TextField
              label={"City *"}
              inputClassName={"p-1 mb-4"}
              placeholder={"Insert..."}
              defaultValue={city}
              onChange={(e) => setCity(e.target.value)}
             />
            <TextField
              label={"State/province"}
              inputClassName={"p-1 mb-4"}
              placeholder={"Insert..."}
              defaultValue={state}
              onChange={(e) => setState(e.target.value)}
            />
            <TextField
              label={"Zip/Postal code"}
              inputClassName={"p-1 mb-4"}
              placeholder={"Insert..."}
              defaultValue={cap}
              onChange={(e) => setCap(e.target.value)}
            />
          </div>
        ),
      },
    ],
    Owner: [
      {
        input: (
          <SelectMenu
            label={"Owner"}
            options={ownersOptions}
            isSearchable
            onChange={(e) => setOwner(e.value)}
            defaultValue={ownersOptions.find((x) => {
              return x.value === owner;
            })}
          />
        ),
      },
    ],
  };

  return (
    <div className="w-full">
      <Tab.Group selectedIndex={index} onChange={(value) => setIndex(value)}>
        <Tab.List className="flex p-1 space-x-1 rounded-xl bg-blue-900/20 bg-neutral-50 text-blue">
          {Object.keys(categories).map((category) => (
            <Tab
              key={category}
              className={({ selected }) =>
                classNames(
                  "w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-blue-700",
                  "ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2",
                  selected
                    ? "shadow bg-green font-extrabold"
                    : "text-blue-100 hover:bg-white/[0.12] hover:text-petroil"
                )
              }
            >
              {category}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels className="my-1 min-h-[25rem]">
          {Object.values(categories).map((formValues, idx) => (
            <Tab.Panel
              key={idx}
              className={classNames(
                "rounded-xl bg-white p-3",
                "ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2"
              )}
            >
              {formValues.map((item, key) => (
                <div key={key}>{item.input}</div>
              ))}
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
      <div className="flex justify-end">
        <TertiaryButton
          onClick={onClose}
          className={"bg-red p-2 w-fit mr-5 max-w-[80px]"}
          content={"Close"}
        />
        <PrimaryButton
          className={"p-2 w-fit h-auto bg-green text-blue max-w-[80px]"}
          content={"Update"}
          onClick={update}
        />
      </div>
    </div>
  );
};
