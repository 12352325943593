import { useState, useEffect } from "react";
// import { Switch } from "@headlessui/react";
import { PhoneField } from "../../../../components/input/fields/phone-field";
import {
  getCountries,
  getCountryCallingCode,
  isValidPhoneNumber,
} from "react-phone-number-input";
import { SelectMenu } from "../../../../components/input/select/select-menu";
import {
  getAuth,
  RecaptchaVerifier,
  updateProfile,
  PhoneAuthProvider,
} from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { getFunctions, httpsCallable } from "firebase/functions";
import TextField from "../../../../components/input/fields/text-field";
import SecondaryButton from "../../../../components/buttons/secondary-button";
import { PhoneVerificationDialog } from "../../../../components/dialogs/presets/phone-verificationDialog";
import { toast } from "react-toastify";

const ExtraInfo = () => {
  // Auth
  const auth = getAuth();
  // Error handler
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");
  // User agreements
  const [newsLetterAgreement, setNewsLetterAgreement] = useState(false);
  // Confirm number dialogo
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedPrefix, setSelectedPrefix] = useState();
  const [phoneNumber, setPhoneNumber] = useState();

    /* Select Options */
    const [options, setOptions] = useState();

  // Navigator
  const navigate = useNavigate();

  const formValues = {
    fullName: selectedPrefix,
    phoneNumber: phoneNumber,
  };

    /* Init Options */
    useEffect(() => {
      setOptions(
        getCountries().map((countryCode) => {
          return { label: countryCode, value: countryCode };
        })
      );
    }, []);

  //CloudFunction
  const functions = getFunctions();
  const newsLetterAgreementCloud = httpsCallable(
    functions,
    "newsLetterAgreement"
  );

  const setupRecaptcha = async () => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      "sign-in-button",
      {
        size: "invisible",
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          resolve(response);
        },
        "expired-callback": (error) => {
          reject(error);
        },
      },
      auth
    );
  };

  const addExtraInfo = () => {
    var formattedPhoneNumber = null;
    // Clean up the useroutput
    setError(false);
    setMessage("");

    // Fields check
    if (!auth.currentUser.displayName) {
      if (formValues.fullName == "" || formValues.phoneNumber == "") {
        setError(true);
        setMessage("Check fields");
        toast.error("The Full Name is mandatory");
        return;
      }
    }

    if (!selectedPrefix) {
      toast.error("The prefix is mandatory");
      return;
    }

    if (!phoneNumber) {
      toast.error("The phone number is mandatory");
      return;
    }

    formattedPhoneNumber = "+" + getCountryCallingCode(selectedPrefix.value) + phoneNumber;
    formattedPhoneNumber = String(formattedPhoneNumber).replace(/(\(|\))|\s|-/g, "");

    if (!isValidPhoneNumber(formattedPhoneNumber)) {
      setError(true);
      setMessage("Invalid phone number provider");
      toast.error("Invalid phone number provider");
      return;
    }

    // Update profile
    setupRecaptcha()
      .then(async () => {
        // Render recaptcha
        window.recaptchaVerifier.render();
        // Getting verificatio id
        window.verificationId = await new PhoneAuthProvider(
          auth
        ).verifyPhoneNumber(formattedPhoneNumber, window.recaptchaVerifier);
        if (!window.verificationId) {
          setError(true);
          setMessage("Sorry an error occurred");
          toast.error("Sorry an error occurred");
          return;
        }
        setDialogOpen(true);
        newsLetterAgreementCloud({
          newsLetterAgreement: newsLetterAgreement,
        });
        if (formValues.fullName) {
          updateProfile(auth.currentUser, { displayName: formValues.fullName })
            .then(() => {
               // TODO: manage 
            })
            .catch((error) => {
              toast.error("An error occurred: " + error);
            });
        }
      })
      .catch(() => {
        toast.error("Database error");
        navigate("");
      });
  };
  return (
    <div>
      <div className="flex items-center justify-center h-fit mb-28">
        <div className="max-w-md m-10">
          <div className="flex flex-col items-center justify-center px-16 text-petroil">
            <img
              className="h-auto w-fit"
              alt="Bilemon logo"
              src="/Logo-Petroil.png"
            />
          </div>
          <div>
            <p className="mt-4 font-bold text-center text-petroil">
              One last step!
            </p>
          </div>

          <div className="space-y-5">
            {error && <p className="my-4 text-center text-red">{message}</p>}
            {!auth.currentUser.displayName && (
              <TextField
                label={"Full name *"}
                inputClassName={"p-2"}
                placeholder={"Insert your first and last name"}
                onChange={(e) => (formValues.fullName = e.target.value)}
                value={formValues.fullName}
              />
            )}
                    <div className="flex items-center justify-between w-full mt-3">
          <SelectMenu
            label={"Prefix *"}
            placeholder={"select"}
            className={"w-36 mr-5"}
            inputClassName={"p-1"}
            options={options}
            onChange={(item) => {
              setSelectedPrefix(item);
            }}
            value={selectedPrefix}
          />
          <TextField
            label={"Phone number *"}
            inputClassName={"p-1"}
            placeholder={"Insert your phone number"}
            onChange={(e) => setPhoneNumber(e.target.value)}
            defaultValue={phoneNumber}
          />
        </div>

            {/* <div className="flex items-center w-full">
              <PhoneField
                defaultValue={formValues.phoneNumber}
                label={"Phone number *"}
                inputClassName={"p-2"}
                onChange={(e) => {
                  formValues.phoneNumber = e.target.value;
                }}
              />
            </div> */}
            {/* <div className="flex items-center">
              <Switch
                checked={newsLetterAgreement}
                onChange={setNewsLetterAgreement}
                className={`${newsLetterAgreement ? "bg-petroil" : "bg-blue"}
          relative inline-flex h-[28px] w-[54px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
              >
                <span className="sr-only">Use setting</span>
                <span
                  aria-hidden="true"
                  className={`${
                    newsLetterAgreement ? "translate-x-[26px]" : "translate-x-0"
                  }
            pointer-events-none inline-block h-[24px] w-[24px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                />
              </Switch>
              <h5 className="ml-2">I want to subscribe to the newsletter</h5>
            </div>
            <h5 className="mt-3 text-sm">
              By clicking Sign up, you agree to Bilemon's User Agreement,
              Privacy Policy, and Cookie Policy.
            </h5> */}
            <div className="flex mt-5">
              <SecondaryButton
                className={"p-2"}
                id={"sign-in-button"}
                onClick={addExtraInfo}
                content={"Complete my account"}
              />
            </div>
            <div className="text-sm text-right text-petroil">
              <PhoneVerificationDialog
                status={dialogOpen}
                phoneNumber={formValues.phoneNumber}

                onComplete={(resl) => {
                  if (resl) {
                    setDialogOpen(false);
                    navigate("../organization-setup");
                    return;
                  }
                }}
                onClose={() => {
                  setDialogOpen(false);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExtraInfo;
