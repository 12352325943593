import TextField from "../../../../../components/input/fields/text-field";
import React, { useState } from "react";
import PrimaryButton from "../../../../../components/buttons/primary-button";
import TertiaryButton from "../../../../../components/buttons/tertiary-button";
import { toast } from "react-toastify";
import { isValidPhoneNumber } from "libphonenumber-js/core";

export const NewOtaCommission = ({ onComplete, otaCommission, onCloseModal }) => {
  const [name, setName] = useState(otaCommission ? otaCommission.name : null);
  const [category, setCategory] = useState(otaCommission ? otaCommission.category : null);

  const regexNumber = /^\+*[0-9]+$/g;
  const checkFields = () => {
    if (!name) {
      toast.error("Please, provide a name");
      return;
    }
    return true;
  };
  return (
    <div>
      <div className="flex flex-wrap ">
        <TextField
          label={"Name *"}
          placeholder={"Insert..."}
          value={name}
          className={"max-w-xl"}
          inputClassName={"p-1"}
          onChange={(e) => setName(e.target.value)}
        />
      </div>
      <div className="flex items-center justify-end w-full">
        {" "}
        <TertiaryButton
          onClick={onCloseModal}
          className={"bg-red p-2 w-fit mr-5 mt-5"}
          content={ "Close "}
        />
        <PrimaryButton
          content={otaCommission ? "Edit Ota Commission" : "Add new Ota Commission"}
          className={"p-2 w-fit mt-5"}
          onClick={() => {
            //TODO: da rivedere
            if (checkFields())
              onComplete( otaCommission ? { id: otaCommission.id, name: name } : { name }
              );
          }}
        />
      </div>
    </div>
  );
};
