import { useState, useEffect } from "react";
import { UilBuilding, UilMoneyBill } from "@iconscout/react-unicons"; 

export const SubscriptionCard = ({
    plan,
    numberProperty,
    amount,
    onClick,
    value,
    description,
    amountExtra
}) => {
    const name = plan === "host" ? "Host": "Property Manager";
    const priceLabel = plan === "host" ? "Price:" : "Price from:";
    const propertyLabel = plan === "host" ? "Maximum properties:" : "Minimum properties:";

    return (
        <>
            <div
                onClick={onClick}
                value={value}
                className={"px-10 py-5 cursor-pointer bg-white border-2 min-w-[450px] hover:border-petroil hover:shadow-lg rounded-lg shadow-sm border-1"}
            >
                {/* Main Section */}
                <div className="flex flex-col space-y-3">
                    <h1 className="text-2xl  text-center font-bold">{name}</h1>
                </div>
                {/* Info Section */}
                <div className="my-4 space-y-2 text-base">
                    <h3 className="flex items-center font-semibold">
                        <UilBuilding className="w-5 h-auto mr-2 text-petroil" />
                        {propertyLabel} <p className="ml-1 font-normal">{numberProperty}</p>
                    </h3>
                </div>
                <div className="my-4 space-y-2 text-base">
                    <h3 className="flex items-center font-semibold">
                        <p className="ml-1 font-normal">{description}</p>
                    </h3>
                </div>
                <hr className="w-full mt-8" />
                {/* Button Section */}
                <div className="flex items-center justify-between mt-4">
                    <h2 className="flex items-center font-semibold">
                        {priceLabel}
                    </h2>
                    <div className="flex items-center space-x-1 text-base">
                        <UilMoneyBill className={"text-petroil mr-2 w-6 h-auto"} />
                        <h3>
                            €{amount}
                        </h3>
                    </div>                        
                </div>    
                {plan === "propertyManager"  && (
                    <>
                        <div className="flex items-center justify-between mt-4">
                            <h2 className="flex items-center font-semibold">
                                Extra price: 
                            </h2>
                            <div className="flex items-center space-x-1 text-base">
                                <h3>
                                   €{amountExtra} / per property
                                </h3>
                            </div>                        
                        </div>  
                    </>                            
                )}    
            </div>       
        </>
    )
}
