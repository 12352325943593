// InfoData.js

export const Info = [
  {
    id: "accommodations",
    title: "Accommodations",
    content: `<p class="mb-4">The first step in the Business Intelligence data collection process is configuring accommodations.</p>
    <p class="mb-4">Inserting accommodations is a simple task. There are only a few required details and some optional ones that can be important for performance analysis.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">How do I add a new Accommodation?</h3>
    <p class="mb-4">In the "Accommodations" section, click "Add a new accommodation" to enter the data of the new accommodation, with the essential characteristics.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">What is the mandatory information?</h3>
    <ul class="list-disc pl-6 mb-4">
        <li>Name</li>
        <li>Street</li>
        <li>City</li>
        <li>State or Province</li>
        <li>Zip Postal Code</li>
        <li>Owner</li>
    </ul>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">What is meant by Contract Typology?</h3>
    <p class="mb-4">By choosing the "Contract Typology" it is possible to select the form of contract that has been stipulated with the owner of the “Accommodation”.</p>
    <p class="mb-4">This information is crucial because choosing a form of management for the Accommodation determines the business model, cost, and revenue strategies.</p>
    <p class="mb-4">Let's take an example, if we manage an accommodation under a revenue share model, we should create a cost rule that allocates a percentage of each booking's revenue to the owner.</p>
    <p class="mb-4">Maximizing profits while providing fair compensation to owners is essential to running a successful accommodation business under a revenue-sharing model.</p>
    <p class="mb-4">By implementing a cost rule that allocates a percentage of each booking's revenue to the owner, we ensure that everyone benefits from the business's success. Let's create a cost rule that is fair and transparent, giving owners peace of mind and fostering a positive long-term relationship.</p>
    <p class="mb-4">Opting for a fixed rent accommodation? You'll need to set up a cost rule that ensures timely payment of monthly rent.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Below is a list of the main contracts:</h3>
    <p class="mb-4"><span class="text-lg text-petroil">Fixed Rent:</span> The property manager agrees to pay the owner a fixed monthly rent in this arrangement. The manager then tries to earn more than this amount by renting the property out. Any profit above the fixed rent is retained by the manager, but the manager also bears the risk of any shortfall.</p>
    <p class="mb-4"><span class="text-lg text-petroil">Revenue Share:</span> Under a revenue share agreement, the property manager and owner split the rental income based on an agreed-upon percentage. For example, the manager might keep 30% of the revenue as a management fee, while the owner receives 70%.</p>
    <p class="mb-4"><span class="text-lg text-petroil">Commission-Based:</span> The property manager only earns a commission for each booking they secure. This is often common with agencies that list multiple properties. The commission can vary but typically ranges between 10% to 25% of the booking revenue.</p>
    <p class="mb-4"><span class="text-lg text-petroil">Guaranteed Income:</span> This is a hybrid between fixed rent and revenue share. The property manager guarantees the owner a minimum amount each month (less than the fixed rent model) and then shares any revenue above this guaranteed amount based on an agreed percentage.</p>
    <p class="mb-4"><span class="text-lg text-petroil">Only Agency:</span> In this model, the property manager acts as an agent to list and market the property. They do not handle operations like guest services, maintenance, or cleaning. They charge either a flat fee or a commission for each booking they secure.</p>
    <p class="mb-4"><span class="text-lg text-petroil">Fee-Based:</span> The property manager charges the owner for specific services, like cleaning, maintenance, or guest relations. This is more of an à la carte service where the owner selects which services they want the manager to handle.</p>
    <p class="mb-4"><span class="text-lg text-petroil">Hybrid Models:</span> Sometimes, combinations of the above models are used based on the specific needs of the property owner and the expertise and services offered by the property manager.</p>
    <p class="mb-4"><span class="text-lg text-petroil">Tiered Revenue Share:</span> Instead of a flat percentage, the revenue share between the property owner and manager changes based on revenue tiers. For example, for the first $5,000 in monthly revenue, the manager might take a 20% cut, but for revenue between $5,001 and $10,000, the cut might be reduced to 15%.</p>
    <p class="mb-4"><span class="text-lg text-petroil">Performance-Based Bonus:</span> This model can be added to others. Here, the property manager receives a bonus if certain targets or KPIs (Key Performance Indicators) are achieved, like maintaining an average 90% occupancy rate over a quarter or achieving a certain number of 5-star reviews.</p>
    <p class="mb-4"> <span class="text-lg text-petroil">Booking Fee Model:</span> Instead of a commission on the rental price, the manager charges a flat fee for every booking made, irrespective of the rental amount.</p>
    <p class="mb-4"><span class="text-lg text-petroil">Full Service with Variable Fee:</span> The manager handles all aspects of the short-term rental operation, from listing to guest services to maintenance. The fee is variable based on the amount of work in a particular month. For instance, if there's higher occupancy and more maintenance issues, the fee might be higher.</p>
    <p class="mb-4"> <span class="text-lg text-petroil">Retainer Plus Commission:</span> The property manager receives a fixed retainer fee for basic services, and then a commission on top for every booking. This ensures the manager has a stable income while still being incentivized to get bookings.</p>
    <p class="mb-4"> <span class="text-lg text-petroil">Equity Share Model:</span> This is less common, but in some situations, especially with startups or larger property ventures, the manager might take an equity stake in the property or business instead of (or in addition to) cash payments.</p>
    <p class="mb-4"><span class="text-lg text-petroil">Zero-Fee with Upsells:</span> Here, the manager might not charge any upfront fee or commission for the booking but makes money through upselling additional services to guests like tours, transportation, or in-house amenities.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Is it possible to deactivate an Accommodation?</h3>
    <p>Absolutely yes.</p>
    <p class="mb-4">In case an accommodation is no longer being temporarily managed or its marketing has been closed due to reasons such as renovations, maintenance, or seasonal markets, you can choose the status "Disabled" in the "Accommodation Details" section.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Why deactivate an Accommodation?</h3>
    <p class="mb-4">The reasons why we could change the "Status" of an Accommodation, moving it from Active to Disabled, can be various: for example, because it is a seasonal market where we only work in summer, and therefore we should change the status; It may be necessary to close for a specific period to carry out extraordinary maintenance.</p>
    <p class="mb-4">One possible reason for this could be if you are redesigning your property or if you operate in a market that allows only a limited number of days for rentals.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Can the details of an accommodation be modified?</h3>
    <p class="mb-4">Click on "Edit" to modify Accommodation data.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Can I delete an Accommodation from the list?</h3>
    <p class="mb-4">No, you cannot delete an Accommodation, but you can deactivate it. The reason for this is that there may be costs or bookings associated with the accommodation which cannot be attributed to the cancelled accommodation.</p>
    <p class="mb-4">Therefore, we recommend deactivating it to prevent any potential data loss.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Is it possible to assign the same owner to more than one Accommodation?</h3>
    <p>Yes, you can do it!</p>
    <p class="mb-4">It is possible to assign the same owner to multiple accommodations if needed. An Accommodation can't have multiple owners. In the case of multiple owners, a double owner can be created in the "Owner" section and then assigned to the Accommodation.</p>`
  },
  {
    id: "costRules",
    title: "Cost Rules",
    content: `<p class="mb-4">The second step in configuring data collection for Business Intelligence is the Cost Rules section. Don't miss out on this crucial step in setting up your system for success! Property Managers can proactively set up automatisms that generate nearly all the costs involved in managing a property. This innovative feature makes BiLemon the go-to solution for efficient property management.</p>
    <p class="mb-4">The configuration of the cost rules is extremely intuitive, considering that the costs are classified into three typologies, Company Booking and Accommodation Costs.</p>
    <p class="mb-4">Once the typology has been selected, the individual cost must be selected from the "Category" menu. For each typology, there are specific and related costs, to be able to classify and allocate according to business logic.</p>
    <p class="mb-4">After selecting Typology and Category, choose the Accommodation (not required for "Company Costs") and define variables for cost rule:</p>
    <ol class="list-decimal pl-6 mb-4">
        <li>“Recurrency”</li>
        <li>“Start Date”</li>
        <li>“Payment amount”</li>
    </ol>
    <p class="mb-4">In the case of Booking Costs, the "recurrency" becomes a "trigger", but the logic remains the same: identifying the event that generates the cost.</p>
    <p class="mb-4">In the case of Booking Costs, there are three Triggers:</p>
    <ol class="list-decimal pl-6 mb-4">
        <li>Booking Date</li>
        <li>Check-in Date</li>
        <li>Check-out Date</li>
    </ol>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">What are cost rules and what are they for?</h3>
    <p>Automated cost rules streamline your workflow by automatically generating costs when specific events occur, saving you time and effort.</p>
    <p>By configuring specific rules, the Property Manager can effortlessly generate the relative cost based on any given event. Streamlining this process not only saves time but also ensures accuracy and consistency in cost calculation.</p>
    <p>Upon receiving a booking, we know that additional costs such as cleaning, check-in, and OTA commissions will be incurred.</p>
    <p>With the cost rules, there's no need to manually enter costs for each booking. The system automatically generates costs based on the event (in this case, the booking).</p>
    <p>Thanks to this innovative approach, it is possible to automate cost entry and management.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">How do you create a cost rule?</h3>
    <p>To create a new cost rule, follow these steps:</p>
    <ol class="list-decimal pl-6 mb-4">
        <li>Assign a name to the rule.</li>
        <li>Choose the Cost Typology, between Booking/Accommodation/Company.</li>
        <li>Select one or more Accommodations (only for Booking and Accommodation Costs).</li>
        <li>Choose the Cost Category.</li>
        <li>Assign a Sub-Category (optional).</li>
        <li>Choose the Supplier (optional).</li>
        <li>Define the Trigger (optional) or Recurrency for which the cost must be generated.</li>
        <li>Define a start date.</li>
        <li>Establish the Payment Type (only for Booking Costs).</li>
        <li>Determine the cost amount.</li>
        <li>Define an end date.</li>
    </ol>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">What are the required fields for a cost rule?</h3>
    <ol class="list-decimal pl-6 mb-4">
        <li>Cost rule name.</li>
        <li>Cost typology.</li>
        <li>Accommodations (only for Booking and Accommodation Costs).</li>
        <li>Category.</li>
        <li>Recurrency.</li>
        <li>Payment type (only for Booking Costs).</li>
        <li>Payment amount.</li>
    </ol>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">What does the term "Cost rule name" mean?</h3>
    <p>"Cost rule name" refers to the name assigned by the Property Manager to its cost rule.</p>
    <p>An example is the "Clearing Cost Rule," which generates costs for cleaning during check-out. If the rule were only named "Final Cleaning," it wouldn't make any difference.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">What is the "Cost Typology"?</h3>
    <p>In BiLemon we classify all costs into three large typologies:</p>
    <ol class="list-decimal pl-6 mb-4">
        <li>Accommodation Costs.</li>
        <li>Booking Costs.</li>
        <li>Company Costs.</li>
    </ol>
    <p>Depending on the typology chosen, there will be specific and relative cost categories.</p>
    <p>Let's take an example, if the Property Manager chooses the "Booking" Typology, the system generates a series of cost categories such as Amenities, Check-in/out, Cleaning, Laundry, Ota Commission, etc. That is all those costs that are generated when you receive a booking.</p>
    <p>Let's take another example, if the Property Manager chooses the "Accommodation" typology, the system generates a series of cost categories, such as Community Fee, Owner Payment, PMS, Channel Manager, Furniture, Insurance, Maintenance, supplies, etc.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">What is the "Cost Category"?</h3>
    <p>The cost categories represent the real costs that affect all property management.</p>
    <p>As already explained for the typologies, different categories of costs relate to the typologies.</p>
    <p>Each typology has a set of cost categories associated with it.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">What is meant by "Sub-Category"?</h3>
    <p>Numerous costs have subcategories, allowing for granularity in cost analysis.</p>
    <p>Let's take an example, Let's say we have to cover maintenance costs for the swimming pool in a villa:</p>
    <ul class="list-disc pl-6 mb-4">
        <li>The Typology will be "Accommodation",</li>
        <li>The Category will be "Maintenance" and,</li>
        <li>The Sub-Category will be "Pool Maintenance".</li>
    </ul>
    <p>Let's take another example, we have a fixed monthly cost for providing internet in the office:</p>
    <ul class="list-disc pl-6 mb-4">
        <li>The Typology will be "Company",</li>
        <li>The Category will be "Office Expenses" and,</li>
        <li>The Sub-Category will be "Wi-Fi Internet connection".</li>
    </ul>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">How does configure the "Recurrency" of a cost rule?</h3>
    <p>The "Recurrency" of a cost rule represents the periodicity with which a cost can be generated.</p>
    <p>Let's consider an example: warehouse rental fees can be charged on a monthly or annual basis. Additionally, there is the option to customize the recurrence if it doesn't occur daily, weekly, monthly, or yearly.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">What is meant by "custom" in the recurrency setup?</h3>
    <p>If the event that triggers the cost does not occur on a daily, weekly, monthly, or yearly basis, it is possible to customize the frequency.</p>
    <p>In this case, if we pay a supplier every 2 months, we should choose "Custom" for Recurrency and set "Number of days" to 60.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">What is a Trigger?</h3>
    <p>The term "Trigger" refers to the specific event that causes a Booking cost to be incurred.</p>
    <p>The Trigger only applies to Booking costs and the Recurrency applies to both Accommodation costs and Company costs. The Trigger has three options:</p>
    <ol class="list-decimal pl-6 mb-4">
        <li>Booking Date.</li>
        <li>Check-in Date.</li>
        <li>Check-out Date.</li>
    </ol>
    <p>The Trigger is not mandatory information. If not configured, the default system considers the Booking Date.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">What is "Payment Type"?</h3>
    <p>The "Payment Type" field, available only for Booking Costs, determines whether a cost is calculated as a percentage or fixed amount.</p>
    <p>Let's consider two examples of different payment types. The OTA commission is typically a percentage-based cost calculated on the booking amount.</p>
    <p>However, the cost of cleaning is typically a fixed amount based on the amount of time dedicated to cleaning.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">How many cost rules can I create?</h3>
    <p>It is possible to create a detailed and exhaustive cost structure without any limitations.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Can I create the same cost rule multiple times but with different values?</h3>
    <p>The answer is yes.</p>
    <p>Taking an example, the cost rule generates the commission calculation for different booking channels. The OTA commission rule remains the same, but the percentage values vary depending on the channels.</p>
    <p>Or, calculating cleaning costs can vary based on Accommodation characteristics, requiring different amounts of time.</p>
    <p>For these specific cases, we can create multiple rules with different parameters.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">What happens when a cost rule is deleted?</h3>
    <p>The only consequence of deleting a cost rule is that it will stop generating costs based on that rule.</p>
    <p>Please note that canceling the cost rule does not mean that any previously generated costs will also be canceled.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Can I apply a cost rule to multiple Accommodations at the same time?</h3>
    <p>The answer is yes.</p>
    <p>If we take the OTA Commission example and apply it to Airbnb, the same calculation applies to all accommodations listed on this booking channel.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Is it possible to insert a cost rule with past dates?</h3>
    <p>No, the cost rules are not retroactive.</p>
    <p>There is a risk of incurring an infinite number of costs for past events.</p>
    <p>If a Property Manager needs to add costs for past data, they can use the "Add a new cost" function under the "Costs" section.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">If I don't choose a cost typology, why am I unable to see any cost category?</h3>
    <p>All costs are classified into three typologies; If you do not select a typology, then the cost category will not be displayed.</p>`
  },
  {
    id: "costs",
    title: "Costs",
    content: `<h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">The Costs section integrates the Cost Rules.</h3>
    <p>In this section, we will include all costs that do not follow a specific rule or are generated occasionally or one-off.</p>
    <p>This section provides you with a significant level of detail regarding management control, allowing you to reach a higher level of granularity.</p>
    <p>It is important to classify, allocate, and include the cost of even a very cheap light bulb!</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">What is the difference between Cost Rules and Costs?</h3>
    <p>Cost Rules are automated processes used to calculate costs when specific events occur (e.g. bookings for variable costs) or when costs are known to occur periodically (e.g. fixed costs).</p>
    <p>Costs can be generated through cost rules or created immediately if they don't follow specific rules, can't be automated, or occur irregularly.</p>
    <p>Let's take an example of Cost, the purchase of a pillow, which is not a recurring cost, does not depend on any ordinary event, and is a one-off purchase.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">What are the mandatory fields to add a cost?</h3>
    <ol class="list-decimal pl-6 mb-4">
        <li>Cost typology.</li>
        <li>Accommodations (only for Booking and Accommodation Costs).</li>
        <li>Category.</li>
        <li>Cost date.</li>
        <li>Payment type (only for Booking Costs).</li>
        <li>Payment amount.</li>
    </ol>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">What is the difference between “Cost Date” and “Payment Date”? Why is “Cost Date” mandatory and “Payment Date” is not?</h3>
    <p>The Cost Date indicates the date on which a specific cost is generated.</p>
    <p>The Payment date instead indicates the date on which that cost will be paid.</p>
    <p>This differentiation is mainly used to analyze cash flow.</p>
    <p>Let's take an example, on September 1st, 2024, we purchased a new kitchen table for our apartment using our credit card.</p>
    <p>The cost will be charged on the first day of the following month (October 1st, 2024).</p>
    <p>The Cost Date is a mandatory field required for temporal cost assignment.</p>
    <p>The Payment Date is not always required, as this date distinction may not always be applicable.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">When I enter the same cost for two Accommodations, does the amount remain the same for both Accommodations or is it split?</h3>
    <p>The cost for two accommodations is not shared; instead, two separate costs of the same amount are created for each accommodation.</p>
    <p>It's a way to optimize and save time.</p>
    <p>Let's say we purchase two identical chairs for two different accommodations at the same cost. Instead of assigning a cost to each chair, we assign a cost to each accommodation and then select the two accommodations.</p>
    <p>The system will automatically charge the cost of the chair for each Accommodation.</p>
    <p>To do this we did not need to create two distinct costs, but rather a single cost by selecting the two Accommodations.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Is it possible to enter a cost for future dates? And what about the past ones?</h3>
    <p>Yes, it is possible to enter a cost for both future and past dates.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Can I make changes or delete a previously entered cost?</h3>
    <p>The answer is yes.</p>
    <p>Costs can be adjusted or canceled depending on the need, and changes may impact other specifications like date and accommodation.</p>`

  },
  {
    id: "bookings",
    title: "Bookings",
    content: `<h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">The Bookings section is where reservations are entered, representing the primary source of revenue for Property Managers.</h3>
    <p>This step can be considered as the final step for Business Intelligence data collection.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">How do I make a booking?</h3>
    <p>To add a new booking, you can use the "Add a new booking" function.</p>
    <p>It is possible to import multiple bookings at once by uploading an Excel file.</p>
    <p>We recommend using the "Download Sample" function to get the correct format, populating it with the reservations to be inserted, and importing it through the "Import" function.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">What is the mandatory information to enter for a Booking?</h3>
    <ol class="list-decimal pl-6 mb-4">
        <li>Booking Date.</li>
        <li>Check-in/Out dates.</li>
        <li>No Guests.</li>
        <li>Amount.</li>
        <li>Accommodation.</li>
    </ol>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Can I make changes or delete a previously entered booking?</h3>
    <p>Yes, it is always possible to modify or cancel a booking.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">If I make changes to a booking, will the associated costs be updated accordingly?</h3>
    <p>The answer is yes.</p>
    <p>Modifying any information related to booking cost rules will affect generated costs.</p>
    <p>If a booking's check-out date is changed, the date of the cleaning cost will adjust based on the previously set rule.</p>
    <p>The same for the calculation of costs that are based on percentages, that is affected by changes in booking amounts.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">How can I enter multiple bookings at the same time?</h3>
    <p>To import multiple reservations at once, use an Excel file.</p>
    <p>BiLemon allows users to download a pre-formatted Excel file.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Why can't I find a Booking Channel? How do I add a Booking Channel?</h3>
    <p>To answer certain questions, we must make a premise: The OTA Commission is a COST and must be managed in the costs section. First, we create cost rules for commission calculations.</p>
    <p>You can configure a Booking Channel in the OTA section, but without creating a cost rule, it will still not be visible.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">How can I order the booking table?</h3>
    <p>The bookings table can be sorted by Booking date or Check-in date.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Is it possible to add a Booking retroactively?</h3>
    <p>Yes, it is always possible to add a Booking retroactively.</p>`
  },
  {
    id: "ota",
    title: "OTA",
    content: `<h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">The section for OTAs lists all booking channels where Accommodations are marketed.</h3>
    <p>This insertion is helpful when creating the cost rule for the OTA Commission. It allows you to easily find the channel name from the appropriate menu.</p>
    <p>The list of OTAs can also be populated directly when the "OTA Commission" cost rule is created, with the "Add Booking Provider" function.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">How do I manage booking channels in BiLemon?</h3>
    <p>Booking channels are cost generators, as their commission is a cost borne by Property Managers for each new booking.</p>
    <p>It is possible to add all booking channels in the OTA section of the general settings. However, to generate commission costs for these channels, it is necessary to create a cost rule called "OTA Commission". During the creation of this rule, you can use the "Add Booking Provider" function to include the new channel.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Why can't I see new booking channels when entering Booking?</h3>
    <p>To calculate OTA commissions, we need to create cost rules in the costs section first. Only then can we access channel information when inserting a booking in the OTA section.</p>
    <p>Without cost rules, channel information won't be visible.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Can I configure multiple cost rules for the same booking channel?</h3>
    <p>Multiple cost rules can be created for the same channel based on varying calculation percentages for different bookings, even if they are from the same channel.</p>`
  }

];


// {
//     id: "dashboard",
//     title: "Dashboard",
//     content:["It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
//     <p>The point of using Lorem Ipsum is...</p>
//     <img src='/path/to/image.jpg' alt='Image Description' />
//     <p>Various versions have evolved over the yearssss...</p>`
// ]
// },
// {
//     id: "organization",
//     title: "Organization",
//     content:[ "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like)."]
// },
// {
//     id: "owners",
//     title: "Owners",
//     content:[ "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like)."]
// },
// {
//     id: "suppliers",
//     title: "Suppliers",
//     content:[ "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like)."]
// },