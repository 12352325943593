import React, { useState } from "react";

import { format } from "date-fns";
import { DayPicker } from "react-day-picker";
import "./style.css";

export const DatePicker = ({
  inputClassName,
  className,
  placeholder,
  label,
  labelClassName,
  onClick,
  menu,
  onCompleted,
}) => {
  const [selected, setSelected] = useState();
  let footer = <p>Please pick a day.</p>;
  if (selected) {
    footer = <p>{format(selected, "PPP")}.</p>;
  }
  return (
    <div
      className={
        className + " " + "flex flex-col items-start justify-start w-full m-1"
      }
    >
      {label && (
        <label
          className={
            labelClassName + " " + "text-lg font-semibold text-blue w-full"
          }
        >
          {label}
        </label>
      )}
      <button
        onClick={onClick}
        className={
          inputClassName +
          " " +
          "w-full h-fit my-1 text-base bg-gray-100 border-2 rounded-md outline-none indent-2 text-blue text-left min-h-[25px]"
        }
        placeholder={placeholder}
      >
        {selected ? footer : placeholder}
      </button>
      {menu && (
        <DayPicker
          className="z-10 absolute mt-[6rem] bg-white border-2 rounded-lg"
          mode="single"
          selected={selected}
          onSelect={(date) => {
            onCompleted(date);
            setSelected(date);
          }}
        />
      )}
    </div>
  );
};
