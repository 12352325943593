import TextField from "../../../../../components/input/fields/text-field";
import React, { useState } from "react";
import PrimaryButton from "../../../../../components/buttons/primary-button";
import TertiaryButton from "../../../../../components/buttons/tertiary-button";
import { toast } from "react-toastify";
import { isValidPhoneNumber } from "libphonenumber-js/core";

export const NewSupplier = ({ onComplete, supplier, onCloseModal }) => {
  const [name, setName] = useState(supplier ? supplier.name : null);
  const [phoneNumber, setPhoneNumber] = useState(
    supplier ? supplier.phoneNumber : null
  );
  const [category, setCategory] = useState(supplier ? supplier.category : null);
  const regexNumber = /^\+*[0-9]+$/g;
  const checkFields = () => {
    if (!name) {
      toast.error("Please, provide a name");
      return;
    }
    return true;
  };
  return (
    <div>
      <div className="flex flex-wrap ">
        <TextField
          label={"Name *"}
          placeholder={"Insert.."}
          className={"max-w-xl"}
          inputClassName={"p-1"}
          onChange={(e) => setName(e.target.value)}
          defaultValue={name}
        />
        <TextField
          label={"Phone number"}
          placeholder={"Insert.."}
          inputClassName={"p-1 "}
          className={"max-w-xl"}
          onChange={(e) => setPhoneNumber(e.target.value)}
          defaultValue={phoneNumber}
        />
        <TextField
          label={"Category"}
          className={"max-w-xl"}
          placeholder={"Insert.."}
          inputClassName={"p-1"}
          onChange={(e) => setCategory(e.target.value)}
          defaultValue={category}
        />
      </div>
      <div className="flex items-center justify-end w-full">
        {" "}
        <TertiaryButton
          onClick={onCloseModal}
          className={"bg-red p-2 w-fit mr-5 mt-5"}
          content={ "Close "}
        />
        <PrimaryButton
          content={supplier ? "Edit supplier" : "Add new supplier"}
          className={"p-2 w-fit mt-5"}
          onClick={() => {
            if (checkFields())
              onComplete(
                supplier
                  ? {
                      id: supplier.id,
                      name: name,
                      phoneNumber: phoneNumber ? phoneNumber : null,
                      category: category ? category : null,
                    }
                  : {
                      name: name,
                      phoneNumber: phoneNumber ? phoneNumber : null,
                      category: category ? category : null,
                    }
              );
          }}
        />
      </div>
    </div>
  );
};
