import React, { useState } from "react";
import { MdPerson } from "react-icons/md";
import TertiaryButton from "../../../components/buttons/tertiary-button";
import TextField from "../../../components/input/fields/text-field";
import { EmailField } from "../../../components/input/fields/email-filed";
import SecondaryButton from "../../../components/buttons/secondary-button";
import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { PhoneField } from "../../../components/input/fields/phone-field";
import {
  getAuth,
  updateProfile,
  updateEmail,
  sendEmailVerification,
} from "firebase/auth";
import { toast } from "react-toastify";
import { NeutralButton } from "../../../components/buttons/neutral-button";
import { logout } from "../util/firestore-functions";
import { useNavigate } from "react-router-dom";
import InfoButton from "../../../components/buttons/info-button";

function UserSettings() {
  const auth = getAuth();
  const user = auth.currentUser;
  const storage = getStorage();
  const [imageUpload, setImageUpload] = useState(null);
  const [hasProfilePic, setHasProfilePic] = useState(0);
  const [displayName, setDisplayName] = useState(user.displayName);
  const [email, setEmail] = useState(user.email);
  const [phone, setPhone] = useState(user.phoneNumber);
  const navigator = useNavigate();
  let isGoogleLinked = false;

  user.providerData.forEach((userInfo) => {
    if (userInfo.providerId == "google.com") {
      isGoogleLinked = true;
      return;
    }
  });

  try {
    const pathReference = ref(storage, user.photoURL);
    getDownloadURL(pathReference)
      .then((url) => {
        setHasProfilePic(1);
      })
      .catch((error) => {
        if (error.code === "storage/object-not-found") {
          setHasProfilePic(0);
        }
      });

    getDownloadURL(pathReference)
      .then((url) => {
        const img = document.getElementById("profilepicture");
        img.setAttribute("src", url);
      })
      .catch((error) => {});
  } catch (error) {}

  function uploadFiles() {
    document.getElementById("selectFileInput").click();
  }

  function validateStates() {
    setDisplayName(displayName.trim());
    setEmail(email.trim());
    setPhone(phone.trim());

    if (displayName !== "" && displayName !== user.displayName) {
      updateProfile(auth.currentUser, {
        displayName: `${displayName}`,
      })
        .then(() => {
          toast.success("Display name updated");
          setDisplayName("");
          document.getElementById("displayNameField").value = "";
        })
        .catch((error) => {
          toast.error("Can't change display name: ", error);
        });
    }

    if (phone !== "" && phone !== user.phoneNumber) {
      toast.error("Something went wrong with the phone number");
    }
    if (email !== "" && email !== user.email) {
      let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (regexEmail.test(email)) {
        updateEmail(auth.currentUser, email)
          .then(() => {
            toast.success("Email updated");
            setEmail("");
            sendEmailVerification(email);
            document.getElementById("emailField").value = "";
          })
          .catch((error) => {
            toast.error("Error changing email: " + error);
          });
      } else {
        document.getElementById("emailField").style.borderColor = "red";
      }
    }
  }

  return (
    <>
    
    <div className="flex flex-col content-between w-full ml-10 h-fit ">
      <div className="text-4xl font-bold ">User Profile</div>
      <div className="my-5 text-2xl font-normal">General</div>
      <hr className="w-full mb-6" />
      <div className="big-screen:hidden font-bold w-[15rem] mb-5">Details</div>
      <div className="flex flex-row justify-center big-screen:justify-start">
        <div className="hidden font-bold w-[15rem] big-screen:flex">
          Details
        </div>

        {/* Card details */}

        <div className="w-[70vw] max-w-[30rem] min-w-[23rem] h-fit pb-6 border-2 rounded-lg bg-neutral-50">
          <div className="flex flex-col mx-6 mt-6">
            Display name
            <div className="max-w-[85%] min-w-[60%] h-10 ">
              <TextField
                id="displayNameField"
                placeholder={"Insert..."}
                inputClassName="px-3 my-1 p-1 bg-white"
                value={displayName}
                onChange={(e) => setDisplayName(e.target.value)}
              />
            </div>
          </div>
          <div className="flex flex-col mx-6 mt-6">
            Email
            <div className="max-w-[85%] min-w-[60%] h-10">
              <EmailField
                id="emailField"
                placeholder={"Insert..."}
                inputClassName="px-3 my-1 p-1 bg-neutral-50"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>
          <div className="flex flex-col mx-6 mt-6">
            Phone number
            <div className="max-w-[85%] min-w-[60%] h-10">
              <PhoneField
                placeholder={"Insert..."}
                inputClassName="px-3 my-1 p-1 bg-neutral-50 "
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
          </div>
          <hr className="w-[90%] my-6 ml-[5%]" />
          <div className="flex justify-between mx-6 mt-6">
            <div className="flex items-center justify-center">
              <SecondaryButton
                content="Update"
                className="w-[5.5rem] h-[2.6rem] text-sm"
                onClick={validateStates}
              />
              <SecondaryButton
                content="Cancel"
                className="w-[5.5rem] h-[2.6rem] mx-6 bg-white text-sm"
                onClick={() => {
                  setEmail("");
                  setDisplayName("");
                  setPhone("");
                }}
              />
            </div>

            <NeutralButton
              content={"Logout"}
              className={"w-[5.5rem] h-[2.6rem] mx-6 text-red text-sm"}
              onClick={() => {
                logout().then(() => navigator("/"));
              }}
            />
          </div>
        </div>
      </div>
      <hr className="w-full my-6" />

      <div className="big-screen:hidden font-bold w-[15rem] mb-5">
        Access providers
      </div>

      <div className="flex flex-col">
        <div className="flex items-center justify-start"></div>
      </div>
    </div>
  </>
  );
}

export default UserSettings;
