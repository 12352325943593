import React from "react";
import { SelectMenu } from "../../../../../components/input/select/select-menu";

const SubCategorySelect = ({ onChange, type, value, className }) => {
  const accomodationOptions = [
    {
      value: "support-owner",
      label: "24/7 Support - Owner",
    },
    {
      value: "accessories-appliances",
      label: "Accessories & Appliances",
    },
    {
      value: "channels-manager",
      label: "Channels Manager",
    },
    {
      value: "community-fees",
      label: "Community Fees",
    },
    {
      value: "direct-marketing-acc",
      label: "Direct Marketing",
    },
    {
      value: "dynamic-pricing-tool",
      label: "Dynamic Pricing Tool",
    },
    {
      value: "furnitures",
      label: "Furnitures",
    },
    {
      value: "home-owner-payment",
      label: "Home Owner Payment",
    },
    {
      value: "host-happiness",
      label: "Host Happiness",
    },
    {
      value: "insurance",
      label: "Insurance",
    },
    {
      value: "listing-preparation",
      label: "Listing Preparation",
    },
    {
      value: "maintenance",
      label: "Maintenance",
    },
    {
      value: "materials",
      label: "Materials",
    },
    {
      value: "payment-support",
      label: "Payment Support",
    },
    {
      value: "photo-shooting",
      label: "Photo Shooting",
    },
    {
      value: "pms",
      label: "PMS",
    },
    {
      value: "property-acquisition-cost",
      label: "Property Acquisition Cost",
    },
    {
      value: "rubbish-removal",
      label: "Rubbish Removal",
    },
    {
      value: "shopping",
      label: "Shopping",
    },
    {
      value: "supplies",
      label: "Supplies",
    },
    {
      value: "utilities",
      label: "Utilities",
    },
    {
      value: "other",
      label: "Other",
    },
  ];
  const bookingOption = [
    {
      value: "amenities",
      label: "Amenities",
    },
    {
      value: "checkin-keyhandling",
      label: "Check-in / Keyhandling",
    },
    {
      value: "Checkout",
      label: "Check-out",
    },
    {
      value: "cleaning",
      label: "Cleaning",
    },
    {
      value: "cleaning-products",
      label: "Cleaning Products",
    },
    {
      value: "direct-marketing",
      label: "Direct Marketing",
    },
    {
      value: "guest-compensation",
      label: "Guest Compensation",
    },
    {
      value: "guest-happiness",
      label: "Guest Happiness",
    },
    {
      value: "home-owner-payment",
      label: "Home Owner Payment",
    },
    {
      value: "linen-laundry",
      label: "Linen & Laundry",
    },
    {
      value: "ota-commission",
      label: "OTA Commission",
    },
    {
      value: "payment-processor-fees",
      label: "Payment Processor Fees",
    },
    {
      value: "shopping",
      label: "Shopping",
    },
    {
      value: "tourist-tax-software",
      label: "Tourist Tax Software",
    },
    {
      value: "other",
      label: "Other",
    },
  ];

  const organizationOption = [
    {
      value: "accounting",
      label: "Accounting",
    },
    {
      value: "administrative-expenses",
      label: "Administrative Expenses",
    },
    {
      value: "advertising",
      label: "Advertising",
    },
    {
      value: "bank-commission",
      label: "Bank Commission",
    },
    {
      value: "bank-credit",
      label: "Bank Credit",
    },
    {
      value: "bi",
      label: "BI",
    },
    {
      value: "bonus-benefit",
      label: "Bonus & Benefit",
    },
    {
      value: "company-insurance",
      label: "Company Insurance",
    },
    {
      value: "consulting-fees",
      label: "Consulting Fees",
    },
    {
      value: "crm",
      label: "CRM",
    },
    {
      value: "digital-marketing-campaign",
      label: "Digital Marketing Campaign",
    },
    {
      value: "employee-happiness",
      label: "Employee Happiness",
    },
    {
      value: "events",
      label: "Events",
    },
    {
      value: "food",
      label: "Food",
    },
    {
      value: "fuel",
      label: "Fuel",
    },
    {
      value: "general-expenses",
      label: "General Expenses",
    },
    {
      value: "home-owner-payment",
      label: "Home Owner Payment",
    },
    {
      value: "legal-expenses",
      label: "Legal Expenses",
    },
    {
      value: "market-data",
      label: "Market Data",
    },
    {
      value: "media",
      label: "Media",
    },
    {
      value: "meeting-expenses",
      label: "Meeting Expenses",
    },
    {
      value: "office-expenses",
      label: "Office Expenses",
    },
    {
      value: "operation-issues",
      label: "Operation Issues",
    },
    {
      value: "other-expenses",
      label: "Other Expenses",
    },
    {
      value: "other-technology",
      label: "Other Technology",
    },
    {
      value: "partnership-referrals",
      label: "Partnership Referrals",
    },
    {
      value: "recruitment",
      label: "Recruitment",
    },
    {
      value: "referral-program",
      label: "Referral Program",
    },
    {
      value: "rent",
      label: "Rent",
    },
    {
      value: "rent-storage",
      label: "Rent Storage",
    },
    {
      value: "research",
      label: "Research",
    },
    {
      value: "retargeting",
      label: "Retargeting",
    },
    {
      value: "seo-marketing",
      label: "SEO & Marketing",
    },
    {
      value: "services",
      label: "Services",
    },
    {
      value: "services-owners-cost",
      label: "Services Owners (cost)",
    },
    {
      value: "software",
      label: "Software",
    },
    {
      value: "staff",
      label: "Staff",
    },
    {
      value: "staff-recognition",
      label: "Staff Recognition",
    },
    {
      value: "staff-training-welfare",
      label: "Staff Training & Welfare",
    },
    {
      value: "subscriptions",
      label: "Subscriptions",
    },   
    {
      value: "telephone-internet",
      label: "Telephone & Internet",
    },
    {
      value: "transport-travel",
      label: "Transport & Travel",
    },
    {
      value: "other",
      label: "Other",
    },
  ];
  
  return (
    <SelectMenu
      label={"Category *"}
      options={
        type
          ? (type.value === 0 && accomodationOptions) ||
            (type.value === 1 && organizationOption) ||
            (type.value === 2 && bookingOption)
          : null
      }
      onChange={onChange}
      value={value}
      className={className + " max-w-[300px]"}
    />
  );
};

export default SubCategorySelect;
