import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import React, { useState } from "react";
import { BiLock } from "react-icons/bi";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import PrimaryButton from "../../../components/buttons/primary-button";
import { PasswordResetDialog } from "../../../components/dialogs/presets/passwordResetDialog";
import { EmailField } from "../../../components/input/fields/email-filed";
import  TextField from "../../../components/input/fields/text-field";

import { IoEye, IoEyeOff } from 'react-icons/io5';
import { PasswordField } from "../../../components/input/fields/password-field";

export const Login = () => {
  const auth = getAuth();
  const navigator = useNavigate();
  /* Fields */
  const [email, setEmail] = useState();
  const [password, setPassword] = useState('');
  
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  /* Functions */
  const submit = () => {
    if (!(email && password)) {
      toast.error("Empty fields");
      return;
    }
    if (
      !String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      toast.error("Invalid email field");
      return;
    }

    signInWithEmailAndPassword(auth, email, password)
      .then(() => {
        toast.success("Login success");
        navigator("/dashboard");
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        switch (errorCode) {
          case "auth/user-not-found":
            toast.error("User not registered");
            break;
          case "auth/wrong-password":
            toast.error("Invalid credentials");
            break;
          case "auth/too-many-requests":
            toast.error("Too many request, try again in a bit");
            break;
          default:
            toast.error("Something went wrong:" + errorMessage);
            break;
        }
      });
  };
  return (
    <div className="flex justify-center w-full my-10 text-2xl text-blue">
      <div className="p-10">
        {/* Image Section */}
        <div className="flex flex-col items-center justify-center w-full text-petroil">
          <img
            className="w-full h-auto max-w-md "
            alt="Bilemon logo"
            src="/Logo-Petroil.png"
          />
          <p className="mt-4 text-base text-center">
            Don't have an account yet?
            <Link className="font-bold hover:underline " to="/signup">
              {" "}
              Signup
            </Link>
          </p>
        </div>
        <div className="flex flex-col items-center w-full my-1 space-y-2">
          <EmailField
            onChange={(e) => setEmail(e.target.value)}
            label={"Email"}
            placeholder={"Insert your email"}
            inputClassName={"p-1"}
          />
          <div className="flex items-center justify-between w-full">
          <div className="relative w-full mr-2">
            <TextField
              type={isPasswordVisible ? "text" : "password"}
              label={"Password *"}
              placeholder={"Insert"}
              className={""}
              inputClassName={"p-1"}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              value={password}
            />
            <button
              className="absolute right-3 bottom-2 transform -translate-y-1/2"
              onClick={() => setIsPasswordVisible(!isPasswordVisible)}
            >
              {isPasswordVisible ? <IoEyeOff className="text-base text-petroil"/> : <IoEye className="text-base text-petroil"/>}
            </button>
          </div>          
        </div>
        </div>
        <div className="flex items-center justify-end my-1 text-sm">
          <PasswordResetDialog />
        </div>
        <div className="flex items-center justify-center">
          <PrimaryButton
            className={"flex p-2 items-center justify-center space-x-3"}
            content={"Login"}
            onClick={submit}
          />
        </div>
      </div>
    </div>
  );
};
