import React, { useContext, useRef, useState } from "react";
import SecondaryButton from "../../../../../components/buttons/secondary-button";
import { CurrencyField } from "../../../../../components/input/fields/currency-field";
import TextField from "../../../../../components/input/fields/text-field";
import PrimaryButton from "../../../../../components/buttons/primary-button";
import TertiaryButton from "../../../../../components/buttons/tertiary-button";
import { SelectMenu } from "../../../../../components/input/select/select-menu";
import Modal from "../../../../../components/dialogs/dialog";
import { PercentField } from "../../../../../components/input/fields/percent-field";
import { toast } from "react-toastify";
import { format } from "date-fns";
import {
  updateBooking,
  setSupplier,
  setBookingProvider,
  updateCost,
} from "../../../util/firestore-functions";
import { DataContext } from "../../..";
import { MdOutlinePostAdd } from "react-icons/md";
import { NewSupplier } from "../pages/new-supplier";
import { NewBookingProvider } from "../pages/new-booking-provider";
import SubCategorySelect from "../components/sub-category-select";
import { NewDatePicker } from "../../../../../components/input/fields/date-pickers/newDatePicker";

const CostEdit = ({ selectedCost, onClose }) => {
  const context = useContext(DataContext);
  const bookingList = context.bookingList;
  const accommodationsList = context.accomodationsList;
  const [isFirstTime, setIsFirstTime] = useState(true);
  const [chargeback, setChargeback] = useState(
    selectedCost.chargebacks ? true : false
  );
  const [costTypology, setCostTypology] = useState(selectedCost.costTypology || "");
  const [costCategory, setCostCategory] = useState(selectedCost.costCategory || "");
  const [paymentType, setPaymentType] = useState({
    label: "Fixed",
    value: "Fixed",
  });
  const [bookingToCalc, setBookingToCalc] = useState(null);
  const closeModal = () => {
    setOpenNewSupplierModal(false);
  };
  const costDate = selectedCost.costDate
    ? new Date(1000 * selectedCost.costDate.seconds)
    : null;
  const paymentDate = selectedCost.paymentDate
    ? new Date(1000 * selectedCost.paymentDate.seconds)
    : null;
  const chargebackDate = chargeback && selectedCost.chargebacksDate
    ? new Date(1000 * selectedCost.chargebacksDate.seconds)
    : null;
  const supplierOptions = context.suppliesList
    ? context.suppliesList.map((item) => {
      return {
        label:
          item.data.name +
          (item.data.category ? " (" + item.data.category + ")" : ""),
        value: item.id,
      };
    })
    : null;
  const [supplier, setSupplierForm] = useState(
    selectedCost.supplierId
      ? supplierOptions.find((value) => value.value == selectedCost.supplierId)
      : null
  );
  const [openNewSupplierModal, setOpenNewSupplierModal] = useState();
  const [selectedBookingProvider, setSelectedBookingProvider] = useState({ label: selectedCost.name, value: selectedCost.name });
  const [openNewBookingProviderModal, setOpenNewBookingProviderModal] = useState();
  const [subcategory, setSubcategory] = useState(selectedCost.name || "");

  var newCategory = null;
  const [formValues, setFormValues] = useState({
    name: subcategory,
    costDate: selectedCost.costDate.toDate(),
    paymentDate: selectedCost.paymentDate
      ? selectedCost.paymentDate.toDate()
      : null,
    amount: parseFloat(selectedCost.amount.toFixed(2)),
    costTypology: costTypology || "",
    costCategory: costCategory || "",
    accomodationList: selectedCost.accomodationList,
    bookingRef: selectedCost.bookingRef ? selectedCost.bookingRef : null,
    costRuleRef: selectedCost.costRuleRef ? selectedCost.costRuleRef : null,
    supplierId: selectedCost.supplierId,
  });
  const costTypologyOptions = [
    {
      label: "Accommodation",
      value: 0,
    },
    {
      label: "Company",
      value: 1,
    },
    {
      label: "Booking",
      value: 2,
    },
  ];

  const paymentTypeOptions = [
    {
      label: "Fixed",
      value: "Fixed",
    },
    {
      label: "Percentage",
      value: "Percentage",
    }
  ];



  const retrieveBooking = (id) => {

    return bookingList.find(
      // Find the booking with the same id
      (x) => x.id === id
    );

  }

  // let isFirstTime = true;

  if (formValues.bookingRef === selectedCost.bookingRef && isFirstTime) {

    let valoreTrovato = retrieveBooking(selectedCost.bookingRef);

    setBookingToCalc(valoreTrovato);
    setIsFirstTime(false);

  }


  const updateBookingInternal = (isToUpdate) => {

    const bookingToUpdate = bookingList.find(
      // Find the booking with the same id
      (x) => x.id === selectedCost.bookingRef
    )
    if (bookingToUpdate && isToUpdate) {

      if (selectedCost.bookingRef != formValues.bookingRef && isToUpdate && selectedCost.costCategory.value === "ota-commission" && formValues.costCategory.value === "ota-commission") {

        const bookingToUpdateNew = bookingList.find(
          // Find the booking with the same id
          (y) => y.id === formValues.bookingRef
        )

        if (bookingToUpdateNew && !bookingToUpdateNew.data.commissionChannel) {
          bookingToUpdate.data.commissionAmount = 0;
          bookingToUpdate.data.commissionChannel = null;
          bookingToUpdateNew.data.commissionAmount = formValues.amount;
          bookingToUpdateNew.data.commissionChannel = formValues.name;
          bookingToUpdateNew.data.accomodationReference = formValues.accomodationList[0];
          updateBooking(bookingToUpdateNew.data, bookingToUpdateNew.id, context.organizationId);

        }


      } else if (isToUpdate && selectedCost.bookingRef === formValues.bookingRef) {

        bookingToUpdate.data.commissionAmount = formValues.amount;
        bookingToUpdate.data.commissionChannel = formValues.name;
        bookingToUpdate.data.accomodationReference = formValues.accomodationList[0];
      }
    } else if (bookingToUpdate && !isToUpdate) {
      bookingToUpdate.data.commissionAmount = 0;
      bookingToUpdate.data.commissionChannel = null;
    }

    updateBooking(bookingToUpdate.data, bookingToUpdate.id, context.organizationId);
    context.updateData("bookings");
  }



  const getAccomodationSelected = () => {
    var resl = Array();
    accommodationsList.map((value) => {
      if (selectedCost.accomodationList)
        selectedCost.accomodationList.forEach((ext) => {
          if (value.id === ext) {
            resl.push({ value: value.id, label: value.data.name });
          }
        });
    });
    return resl;
  };
  const getBookingSelected = () => {
    const costBookingSelected = bookingList.find(
      (value) => value.id === selectedCost.bookingRef
    );

    const costBookingSelReference = accommodationsList.find((value) =>
      costBookingSelected.data.accomodationReference === value.id

    );
    return {
      value: costBookingSelected.data.id, label: costBookingSelected.data.bookingDate.toDate().toLocaleString('it-IT', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
      }) + " - " + costBookingSelReference.data.name
    };


  };

  const [selectedSecondaryMenu, setSelectedSecondaryMenu] = useState(
    (selectedCost.costTypology.value === 2 && getBookingSelected()) ||
    (selectedCost.costTypology.value === 0 && getAccomodationSelected())
  );

  const checkFields = () => {
    var regexNumbers = /^[0-9]*(\.[0-9]{0,2})?$/;

    const checkOta = bookingList.find(
      // Find the booking with the same id
      (z) => z.id === formValues.bookingRef
    )

    const checkNewOta = bookingList.find(
      // Find the booking with the same id
      (z) => z.id === selectedCost.bookingRef
    )

    if (formValues.costTypology.value === 2 && formValues.costCategory.value === "ota-commission" && checkNewOta.data.commissionAmount && checkOta.data.commissionAmount && checkNewOta.id != checkOta.id) {
      toast.error('You already have an OTA commission');
      return false;
    }

    if (formValues.costTypology.value == 2  && !paymentType) {
      toast.error("Select a Payment Type");
      return false;
    }

    if (formValues.amount && !regexNumbers.test(formValues.amount)) {
      toast.error("Check amount field");
      return false;
    }

    if (costTypology.value === 0) {
      if (!formValues.accomodationList || formValues.accomodationList.length === 0) {
        toast.error("Please provide one or more accommodations");
        return false;
      }
    } else if (costTypology.value === 2) {
      if (!formValues.bookingRef) {
        toast.error("Please select a booking");
        return false;
      }
    }

    if (!formValues.costDate || !formValues.costCategory || formValues.costCategory.value === "ota-commission" && !formValues.name) {
      toast.error("Please check required fields");
      return false;
    }

    if (formValues.paymentDate && formValues.costDate > formValues.paymentDate) {
      toast.error("Cost date has to be before payment date");
      return false;
    }

    if (chargeback) {
      if (formValues.paymentDate && formValues.paymentDate > formValues.chargebacksDate) {
        toast.error("Payment date has to be before chargeback date");
        return false;
      }

      if (formValues.costDate > formValues.chargebacksDate) {
        toast.error("Cost date has to be before chargeback date");
        return false;
      }

      if (!(formValues.chargebacksAmount && formValues.chargebacksDate)) {
        toast.error("Check required fields");
        return false;
      }
    }

    return true;
  };





  const submit = async () => {
    if (!checkFields()) {
      return;

    }
    // const bookingInternalResult = updateBookingInternal(true);
    // if (bookingInternalResult && bookingInternalResult.error) {
    // return; // Don't proceed with submission
    // }
    // if (bookingToUpdate.data.commissionChannel && formValues.costCategory.value === "ota-commission"){        
    //   toast.error('You already have an OTA commission');
    //   return; 
    // }

    updateCost(formValues, selectedCost.id, context.organizationId)
      .then(() => {
        context.updateData("costs");
        if (formValues.costCategory && formValues.costCategory.value !== "ota-commission" && selectedCost.costCategory.value === "ota-commission") {
          updateBookingInternal(false);
        }

        if (formValues.costCategory && formValues.costCategory.value === "ota-commission") {
          if (formValues.amount != selectedCost.amount || formValues.name && formValues.name != selectedCost.name || formValues.bookingRef != selectedCost.bookingRef) {
            updateBookingInternal(true);
          }
        }
        onClose();
      });

  };

  return (
    <div>
      <div className="w-full max-w-5xl p-5">
        <div className="flex flex-col justify-between ml-auto mr-auto h-fit">
          <div className="flex flex-wrap items-center justify-start mb-4">
            <SelectMenu
              label={"Cost typology *"}
              className={"max-w-xs min-w-[250px] mr-10"}
              isSearchable={false}
              options={costTypologyOptions}
              placeholder={"Select type of cost"}
              onChange={(value) => {
                formValues.costTypology = value;
                formValues.accomodationList = null;
                formValues.bookingRef = null;
                formValues.costCategory = null;
                setCostCategory(null);
                setCostTypology(value);
                setSelectedSecondaryMenu(null);
              }}
              value={costTypology}
            />
            {costTypology && costTypology.value != 1 && (
              <SelectMenu
                label={
                  "Select your " +
                  costTypology.label.toLowerCase() +
                  (costTypology.value == 2 ? " *" : "s *")
                }
                className={"max-w-xs min-w-[250px]"}
                isMulti={costTypology.value == 0 ? true : false}
                onChange={(value) => {
                  if (costTypology.value == 0) {
                    formValues.accomodationList = value.map((item) => {
                      return item.value;
                    });

                  }
                  if (costTypology.value === 2) {
                    const selectedBooking = bookingList.find(item => item.id === value.value);
                    if (selectedBooking) {
                      const referenza = accommodationsList.find(
                        value => selectedBooking.data.accomodationReference === value.id
                      );
                      formValues.bookingRef = value.value;
                      formValues.accomodationList = [referenza.id];
                      setBookingToCalc(selectedBooking);
                    }
                  }
                  // if (costTypology.value == 2) {
                  //   bookingList.map((item) => {
                  //     let referenza = accommodationsList.find((value) =>
                  //       item.data.accomodationReference === value.id

                  //     );
                  //     return {
                  //       label: item.data.bookingDate.toDate().toLocaleString('it-IT', {
                  //         year: 'numeric',
                  //         month: 'numeric',
                  //         day: 'numeric'
                  //       }) + " - " + referenza.data.name, value: item.id
                  //     };
                  //   })
                  //   formValues.bookingRef = value.value;
                  //   let accomodationsList = new Array();
                  //   accomodationsList.push(referenza.id);
                  //   formValues.accomodationList = accomodationsList;
                  // }

                  setSelectedSecondaryMenu(value);
                }}
                value={selectedSecondaryMenu}
                options={
                  costTypology.value == 0
                    ? accommodationsList &&
                    accommodationsList.map((item) => {
                      return { label: item.data.name, value: item.id };
                    })
                    : bookingList &&
                    bookingList.map((item) => {
                      let referenza = accommodationsList.find((value) =>
                        item.data.accomodationReference === value.id

                      );
                      return {
                        label: item.data.bookingDate.toDate().toLocaleString('it-IT', {
                          year: 'numeric',
                          month: 'numeric',
                          day: 'numeric'
                        }) + " - " + referenza.data.name, value: item.id
                      };
                    })
                }
              />
            )}
          </div>
          <div className="flex flex-wrap items-end justify-start mb-4">
            <SubCategorySelect
              type={costTypology}
              onChange={(e) => {
                if (e) {
                  const bookingProvider = context.bookingProvidersList.find(
                    (x) => x.data.name === selectedCost.name
                  );
                  if (
                    formValues.costTypology &&
                    formValues.costTypology.value === 2 &&
                    e.value === "ota-commission" &&
                    bookingProvider
                  ) {
                    formValues.name = selectedCost.name;
                    setSubcategory(selectedCost.name);
                    setSelectedBookingProvider({
                      label: selectedCost.name,
                      value: selectedCost.name,
                    });
                  } else {
                    formValues.name = null;
                    setSubcategory("");
                    setSelectedBookingProvider(null);
                  }
                  formValues.costCategory = e;
                  setCostCategory(e);
                } else {
                  // Handle the case where the user cleared the sub-category field
                  formValues.name = null;
                  setSubcategory("");
                  setSelectedBookingProvider(null);
                  formValues.costCategory = null;
                  setCostCategory(null);
                }
              }}
              value={costCategory}
            />

            {costCategory && costCategory.value === "ota-commission" && (
              <React.Fragment>
                <SelectMenu
                  label={"Booking Provider *"}
                  className={"max-w-xs ml-10 mr-5"}
                  value={selectedBookingProvider}
                  options={
                    context.bookingProvidersList
                      ? context.bookingProvidersList.map((item) => {
                        return {
                          label: item.data.name,
                          value: item.data.name,
                        };
                      })
                      : null
                  }
                  onChange={(el) => {
                    formValues.name = el.value;
                    setSelectedBookingProvider(el);
                  }}
                />
                <SecondaryButton
                  content={<MdOutlinePostAdd className="w-6 h-auto" />}
                  className={"w-fit p-2 mb-2 max-w-[30px]"}
                  onClick={() => setOpenNewBookingProviderModal(true)}
                />
              </React.Fragment>
            )}
            {costCategory && costCategory.value !== "ota-commission" && (
              <TextField
                label={"Sub-Category "}
                placeholder={"Insert the sub-category "}
                className={"max-w-xs min-w-[250px] ml-10"}
                inputClassName={"p-1"}
                value={formValues.name}
                onChange={(e) => {
                  formValues.name = e.target.value;
                  setSubcategory(e.target.value);
                }}
                notEditableValue={formValues.name}
              />
            )}
            <div className="flex items-end justify-start w-full mt-3">
              <SelectMenu
                label={"Supplier "}
                isClearable
                className={"max-w-xs mr-5"}
                options={supplierOptions}
                value={supplier}
                onChange={(el) => {
                  setSupplierForm(el);
                  formValues.supplierId = el.value;
                }}
              />
              <SecondaryButton
                content={<MdOutlinePostAdd className="w-6 h-auto" />}
                className={"w-fit p-2 mb-2 max-w-[30px]"}
                onClick={() => setOpenNewSupplierModal(true)}
              />
            </div>
          </div>

          <div className="flex flex-wrap space-y-5">
            <NewDatePicker
              label={"Cost Date *"}
              placeholder={"Select date of payment"}
              className={"max-w-xs min-w-[250px] mt-5 mr-10"}
              defaultValue={costDate}
              onCompleted={(el) => {
                formValues.costDate = el;
              }}
            />
            <NewDatePicker
              label={"Payment date"}
              placeholder={"Select date of invoice"}
              className={"max-w-xs min-w-[250px] mr-10"}
              defaultValue={paymentDate}
              onCompleted={(el) => (formValues.paymentDate = el)}
            />
            {costTypology && costTypology.value === 2 ?
              <div className="flex flex-wrap mt-6">
                <SelectMenu
                  label={"Payment type *"}
                  className={"max-w-xs mr-10"}
                  isSearchable={false}
                  value={paymentType}
                  options={paymentTypeOptions}
                  placeholder={"Select type of payment"}
                  onChange={(value) => {
                    setPaymentType(value);
                  }}
                />
                {paymentType.value === "Percentage" ?
                  <PercentField
                    className={"max-w-xs mr-10"}
                    label={"Percentage amount *"}
                    inputClassName={"p-[5px] w-[150px]"}
                    value={0}
                    onChange={(e) => {

                      formValues.amount = Number((bookingToCalc.data.amount / 100 * e.target.value).toFixed(2));
                    }}
                  /> :
                  <CurrencyField
                    className={"max-w-xs mr-10"}
                    label={"Payment amount *"}
                    inputClassName={"p-1"}
                    value={formValues.amount}
                    onChange={(value) =>
                      (formValues.amount = Number(value.target.value))
                    }
                  />
                }
              </div> : <CurrencyField
                className={"max-w-xs mr-10"}
                label={"Payment amount *"}
                inputClassName={"p-1"}
                value={formValues.amount}
                onChange={(value) =>
                  (formValues.amount = Number(value.target.value))
                }
              />}
          </div>
        </div>
      </div>
      <Modal
        externalControl
        status={openNewSupplierModal}
        onClose={() => setOpenNewSupplierModal(false)}
        body={
          <NewSupplier
            onCloseModal={closeModal}
            onComplete={(value) => {
              setOpenNewSupplierModal(false);
              setSupplier(value, context.organizationId);
              context.updateData("suppliers");
            }}
          />
        }
        title={"New Supplier"}
        className={"w-fit"}
      />
      <Modal
        externalControl
        status={openNewBookingProviderModal}
        onClose={() => setOpenNewBookingProviderModal(false)}
        body={
          <NewBookingProvider
            onComplete={(value) => {
              setOpenNewBookingProviderModal(false);
              setBookingProvider(value, context.organizationId);
              context.updateData("booking-providers");
            }}
          />
        }
        title={"New Booking Provider"}
      />
      <div className="flex flex-row-reverse justify-start">

        <PrimaryButton
          content={"Update cost"}
          className={"p-2 w-fit h-auto max-w-[120px]"}
          onClick={() => {
            submit();
          }}
        />
        <TertiaryButton
          onClick={onClose}
          className={"bg-red p-2 w-fit mr-5 max-w-[80px]"}
          content={"Close "}
        />
      </div>
    </div>
  );
};

export default CostEdit;
