import React from "react";
import { TextFieldImaged } from "./text-field-image";
import { BsPercent } from "react-icons/bs";

export const PercentField = ({
  onChange,
  inputClassName,
  label,
  value,
  className,
  labelClassName,
  placeholder,
}) => {
  return (
    <TextFieldImaged
      id="displayNameField"
      inputClassName={inputClassName + " " + "max-w-[100px]"}
      label={label}
      className={className + " " + "w-fit"}
      labelClassName={labelClassName + " " + "w-fit"}
      placeholder={placeholder}
      type="number"
      value={value}
      onChange={onChange}
      image={<BsPercent className="w-5 h-auto ml-2" />}
    />
  );
};
