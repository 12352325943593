import { getAuth, RecaptchaVerifier } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import PrimaryButton from "../../../../components/buttons/primary-button";
import SecondaryButton from "../../../../components/buttons/secondary-button";
import { EmailField } from "../../../../components/input/fields/email-filed";
import TextField from "../../../../components/input/fields/text-field";
import { IoEye, IoEyeOff } from 'react-icons/io5';

export const SecondStep = ({ onGoBack, onComplete, formsValues }) => {
  const [email, setEmail] = useState(formsValues.email);
  const [password, setPassword] = useState(formsValues.password);
  const [confirmPassword, setConfirmPassword] = useState(
    formsValues.confirmPassword
  );
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const submit = () => {

    if (isLoading) {
      return;
    }

    setIsLoading(true);

    if (!(email && password && confirmPassword)) {
      toast.error("Check fields");
      return;
    }
    if (
      !String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      toast.error("Invalid email");
      return;
    }
    if (!(password === confirmPassword)) {
      toast.error("Password mismatch");
      return;
    }

    if (!String(password).match(/[A-Z]/)) {
      toast.error("Password must contain at least one uppercase letter.");
      return;
    }

    if (!String(password).match(/[a-z]/)) {
      toast.error("Password must contain at least one lowercase letter.");
      return;
    }

    if (!String(password).match(/\d/)) {
      toast.error("Password must contain at least one number");
      return;
    }

    if (!String(password).match(/[!@#$%^&*().]/)) {
      toast.error("Password must contain at least one special character like !.@#$%^&*() .");
      return;
    }

    if (String(password).length < 8) {
      toast.error("Password must be at least 8 characters long.");
      return;
    }
    
    onComplete({
      email: email,
      password: password,
      confirmPassword: confirmPassword,
    });

    setTimeout(() => {
      setIsLoading(false);
    }, 2500); 
  

    return;
  };

  return (
    <>
      <div className="flex flex-col items-center w-full my-1 space-y-2">
        <EmailField
          label={"Email *"}
          inputClassName={"p-1"}
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          placeholder={"Insert your email"}
        />
        <div className="flex items-center justify-between w-full">
          <div className="relative w-full mr-2">
            <TextField
              type={isPasswordVisible ? "text" : "password"}
              label={"Password *"}
              placeholder={"Insert"}
              className={""}
              inputClassName={"p-1"}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              value={password}
            />
            <button
              className="absolute right-3 bottom-2 transform -translate-y-1/2"
              onClick={() => setIsPasswordVisible(!isPasswordVisible)}
            >
              {isPasswordVisible ? <IoEyeOff className="text-base text-petroil"/> : <IoEye className="text-base text-petroil"/>}
            </button>
          </div>
          <div className="relative w-full">
            <TextField
              label={"Confirm password *"}
              type={isConfirmPasswordVisible ? "text" : "password"}
              inputClassName={"p-1"}
              className={""}
              placeholder={"Confirm password"}
              onChange={(e) => setConfirmPassword(e.target.value)}
              value={confirmPassword}
            />
            <button
              className="absolute right-3 bottom-2 transform -translate-y-1/2"
              onClick={() =>
                setIsConfirmPasswordVisible(!isConfirmPasswordVisible)
              }
            >
              {isConfirmPasswordVisible ? <IoEyeOff className="text-base text-petroil"/> : <IoEye className="text-base text-petroil"/>}
            </button>
          </div>
        </div>
      </div>
      <div className="flex justify-between mt-10">
        <SecondaryButton
          content={"Back"}
          className={"py-2 px-10 w-fit mr-10 max-w-[80px]"}
          onClick={() => {
            onGoBack({
              email: email,
              password: password,
              confirmPassword: confirmPassword,
            });
          }}
        />
        <PrimaryButton
      content={isLoading ? "Signing up..." : "Sign up"}
      className={isLoading ? "cursor-not-allowed p-2 brightness-95" : "p-2"}
      onClick={submit}
      disabled={isLoading} 
    />
      </div>
    </>
  );
};