import { CheckIcon, TicketIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { format } from "date-fns";
import { it } from "date-fns/locale";
import React, { useContext } from "react";
import { DataContext } from "../../..";
import SecondaryButton from "../../../../../components/buttons/secondary-button";
import TertiaryButton from "../../../../../components/buttons/tertiary-button";

const BookingView = ({ selectedCost, onClose }) => {
  const context = useContext(DataContext);

  const formattedBookingDate = format(selectedCost.bookingDate, "dd/MM/yyyy", { locale: it });
const formattedCheckInDate = format(selectedCost.checkInDate, "dd/MM/yyyy", { locale: it });
const formattedCheckOutDate = format(selectedCost.checkOutDate, "dd/MM/yyyy", { locale: it });

  function formatNumberWithComma(number) {
    if(number == null || number == undefined) { 
      return 0; 
    }
    return number.toString().replace('.', ',');
  }

  const selectedAccomodation = context.accomodationsList.filter(x => selectedCost.accomodationList && selectedCost.accomodationList.filter(accomodation => x && x.id == accomodation).length > 0);
  return (
    <>
    <div className="text-base">
      <div className="flex items-center space-x-2">
        <h4 className="font-bold">Accommodation:</h4>
        <p> {selectedCost.accomodationReference}</p>
      </div>
      <div className="flex items-center space-x-2">
        <h4 className="font-bold">Booking Date: </h4>
        <p> {formattedBookingDate}</p> 
      </div>
      <div className="flex items-center space-x-2">
        <h4 className="font-bold">Check-In Date: </h4>
         <p> {formattedCheckInDate}</p> 
      </div>
      <div className="flex items-center space-x-2">
        <h4 className="font-bold">Check-Out Date: </h4>
         <p> {formattedCheckOutDate}</p> 
      </div>
      <div className="flex items-center space-x-2">
        <h4 className="font-bold">Guests: </h4>
        <p> {selectedCost.numberOfGuest}</p>
      </div>
      <div className="flex items-center space-x-2">
        <h4 className="font-bold">Booking Amount: </h4>
        <p>{selectedCost.amount ? "€ " + formatNumberWithComma(selectedCost.amount): ""}</p>
      </div>
      <div className="flex items-center space-x-2">
        <h4 className="font-bold">Channel: </h4>
        <p>  {selectedCost.commissionChannel = selectedCost.commissionChannel ? selectedCost.commissionChannel : "Direct" }</p>
      </div>
      <div className="flex items-center space-x-2">
        <h4 className="font-bold">Channel Commission: </h4>
        <p>€ {selectedCost.commissionAmount ? formatNumberWithComma(selectedCost.commissionAmount) : 0 }</p>
      </div>
    </div>
          <div className="flex justify-end">
          <TertiaryButton
            content={"Close"}
            className={"bg-red text-white p-2 w-fit max-w-[130px] mt-2"}
            onClick={onClose}
          />
        </div>
        </>
  );
};

export default BookingView;
