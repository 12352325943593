import { getAuth, sendEmailVerification } from "firebase/auth";
import React, { createContext, useEffect, useState } from "react";
import { Route, Routes, useNavigate, Navigate } from "react-router-dom";
import SecondaryButton from "../../components/buttons/secondary-button";
import { SideBarComponent } from "../../components/sidebar/sidebar-component";
import {
  getAccomodationList,
  getBookingForeachAccomodationList,
  getBookingList,
  getCostList,
  getCostRulesList,
  getOrganizationId,
  getOrganizationInfo,
  getOwnerList,
  getSupplierList,
  getUserExtraInfo,
  sendVerificationEmail,
  getBookingProviderList
} from "./util/firestore-functions";
import LoadingApp from "../loading/LoadingApp";
import NotFound from "../not-found";
import { Accomodations } from "./main-sections/accomodation";
import { NewAccomodation } from "./main-sections/accomodation/pages/new-accomodation";
import Booking from "./main-sections/booking/booking";
import NewBooking from "./main-sections/booking/pages/new-booking";
import Costs from "./main-sections/costs";
import { NewCost } from "./main-sections/costs/pages/new-cost";
import { NewCostRule } from "./main-sections/rules/pages/new-cost-rules";
import Home from "./main-sections/home";
import Organization from "./user-settings/organization";
import UserSecurity from "./user-settings/security";
import UserSettings from "./user-settings/settings";
import { OwnersSettings } from "./user-settings/owners";
import { SuppliersSettings } from "./user-settings/suppliers";
import { IoWarning, IoReload, IoInformationCircleOutline } from "react-icons/io5";
import { HiOutlineInformationCircle } from "react-icons/hi";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Rule } from "postcss";
import CostRules from "./main-sections/rules";
import { OtaCommissionsSettings } from "./user-settings/otaCommissions";
import Faq from "./main-sections/faq/faq";
import SubscriptionSetup from "./user-settings/subscription";

export const DataContext = createContext({
  loading: null,
  accomodationsList: null,
  ownersList: null,
  suppliesList: null,
  costsList: null,
  bookingProvidersList: null,
  costsRuleList: null,
  bookingList: null,
  organizationInfo: null,
  userInfo: null,
  organizationId: null,
});
function Dashboard() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [accomodationsList, setAccomodationsList] = useState();
  const [ownersList, setOwnersList] = useState();
  const [suppliesList, setSuppliersList] = useState();
  const [bookingProvidersList, setBookingProvidersList] = useState();
  const [costsList, setCostsList] = useState();
  const [costsRuleList, setCostsRuleList] = useState();
  const [bookingList, setBookingList] = useState();
  const [organizationInfo, setOrganizationInfo] = useState();
  const [organizationId, setOrganizationId] = useState();
  const [userInfo, setUserInfo] = useState();
  const [emailVerificationBanner, setEmailVerificationBanner] = useState(true);
  const organization = getOrganizationInfo()
  const auth = getAuth();




  useEffect(() => {
    /* Check if user has the phone number otherwise continue the registration */
    if (!auth.currentUser.phoneNumber) {
      navigate("/signup/extra-info");
      return;
    }

    /* Check organization ID */
    getUserExtraInfo().then(async (org) => {
      if (org && org.organization) {
        const organizationId = org.organization;
        await fetchData(organizationId);
      } else {
        navigate("/organization-setup");
        return;
      }
    });

    // Fetch all data informations
    const fetchData = async (organizationId) => {
      setLoading(true);
      setOrganizationId(organizationId);
      setOrganizationInfo(await getOrganizationInfo(organizationId));
      setAccomodationsList(await getAccomodationList(organizationId));
      setOwnersList(await getOwnerList(organizationId));
      setSuppliersList(await getSupplierList(organizationId));
      setBookingProvidersList(await getBookingProviderList(organizationId));
      setBookingList(await getBookingList(organizationId));
      setCostsList(await getCostList(organizationId));
      setCostsRuleList(await getCostRulesList(organizationId));
      setOrganizationInfo(await getOrganizationInfo(organizationId));
      setLoading(false);
    };
  }, []);

  const onChangeUpdate = async (key) => {
    switch (key) {
      case "accomodations":
        setAccomodationsList(await getAccomodationList(organizationId));
        break;
      case "bookings":
        setBookingList(await getBookingList(organizationId));
        break;
      case "costs":
        setCostsList(await getCostList(organizationId));
        break;
      case "rules":
        setRulesList(await getRuleList(organizationId));
        break;
      case "cost-rules":
        setCostsRuleList(await getCostRulesList(organizationId));
        break;
      case "owners":
        setOwnersList(await getOwnerList(organizationId));
        break;
      case "suppliers":
        setSuppliersList(await getSupplierList(organizationId));
        break;
      case "booking-providers":
        setBookingProvidersList(await getBookingProviderList(organizationId));
        break;
      case "organization-info":
        setOrganizationInfo(await getOrganizationInfo(organizationId));
        break;
    }
  };
  return (
    <DataContext.Provider
      value={{
        loading: loading,
        accomodationsList: accomodationsList,
        ownersList: ownersList,
        suppliesList: suppliesList,
        costsList: costsList,
        costsRuleList: costsRuleList,
        bookingList: bookingList,
        organizationInfo: organizationInfo,
        userInfo: userInfo,
        organizationId: organizationId,
        bookingProvidersList: bookingProvidersList,
        updateData: onChangeUpdate,
      }}
    >



      {!auth.currentUser.emailVerified && emailVerificationBanner && (
        <div className="absolute z-20 flex justify-center w-full h-10 bg-yellow-300 opacity-70">
          <div className="flex items-center justify-center h-full space-x-2">
            <IoWarning className="w-5 h-auto" />
            <p>
              Email not verified, check your inbox or click{" "}
              <button
                className="underline"
                onClick={() => {
                  sendVerificationEmail().then(() =>
                    setEmailVerificationBanner(false)
                  );
                }}
              >
                here
              </button>{" "}
              to send another email.
            </p>
          </div>
          <div className="absolute mt-1 right-2">
            <button onClick={() => setEmailVerificationBanner(false)}>
              <XMarkIcon className="w-5 h-auto" />
            </button>
          </div>
        </div>
      )}
      <div className="flex">
        <SideBarComponent />
        <div className="flex items-start justify-start w-full min-h-full p-10 bg-gray-100 text-blue">
          {!loading ? (
            <Routes>
              <Route path="settings" element={ <UserSettings />} />
              <Route path="settings/subscription" element={ <SubscriptionSetup />} />
              <Route path="/" element={organizationInfo.subscription.expired.toDate() < new Date() ? <Navigate to="/dashboard/settings/subscription"/> : <Home />} />
              <Route path="costs" element={organizationInfo.subscription.expired.toDate() < new Date() ? <Navigate to="/dashboard/settings/subscription"/> : <Costs />} />
              <Route path="costs/new-cost" element={organizationInfo.subscription.expired.toDate() < new Date() ? <Navigate to="/dashboard/settings/subscription"/> : <NewCost />} />
              <Route path="accommodations" element={organizationInfo.subscription.expired.toDate() < new Date() ? <Navigate to="/dashboard/settings/subscription"/> : <Accomodations />} />
              <Route path="accommodations/new-accommodation" element={organizationInfo.subscription.expired.toDate() < new Date() ? <Navigate  to="/dashboard/settings/subscription"/> : <NewAccomodation />} />
              <Route path="rules" element={organizationInfo.subscription.expired.toDate() < new Date() ? <Navigate to="/dashboard/settings/subscription"/> : <CostRules />} />
              <Route path="rules/new-cost-rules" element={organizationInfo.subscription.expired.toDate() < new Date() ? <Navigate to="/dashboard/settings/subscription"/> : <NewCostRule />} />
              <Route path="bookings" element={organizationInfo.subscription.expired.toDate() < new Date() ? <Navigate to="/dashboard/settings/subscription"/> : <Booking />} />
              <Route path="bookings/new-booking" element={organizationInfo.subscription.expired.toDate() < new Date() ? <Navigate to="/dashboard/settings/subscription"/> : <NewBooking />} />
              <Route path="faq" element={organizationInfo.subscription.expired.toDate() < new Date() ? <Navigate to="/dashboard/settings/subscription"/> : <Faq />} />
              <Route path="settings/security" element={organizationInfo.subscription.expired.toDate() < new Date() ? <Navigate to="/dashboard/settings/subscription"/> : <UserSecurity />} />
              <Route path="settings/organization" element={organizationInfo.subscription.expired.toDate() < new Date() ? <Navigate to="/dashboard/settings/subscription"/> : <Organization />} />
              <Route path="settings/owners" element={organizationInfo.subscription.expired.toDate() < new Date() ? <Navigate to="/dashboard/settings/subscription"/> : <OwnersSettings />} />
              <Route
                path="settings/suppliers"
                element={organizationInfo.subscription.expired.toDate() < new Date() ? <Navigate to="/dashboard/settings/subscription"/> : <SuppliersSettings />}
              />
              <Route
                path="settings/otaCommissions"
                element={organizationInfo.subscription.expired.toDate() < new Date() ? <Navigate to="/dashboard/settings/subscription"/> : <OtaCommissionsSettings />}
              />
              <Route path="*" element={<NotFound />} />
            </Routes>
          ) : (
            <LoadingApp />
          )}
        </div>
      </div>
    </DataContext.Provider>
  );
}
export default Dashboard;
