import React, { useRef, useState, useImperativeHandle, forwardRef } from "react";
import { useDatePicker, DatePickerProvider } from "./datePickerContext";

import { format } from "date-fns";
import { DayPicker } from "react-day-picker";
import "./style.css";

export const NewDatePicker = forwardRef(({
  inputClassName,
  className,
  defaultValue,
  placeholder,
  label,
  labelClassName,
  onClick,
  menu,
  onCompleted,
}, ref) => {
  const [selected, setSelected] = useState(defaultValue ? defaultValue : null);
  const [isOpen, setIsOpen] = useState(false);
  const innerRef = useRef(null);

  useImperativeHandle(ref, () => ({
    clearDate: () => {
      setSelected(null);
    }
}));

  let footer = <p>Please pick a day.</p>;
  if (selected) {
    footer = <p>{format(selected, "PPP")}.</p>;
  }

  const clickOutside = (e) => {
    if (innerRef.current && isOpen && !innerRef.current.contains(e.target)) {
      setIsOpen(false);
    }
  };

  document.addEventListener("mousedown", clickOutside);

  return (
    
    <div
      ref={innerRef}
      className={
        className + " " + "flex flex-col items-start justify-start w-full m-1"
      }
    >
      {label && (
        <label
          className={
            labelClassName + " " + "text-lg font-semibold text-blue w-full"
          }
        >
          {label}
        </label>
      )}
      <button
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        className={
          inputClassName +
          " " +
          "w-full h-fit my-1 text-base bg-gray-100 border-2 rounded-md outline-none indent-2 text-blue text-left min-h-[25px] p-1"
        }
      >
        {selected ? footer : placeholder ? placeholder : "Select Date"}
      </button>
      {isOpen && (
        <div className="z-10 stick">
          <DayPicker
            className="absolute z-50 mt-1 bg-white border-2 rounded-lg"
            mode="single"
            selected={selected}
            onSelect={(date) => {
              setSelected(date);
              setIsOpen(false);
              onCompleted && onCompleted(date);
            }}
          />
        </div>
      )}
    </div>
  );
});

export default NewDatePicker;

