import React, { useContext, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { GoTriangleDown, GoTriangleUp } from "react-icons/go";
import { IoMdAddCircleOutline } from "react-icons/io";
import { deleteCostRule, getCostRuleList } from "../../util/firestore-functions";
import PrimaryButton from "../../../../components/buttons/primary-button";
import { IoEye, IoPencil, IoTrashBin } from "react-icons/io5";
import SecondaryButton from "../../../../components/buttons/secondary-button";
import { FaRegBuilding } from "react-icons/fa";
import { BiBuilding, BiHome } from "react-icons/bi";
import { HiOutlineTicket } from "react-icons/hi";
import Modal from "../../../../components/dialogs/dialog";
import { DataContext } from "../..";
import { AiOutlineCheck, AiOutlineMinus } from "react-icons/ai";
import { format, formatISO } from "date-fns";
import { it } from 'date-fns/locale'; 
import CostRuleView from "./components/CostRuleView";
import CostRuleEdit from "./components/CostRuleEdit";
import { NewDatePicker } from "../../../../components/input/fields/date-pickers/newDatePicker";
import { SelectMenu } from "../../../../components/input/select/select-menu";
import { toast } from "react-toastify";
import InfoButton from "../../../../components/buttons/info-button";
import TertiaryButton from "../../../../components/buttons/tertiary-button";
import ResetButton from "../../../../components/buttons/reset-button";

function CostRules() {
  const context = useContext(DataContext);
  let costsRulesList = context.costsRuleList;
  // const [costsRulesList, setCostsRulesList] = useState(context.costsRuleList);
  const [removeModal, setRemoveModal] = useState();
  const [costSelected, setCostSelected] = useState();
  const [tablePage, setTablePage] = useState(1);
  const [viewModal, setViewModal] = useState();
  const [editModal, setEditModal] = useState();
  const [filterDateFrom, setFilterDateFrom] = useState();
  const [filterDateTo, setFilterDateTo] = useState();
  const [costCreationDateOrder, setCreationDateOrder] = useState("reverse");
  const [filterRuleName, setFilterRuleName] = useState();
  const datePickerRef1 = useRef();
  const datePickerRef2 = useRef();
  const [selectedValue, setSelectedValue] = useState([]);

  function formatNumberWithComma(number) {
    if(number == null || number == undefined) { 
      return 0; 
    }
    return number.toString().replace('.', ',');
  }

  var options = context.loading
  ? null
  : context.costsRuleList &&
    context.costsRuleList.map((item) => {
      return { label: item.data.costrulename, value: item.id };
    });


  // var filtredCostList = Array();
  const filteredData = costsRulesList.filter((value) => {
    const creationDate = new Date(1000 * value.data.creationDate.seconds);
    const adjustedFilterDateTo = new Date(filterDateTo);

  if (filterDateFrom && filterDateTo && filterRuleName) {   
    
    adjustedFilterDateTo.setDate(adjustedFilterDateTo.getDate() + 1);

    return (
      format(filterDateFrom, "t") <= format(creationDate, "t") &&
      creationDate < adjustedFilterDateTo &&
      filterRuleName.some((valueFilter) => valueFilter.value === value.id)
    );
  } else if (filterDateFrom && filterDateTo) {    
    
    adjustedFilterDateTo.setDate(adjustedFilterDateTo.getDate() + 1);

    return (
      format(filterDateFrom, "t") <= format(creationDate, "t") &&
      creationDate < adjustedFilterDateTo
    );
  } else if (filterRuleName) {
    return filterRuleName.some((valueFilter) => valueFilter.value === value.id);
  } else {
    return true; // Nessun filtro applicato, mostra tutti i dati
  }
});

  
  
//   if (filterDateFrom && filterDateTo && filterRuleName) {
//     costsRulesList.map((value) => {
//     if (
//       format(filterDateFrom, "t") <=
//         format(new Date(1000 * value.data.creationDate.seconds), "t") &&
//       format(filterDateTo, "t") >=
//         format(new Date(1000 * value.data.creationDate.seconds), "t") && filterRuleName
//     ) {
//       filterRuleName.map((valueFilter) => {

//         if(valueFilter.value === value.id){
//           filtredCostList.push(value);
//         }
//       });
//     }
//   });
// }else if(filterDateFrom && filterDateTo){
//   costsRulesList.map((value) => {
//     if (
//       format(filterDateFrom, "t") <=
//         format(new Date(1000 * value.data.creationDate.seconds), "t") &&
//       format(filterDateTo, "t") >=
//         format(new Date(1000 * value.data.creationDate.seconds), "t")
//     ) {
//       filtredCostList.push(value);
//     }
//   })
// }else if (filterRuleName){
//   costsRulesList.map((value) => {
//     filterRuleName.map((valueFilter) => {
//       if (valueFilter.value === value.id) {
//         filtredCostList.push(value);
//      }
//     })
//   });
// }

const resetSelect = () => {
  setSelectedValue([]);
};

const handleClearFilters = () => {
  datePickerRef1.current.clearDate();
  datePickerRef2.current.clearDate();
  setFilterDateFrom();
  setFilterDateTo();
  setFilterRuleName();
  resetSelect();
};

  const handleCreationDateOrder = () => {
    
    if (costCreationDateOrder === "sort") {
      costsRulesList = costsRulesList.reverse(
        (a, b) => a.data.creationDate.seconds - b.data.creationDate.seconds
      );
      setCreationDateOrder("reverse");
    }
    if (costCreationDateOrder === "reverse") {
      costsRulesList = costsRulesList.sort(
        (a, b) => a.data.creationDate.seconds - b.data.creationDate.seconds
      );
      setCreationDateOrder("sort");
    }
  };

  const showCostRuleInfo = () => {
    setViewModal(true);
  };

  const CostRuleRow = ({ costRuleInfo, id }) => {
    const supplierName = context.suppliesList.find((item) => item.id === costRuleInfo.supplierId);

    let accomodationName = "";
    if (costRuleInfo.accomodationList && costRuleInfo.accomodationList.length > 0) {
      accomodationName = costRuleInfo.accomodationList.map((accomodation) =>
        context.accomodationsList.find((value) => value.id === accomodation).data.name
      ).join(' - '); // Usa la virgola come separatore tra le sistemazioni
    }


    return (
      <tr className="border-b cursor-pointer hover:bg-neutral-100" id={id}>
                <td
          className="p-2 text-center border-r"
          onClick={() => {
            setCostSelected({
              id: id,
              accomodationList: costRuleInfo.accomodationList,
              amount: costRuleInfo.amount,
              costCategory: costRuleInfo.costCategory,
              costTypology: costRuleInfo.costTypology,
              costrulename: costRuleInfo.costrulename,
              name: costRuleInfo.name,
              creationDate: costRuleInfo.creationDate,
              executionDate: costRuleInfo.executionDate,
              recurrency: costRuleInfo.recurrency,
              recurrencyCustom: costRuleInfo.recurrencyCustom,
              paymentDateType: costRuleInfo.paymentDateType,
              endDate: costRuleInfo.endDate,
              paymentType: costRuleInfo.paymentType,
              supplierId: costRuleInfo.supplierId,
            });
            showCostRuleInfo();
          }}
        >
          {costRuleInfo.costrulename}
        </td>
        <td
          className="text-center border-r"
          onClick={() => {
            setCostSelected({
              id: id,
              accomodationList: costRuleInfo.accomodationList,
              amount: costRuleInfo.amount,
              costCategory: costRuleInfo.costCategory,
              costTypology: costRuleInfo.costTypology,
              costrulename: costRuleInfo.costrulename,
              name: costRuleInfo.name,
              creationDate: costRuleInfo.creationDate,
              executionDate: costRuleInfo.executionDate,
              recurrency: costRuleInfo.recurrency,
              recurrencyCustom: costRuleInfo.recurrencyCustom,
              paymentDateType: costRuleInfo.paymentDateType,
              endDate: costRuleInfo.endDate,
              paymentType: costRuleInfo.paymentType,
              supplierId: costRuleInfo.supplierId,
            });
            showCostRuleInfo();
          }}
        >
          {costRuleInfo.costTypology.label}
        </td>

        <td
          className="p-2 text-center border-r"
          onClick={() => {
            setCostSelected({
              id: id,
              accomodationList: costRuleInfo.accomodationList,
              amount: costRuleInfo.amount,
              costCategory: costRuleInfo.costCategory,
              costTypology: costRuleInfo.costTypology,
              costrulename: costRuleInfo.costrulename,
              name: costRuleInfo.name,
              creationDate: costRuleInfo.creationDate,
              executionDate: costRuleInfo.executionDate,
              recurrency: costRuleInfo.recurrency,
              recurrencyCustom: costRuleInfo.recurrencyCustom,
              paymentDateType: costRuleInfo.paymentDateType,
              endDate: costRuleInfo.endDate,
              paymentType: costRuleInfo.paymentType,
              supplierId: costRuleInfo.supplierId,
            });
            showCostRuleInfo();
          }}
        >
          {costRuleInfo.costCategory.label}
        </td>
        <td
          className="p-2 text-center border-r"
          onClick={() => {
            setCostSelected({
              id: id,
              accomodationList: costRuleInfo.accomodationList,
              amount: costRuleInfo.amount,
              costCategory: costRuleInfo.costCategory,
              costTypology: costRuleInfo.costTypology,
              costrulename: costRuleInfo.costrulename,
              name: costRuleInfo.name,
              creationDate: costRuleInfo.creationDate,
              executionDate: costRuleInfo.executionDate,
              recurrency: costRuleInfo.recurrency,
              recurrencyCustom: costRuleInfo.recurrencyCustom,
              paymentDateType: costRuleInfo.paymentDateType,
              endDate: costRuleInfo.endDate,
              paymentType: costRuleInfo.paymentType,
              supplierId: costRuleInfo.supplierId,
            });
            showCostRuleInfo();
          }}
        >
          {costRuleInfo.name}
        </td>
        <td
          className="text-center border-r"
          onClick={() => {
            setCostSelected({
              id: id,
              accomodationList: costRuleInfo.accomodationList,
              amount: costRuleInfo.amount,
              costCategory: costRuleInfo.costCategory,
              costTypology: costRuleInfo.costTypology,
              costrulename: costRuleInfo.costrulename,
              name: costRuleInfo.name,
              creationDate: costRuleInfo.creationDate,
              executionDate: costRuleInfo.executionDate,
              recurrency: costRuleInfo.recurrency,
              recurrencyCustom: costRuleInfo.recurrencyCustom,
              paymentDateType: costRuleInfo.paymentDateType,
              endDate: costRuleInfo.endDate,
              paymentType: costRuleInfo.paymentType,
              supplierId: costRuleInfo.supplierId,
            });
            showCostRuleInfo();
          }}
        >
          {costRuleInfo.paymentType && costRuleInfo.paymentType.value == "Fixed" ? "€ " + formatNumberWithComma(costRuleInfo.amount) : formatNumberWithComma(costRuleInfo.amount) + " %"}
        </td> 
        <td
          className="text-center border-r"
          onClick={() => {
            setCostSelected({
              id: id,
              accomodationList: costRuleInfo.accomodationList,
              amount: costRuleInfo.amount,
              costCategory: costRuleInfo.costCategory,
              costTypology: costRuleInfo.costTypology,
              costrulename: costRuleInfo.costrulename,
              name: costRuleInfo.name,
              creationDate: costRuleInfo.creationDate,
              executionDate: costRuleInfo.executionDate,
              recurrency: costRuleInfo.recurrency,
              recurrencyCustom: costRuleInfo.recurrencyCustom,
              paymentDateType: costRuleInfo.paymentDateType,
              endDate: costRuleInfo.endDate,
              paymentType: costRuleInfo.paymentType,
              supplierId: costRuleInfo.supplierId,
            });
            showCostRuleInfo();
          }}
        >
          {costRuleInfo.recurrency && costRuleInfo.recurrency.value == "custom" ? ("every " + costRuleInfo.recurrencyCustom + " days") : (costRuleInfo.recurrency && costRuleInfo.recurrency.label ? costRuleInfo.recurrency.label : "")}
        </td>
        <td
          className="text-center border-r"
          onClick={() => {
            setCostSelected({
              id: id,
              accomodationList: costRuleInfo.accomodationList,
              amount: costRuleInfo.amount,
              costCategory: costRuleInfo.costCategory,
              costTypology: costRuleInfo.costTypology,
              costrulename: costRuleInfo.costrulename,
              name: costRuleInfo.name,
              creationDate: costRuleInfo.creationDate,
              executionDate: costRuleInfo.executionDate,
              recurrency: costRuleInfo.recurrency,
              recurrencyCustom: costRuleInfo.recurrencyCustom,
              paymentDateType: costRuleInfo.paymentDateType,
              endDate: costRuleInfo.endDate,
              paymentType: costRuleInfo.paymentType,
              supplierId: costRuleInfo.supplierId,
            });
            showCostRuleInfo();
          }}
        >
          {format(costRuleInfo.creationDate.toDate(), "dd/MM/yyyy", { locale: it }  )}
        </td>
        <td className="text-center border-r" 
                  onClick={() => {
                    setCostSelected({
                      id: id,
                      accomodationList: costRuleInfo.accomodationList,
                      amount: costRuleInfo.amount,
                      costCategory: costRuleInfo.costCategory,
                      costTypology: costRuleInfo.costTypology,
                      costrulename: costRuleInfo.costrulename,
                      name: costRuleInfo.name,
                      creationDate: costRuleInfo.creationDate,
                      executionDate: costRuleInfo.executionDate,
                      recurrency: costRuleInfo.recurrency,
                      recurrencyCustom: costRuleInfo.recurrencyCustom,
                      paymentDateType: costRuleInfo.paymentDateType,
                      endDate: costRuleInfo.endDate,
                      paymentType: costRuleInfo.paymentType,
                      supplierId: costRuleInfo.supplierId,
                    });
                    showCostRuleInfo();
                  }}
                  >{accomodationName}</td>
        <td className="">
            <div className="flex items-center justify-center">
              <PrimaryButton
                onClick={() => {
                  setCostSelected({
                    id: id,
                    accomodationList: costRuleInfo.accomodationList,
                    amount: costRuleInfo.amount,
                    costCategory: costRuleInfo.costCategory,
                    costTypology: costRuleInfo.costTypology,
                    costrulename: costRuleInfo.costrulename,
                    name: costRuleInfo.name,
                    creationDate: costRuleInfo.creationDate,
                    executionDate: costRuleInfo.executionDate,
                    recurrency: costRuleInfo.recurrency,
                    recurrencyCustom: costRuleInfo.recurrencyCustom,
                    paymentDateType: costRuleInfo.paymentDateType,
                    endDate: costRuleInfo.endDate,
                    paymentType: costRuleInfo.paymentType,
                    supplierId: costRuleInfo.supplierId,
                  });
                  setEditModal(true);
                }}
                content={<IoPencil className="mx-auto" />}
                className={"p-1 mx-2 max-w-[100px]"}
              />
              <SecondaryButton
                onClick={() => {
                  setCostSelected({
                    id: id,
                    accomodationList: costRuleInfo.accomodationList,
                    amount: costRuleInfo.amount,
                    costCategory: costRuleInfo.costCategory,
                    costTypology: costRuleInfo.costTypology,
                    costrulename: costRuleInfo.costrulename,
                    name: costRuleInfo.name,
                    creationDate: costRuleInfo.creationDate,
                    executionDate: costRuleInfo.executionDate,
                    recurrency: costRuleInfo.recurrency,
                    recurrencyCustom: costRuleInfo.recurrencyCustom,
                    paymentDateType: costRuleInfo.paymentDateType,
                    endDate: costRuleInfo.endDate,
                    paymentType: costRuleInfo.paymentType,
                    supplierId: costRuleInfo.supplierId,
                  });
                  setRemoveModal(true);
                }}
                content={<IoTrashBin className="mx-auto" />}
                className={"p-1 mx-2 max-w-[100px]"}
              />
            </div>
        </td>
      </tr>
      
    );
  };
  return (
    <>
    
    <InfoButton nameComponent={'costRules'}/>
      <div className="w-full">
        <h1 className="text-3xl font-bold">Cost Rules</h1>
        <Link
          className="flex flex-row items-center my-5 text-2xl font-normal cursor-pointer w-fit"
          to="new-cost-rules"
        >
          <IoMdAddCircleOutline className="mr-1 w-[2rem]" />
          <p>Add a new cost rules</p>
        </Link>
        <hr className="w-full max-w-5xl mb-6" />

        {costsRulesList && costsRulesList.length > 0 ? (
          <>
            <table className="w-full mb-10 bg-white rounded-lg shadow-md">
              <tbody className="">
                <tr>
                  <th className="p-4 text-left w-fit">Filter Cost</th>
                  <th className="text-left w-fit">
                  <div className="flex items-center justify-start space-x-20">
                    <div className="flex items-center justify-start space-x-5">
                      <h4>By Cost Date:</h4>
                      <NewDatePicker
                        className={"w-[200px]"}
                        placeholder={"From"}
                        ref={datePickerRef1}
                        onCompleted={(el) => {
                          setFilterDateFrom(el);
                        }}
                      />
                      <NewDatePicker
                        className={"w-[200px]"}
                        placeholder={"To"}
                        ref={datePickerRef2}
                        onCompleted={(el) => {
                          if (filterDateFrom && el < filterDateFrom) {
                            toast.error("Check dates");
                            setFilterDateTo();
                          } else {
                            setFilterDateTo(el);
                          }
                        }}
                      />
                      </div>
                      <div className="flex items-center justify-start space-x-5">
                                            <h4>Rule Name:</h4>
                      <SelectMenu
                          className={"w-[200px]"}
              placeholder={"Select..."}
              options={options}
              isMulti={true}
              isClearable
              value={selectedValue}
              // isLoading={context.loading}
              isSearchable
              onChange={(value) => {
                if (value.length > 0) {
                  setSelectedValue(value);  
                  setFilterRuleName(value);
                }else{
                  setSelectedValue([]);  
                  setFilterRuleName(options)
                }
                }}
            />
                    </div>
</div>
                  </th>
                  <th className="text-left w-fit">
                    <div className="flex items-center justify-start space-x-5">
                    <ResetButton onReset={handleClearFilters}></ResetButton>
                    </div>
                    </th>
                  <th></th>
                </tr>
              </tbody>
            </table>
            <table className="table w-full  bg-white rounded-lg shadow-md">
              <thead className="sticky top-0 table-header-group bg-white">
                <tr className="border-b-2">
                  <th className="w-48 p-4 border-r">Rule name</th>
                  <th className="w-48 p-4 border-r">Typology</th>
                  <th className="p-4 border-r w-fit">Category</th>
                  <th className="w-48 p-4 border-r">Sub category</th>
                  {/* <th className="w-48 p-4 border-r">Payment type</th> */}
                  <th className="p-4 border-r w-28">Amount</th>
                  <th className="p-4 border-r w-28">Recurrence</th>
                  <th
                    className="p-4 border-r w-96 hover:cursor-pointer hover:bg-neutral-50"
                    onClick={handleCreationDateOrder}
                  >
                    <div className="flex items-center justify-center ">
                      {costCreationDateOrder === "sort" && (
                        <GoTriangleUp className="" />
                      )}
                      {costCreationDateOrder === "reverse" && (
                          <GoTriangleDown className="" />
                        )}
                      <p className="ml-2">Creation Date</p>
                    </div>
                  </th>
                  {/* <th className="p-4 border-r w-28">Supplier</th> */}
                  <th className="p-4 border-r w-28">Accommodations</th>
                  <th className="w-40">Actions</th>
                </tr>
              </thead>

              <tbody className="w-full h-10 overflow-scroll ">
              {
  ((filterDateFrom && filterDateTo) || filterRuleName) ? (
    filteredData.length > 0 ? (
      filteredData.map((cost, key) => {
        if (key >= tablePage * 10 - 10 && key < tablePage * 10) {
          return (
            <CostRuleRow costRuleInfo={cost.data} id={cost.id} key={key} />
          );
        }
        return null;
      })
    ) : (
      <tr><td colSpan={9} aria-colspan={9} className="text-lg font-semibold text-center">No Cost Rule</td></tr>
    )
  ) : (
    !filterRuleName && (
      filteredData.length > 0 ? (
        filteredData.map((cost, key) => {
          if (key >= tablePage * 10 - 10 && key < tablePage * 10) {
            return (
              <CostRuleRow costRuleInfo={cost.data} id={cost.id} key={key} />
            );
          }
          return null;
        })
      ) : (
        <tr> <td colSpan={9} aria-colspan={9} className="text-lg font-semibold text-center">No Cost Rule</td></tr>
      )
    )
  )
}


              </tbody>
              <tfoot>
                <tr className="border-b-2 bg-neutral-50">
                  <th className="p-4 w-fit"></th>
                  <th className="p-4 w-28"></th>
                  <th className="w-48 "></th>
                  <th className="w-48 "></th>
                  <th className="p-4 "></th>
                  <th></th>
                  <th></th>
                  <th className="p-4 border-r w-28"></th>
                  <th className="flex items-center justify-center w-40 px-4 space-x-4 ">
                    <SecondaryButton
                      content={"<"}
                      className={"w-[30px] p-1"}
                      onClick={() =>
                        tablePage != 1 ? setTablePage(tablePage - 1) : null
                      }
                    />

                    <p className="text-xs text-center">
                      {tablePage} of {Math.ceil(costsRulesList.length / 10)}
                    </p>
                    <div className="flex items-center justify-center space-x-2">
                      {
                        <SecondaryButton
                          content={">"}
                          className={"w-[30px] p-1"}
                          onClick={() =>
                            Math.ceil(costsRulesList.length / 10) != tablePage
                              ? setTablePage(tablePage + 1)
                              : null
                          }
                        />
                      }
                    </div>
                  </th>
                </tr>
              </tfoot>
            </table>
          </>
        ) : (
          <div className="w-full text-lg font-semibold text-center">
            No Cost rules
          </div>
        )}
        { (costsRulesList.length >= 10 && !filterDateFrom && !filterDateTo && !filterRuleName) || filteredData.length >= 10 ?
          <div className="font-bold flex items-center justify-end p-4 text-petroil rounded-full ">
          <p className="mr-1">Do you need support?</p>
          <a href="mailto:support@bilemon.com" className="text-petroil hover:brightness-125 rounded-full bg-transparent"
          >
            Contact us.
          </a>
        </div>
        :
        <div className="font-bold absolute bottom-0 right-10 flex items-center justify-end p-4 text-petroil rounded-full ">
  <p className="mr-1">Do you need support?</p>
  <a href="mailto:support@bilemon.com" className="text-petroil hover:brightness-125 rounded-full bg-transparent"
  >
    Contact us.
  </a>
</div>
        }
      </div>
      <Modal
        externalControl
        title={"Remove Cost Rule"}
        body={
          <>
            <h1 className="text-lg mb-3">Are you sure to remove "{costSelected && costSelected.costrulename}" cost rule?</h1>
            <div className="flex justify-end">
            <TertiaryButton
                className={"bg-slate-400 mr-2 text-white p-2 w-fit max-w-[130px] mt-2"}
                content={"Cancel"}
                onClick={() => {
                  setRemoveModal(false)}}
              />
              <TertiaryButton
                className={"bg-red text-white p-2 w-fit max-w-[130px] mt-2"}
                content={"Yes, I'm sure"}
                onClick={() => {
                  deleteCostRule(costSelected.id, context.organizationId)
                    .then(() => {
                      context.updateData("cost-rules");
                      setRemoveModal(false);
                      setCostSelected();
                    })
                    .catch((error) => toast.error("Error while deleting cost rule: " + error));
                }}
              />              
            </div>
          </>
        }
        status={removeModal}
        onClose={() => setRemoveModal(false)}
      />
      <Modal
        externalControl
        status={editModal}
        title={"Edit"}
        className={"max-w-[850px]"}
        body={
          <CostRuleEdit
            selectedCostRule={costSelected}
            onClose={() => setEditModal(false)}
          />
        }
        onClose={() => setEditModal(false)}
      />
      <Modal
        externalControl
        status={viewModal}
        title={"Cost Rule Details"}
        className={"w-[600px]"}
        body={
          <CostRuleView
            selectedCost={costSelected}
            onClose={() => setViewModal(false)}
          />
        }
        onClose={() => setViewModal(false)}
      />
    </>
  );
}

export default CostRules;
