import { CheckIcon, TicketIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { formatISO } from "date-fns";
import React, { useContext } from "react";
import { DataContext } from "../../..";
import SecondaryButton from "../../../../../components/buttons/secondary-button";
import TertiaryButton from "../../../../../components/buttons/tertiary-button";

const CostRuleView = ({ selectedCost, onClose }) => {
  const context = useContext(DataContext);

  const selectedAccomodation = context.accomodationsList.filter(x => selectedCost.accomodationList && selectedCost.accomodationList.filter(accomodation => x && x.id == accomodation).length > 0);
  let accomodationListsName = "";
  for (let i = 0; i < selectedAccomodation.length; i++) {
    accomodationListsName += selectedAccomodation[i].data.name;
    if (i < selectedAccomodation.length -1){
      accomodationListsName +=  ", ";
    }
  }
  
  const supplierData = selectedCost.supplierId
  ? context.suppliesList.find((item) => item.id === selectedCost.supplierId)?.data
  : null;

  return (
    <div className="text-base">
      <div className="flex items-center space-x-2">
        <h4 className="font-bold">Cost Rule Name:</h4>
        <p>{selectedCost.costrulename}</p>
      </div>
      <div className="flex items-center space-x-2">
        <h4 className="font-bold">Cost Type:</h4>
        <p>{selectedCost.costTypology.label}</p>
      </div>
      <div className="flex items-center space-x-2">
        <h4 className="font-bold">Payment Type:</h4>
        <p>{selectedCost.paymentType.label}</p>
      </div>
      <div className="flex items-center space-x-2">
        <h4 className="font-bold">Amount:</h4>
        <p>{selectedCost.paymentType && selectedCost.paymentType.value == "Fixed" ? "€ " + selectedCost.amount : selectedCost.amount + " %"}</p>
      </div>

      {(selectedCost.costTypology.value == 0 || selectedCost.costTypology.value == 2) && (
        <div className="flex items-center space-x-2">
          <h4 className="font-bold">Accommodations:</h4>
          <p>{accomodationListsName}</p>
        </div>
      )}
      <div className="flex items-center space-x-2">
        <h4 className="font-bold">Creation date:</h4>
        <p>
          {formatISO(selectedCost.creationDate.toDate(), {
            representation: "date",
          })}
        </p>
      </div>
      <div className="flex items-center space-x-2">
        <h4 className="font-bold">Execution Date:</h4>
        <p>
          {selectedCost.executionDate
            ? formatISO(selectedCost.executionDate.toDate(), {
              representation: "date",
            })
            : "Not defined"}
        </p>
      </div>
      {selectedCost.costTypology && selectedCost.costTypology.value === 2 && (
        <div className="flex items-center space-x-2">
          <h4 className="font-bold">Trigger:</h4>
          <p>{selectedCost.paymentDateType ? selectedCost.paymentDateType.label : "Not defined"}</p>
        </div>
      )}
      {selectedCost.costTypology && selectedCost.costTypology.value !== 2 && (
        <div className="flex items-center space-x-2">
          <h4 className="font-bold">Recurrency:</h4>
          <p>
            {selectedCost.recurrency && selectedCost.recurrency.value == "custom" ? ("every " + selectedCost.recurrencyCustom + " days") : (selectedCost.recurrency && selectedCost.recurrency.label ? selectedCost.recurrency.label : "")}
          </p>
        </div>
      )}
      <div className="flex items-center space-x-2">
        <h4 className="font-bold">End Date:</h4>
        <p>{selectedCost.endDate
            ? formatISO(selectedCost.endDate.toDate(), {
              representation: "date",
            })
            : "Not defined"}</p>
      </div>
      <div className="flex items-center space-x-2">
        <h4 className="font-bold">Category:</h4>
        <p>{selectedCost.costCategory.label}</p>
      </div>
      <div className="flex items-center space-x-2">
        <h4 className="font-bold">{selectedCost.costCategory && selectedCost.costCategory.value == "booking-commission" ? "Booking Provider:" : "Sub-Category:"}</h4>
        <p>{selectedCost.name}</p>
      </div>
      <div className="flex items-center space-x-2">
          <h4 className="font-bold">Supplier:</h4>
          <p>{supplierData ? supplierData.name  : "No supplier available" }</p>
        </div>

      <div className="flex justify-end">
        <TertiaryButton
          content={"Close"}
          className={"bg-red text-white p-2 w-fit max-w-[130px] mt-2"}
          onClick={onClose}
        />
      </div>
    </div>
  );
};

export default CostRuleView;
