import { Chip } from "@mui/material";
import { useState } from "react";
import PrimaryButton from "../../components/buttons/primary-button";
import SecondaryButton from "../../components/buttons/secondary-button";
import { NeutralButton } from "../../components/buttons/neutral-button";
import { toast } from "react-toastify";
import TextField from "../../components/input/fields/text-field";
import {
  createOrganization,
  setUserExtraInfo,
  getUserExtraInfo
} from "../dashboard/util/firestore-functions";
import { SubscriptionCard } from "../../components/buttons/subscription-card";

import { logout } from "../../../src/pages/dashboard/util/firestore-functions"
import { useNavigate } from "react-router-dom";
var emailRegex =
  /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

const OrganizationSetup = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [formValues, setFormValues] = useState({
    organizationName: "",
    organizationState: "",
    organizationNumberOfEmployee: "",
    subscription : {
      expired: "",
      properties: 0,
      plan: "",
      amount: 0,
      recurrency: "",
      description: "",
      propertiesExtra: 0
      }
  });

  const handleLogout = () => {
    logout().then(() => navigate('/login'));
  };

  const handleSubmit = () => {
    createOrganization({
      name: formValues.organizationName,
      state: formValues.organizationState,
      numberOfEmployee: formValues.organizationNumberOfEmployee,
      collaboratorList: "emailList",
      website: "",
      phoneNumber: "",
      companyEmail: "",
      address: {
        street: "",
        city: "",
        cap: "",
      },
      logoUrl: "",
      subscription: {
        amount: formValues.subscription.amount,
        expired: formValues.subscription.expired,
        amountExtra: formValues.subscription.amountExtra,
        plan: formValues.subscription.plan,
        properties: formValues.subscription.properties,
        recurrency: formValues.subscription.recurrency,
        propertiesExtra: formValues.subscription.propertiesExtra,
        
      },
      powerBiWorkspaceId: "",
      powerBiDataSetId: "",
      powerBiReportId: ""
    }).then((id) => {

      setUserExtraInfo({ organization: id, newsLetterAgreement: false }).then(
        window.location.replace('/dashboard')
        );
    });
  };


  return (
    
    <div>
       <a className="fixed top-4 right-8 font-bold cursor-pointer text-petroil text-base p-2" onClick={handleLogout}>
        Logout
      </a>
      <div className="flex items-start justify-center h-fit mb-28">
        <div className="max-w-md m-10">
          <div className="flex flex-col items-center justify-center px-[10%] text-petroil">
            <img
              className="h-auto w-fit"
              alt="Bilemon logo"
              src="/Logo-Petroil.png"
            />
            <p className="mt-4 text-center">Welcome to Bilemon! </p>
          </div>
          <div>
            <p className="mt-4 font-bold text-center text-petroil">
              {step} of 4 steps completed
            </p>
          </div>
          {step == 1 && (
            <SubscriptionStep
            setFormValues={setFormValues}
            onComplete={() => setStep(2)}
            value={formValues}
            />
            
          )}
          {step == 2 && (
            <FirstStep
              onComplete={(value) => setStep(value)}
              onChange={(value) => {
                (formValues.organizationName = value)}}
              value={formValues.organizationName}
            />
          )}
          {step == 3 && (
            <SecondStep
              onChange={(value) => (formValues.organizationState = value)}
              onComplete={(value) => setStep(value)}
              value={formValues.organizationState}
            />
          )}
          {step == 4 && (
            <ThirdStep
              onBack={value => setStep(value)}
              onComplete={handleSubmit}
              onChange={(value) =>
                (formValues.organizationNumberOfEmployee = value)
              }
            />
          )}
        </div>
      </div>
    </div>
  );
};

const SubscriptionStep = ({ onComplete, value, setFormValues }) => {

  // Change here if you need to change price
  const descriptionHost = "Our exclusive \"Plan Host\" subscription is a strategic cornerstone in our business intelligence solution crafted for hosts aiming to maximize profitability. Priced at just €25.99, this plan is meticulously designed for users managing up to 4 properties. Elevate your hosting journey with intelligence that transforms properties into lucrative assets. Embrace the Host plan—where data meets profitability.";
  const descriptionPropertyManager = "Embrace proactive strategies with our \"Plan PM\" subscription—an indispensable toolkit for those overseeing a dynamic portfolio. Priced at an unbeatable €6.49 per accommodation, this plan isn't just cost-effective; it's your key to absolute control. Achieve profitability with precision timing and elevate your game, seize control, and make profitability a reality—welcome to the future of property management excellence.";
  const amountHost = 25.99;
  const amountPropertyManager = 32.45;
  const amountExtraProperty = 6.49;
  
  const handleSubscriptionChange = (formValues) => {
  
    setFormValues({
      subscription: {
        expired: formValues.expired,
        properties: formValues.properties,
        plan: formValues.plan,
        amount: formValues.amount,
        recurrency: formValues.recurrency,
        propertiesExtra: formValues.propertiesExtra,
        amountExtra: formValues.amountExtra
      },
    });
    };
  
    const expiredDate = new Date();
    expiredDate.setDate(expiredDate.getDate() + 14);
    
    

    const todayDate = new Date();
    todayDate.setDate(todayDate.getDate());

    
    
  return (
    <>
    <h2 className="font-bold mt-4 text-center text-petroil">Our plans</h2>
    <h3 className="mt-4 text-center text-petroil">Select the plan best suited to your needs</h3>
    <div className="flex justify-center ">
      <div className="p-12">

      <SubscriptionCard
      numberProperty={4}
      amount={amountHost}
      plan={"host"}
      description={descriptionHost}
      onChange={handleSubscriptionChange}
      onClick={() => {
        handleSubscriptionChange({
          expired: expiredDate,
          properties: 0,
          plan: "host",
          amount: amountHost,
          recurrency: "monthly",
          amountExtra: 0,
          propertiesExtra: 0
        })
        onComplete(2)
      }}
      />
      </div>
      <div className="p-12">

      <SubscriptionCard
          numberProperty={5}
          amount={amountPropertyManager}
          plan={"propertyManager"}
          description={descriptionPropertyManager}
          amountExtra={amountExtraProperty}
          onChange={handleSubscriptionChange}
          onClick={() => {
            handleSubscriptionChange({
              expired: expiredDate,
              properties: 0,
              plan: "propertyManager",
              amount: amountPropertyManager,
              recurrency: "monthly",
              amountExtra: amountExtraProperty,
              propertiesExtra: 0
            })
            onComplete(2)
          }}
        />
      </div>
      </div>
      <p className="flex justify-center mt-6">Try it for free 14 days.</p>      
      </>
  )

}

const FirstStep = ({ onComplete, onChange, value }) => {
  
  
  return (
    <div className="mt-10 space-y-5">
      <TextField
        labelClassName={"text-4xl font-bold text-petroil mb-4"}
        inputClassName={"bg-transparent py-2 text-lg"}
        onFocusEffect={true}
        label={"What is your company's name?"}
        placeholder={"Company name"}
        onChange={(e) => {
          onChange(e.target.value);
          }
        }
        defaultValue={value}
      />
      <div className="flex items-center justify-between w-full">
      <button className=" p-3 max-w-[16rem] mr-10 w-full min-w-fit h-fit bg-petroil text-white rounded-lg text-base font-semibold hover:brightness-125" onClick={() => onComplete(1)}>Go back</button>
      
        <SecondaryButton
          className={"py-3 px-10 w-fit"}
          content={"Next >"}
          onClick={() => {
            if(onChange){

              onComplete(3)
            } else {
              toast.error("Invalid Company name");
            }
          }}
        />
      </div>
    </div>
  );
};

const SecondStep = ({ onComplete, onChange, value }) => {

  
  
  return (
    <div className="mt-10 space-y-5">
      <TextField
        labelClassName={"text-4xl font-bold text-petroil mb-4"}
        inputClassName={"bg-transparent py-2 text-lg"}
        onFocusEffect={true}
        label={"What is your company's country?"}
        placeholder={"Company country"}
        onChange={(e) => {
          onChange(e.target.value);
        }
        }
        defaultValue={value}
      />
      <div className="flex items-center justify-between w-full">
      <button className=" p-3 max-w-[16rem] mr-10 w-full min-w-fit h-fit bg-petroil text-white rounded-lg text-base font-semibold hover:brightness-125" onClick={() => onComplete(2)}>Go back</button>
        <SecondaryButton
          className={"py-3 px-10 w-fit"}
          content={"Next >"}
          onClick={() => {
            if(onChange){

              onComplete(4)
            } else {
              toast.error("Invalid Company country");
            }
          }}
        />
      </div>
    </div>
  );
};

const ThirdStep = ({ onComplete, onChange, onBack }) => {
  return (
    <div className="mt-10 space-y-10">
      <h2 className="text-4xl font-bold text-petroil">
        Select a employee range
      </h2>
      <div className="flex flex-wrap items-center justify-center mx-auto ">
        <button
          onClick={() => {
            onChange("1-19");
            onComplete(5);
          }}
          className="p-10 mx-5 my-5 min-w-[150px] border-2"
        >
          1 - 19
        </button>
        <button
          onClick={() => {
            onChange("20-49");
            onComplete(5);
          }}
          className="p-10 mx-5 my-5 min-w-[150px] border-2"
        >
          20 - 49
        </button>
        <button
          onClick={() => {
            onChange("50-99");
            onComplete(5);
          }}
          className="p-10 mx-5 my-5 min-w-[150px] border-2"
        >
          50 - 99
        </button>
        <button
          onClick={() => {
            onChange("+100");
            onComplete(5);
          }}
          className="p-10 mx-5 my-5 min-w-[150px] border-2"
        >
          +100
        </button>
      </div>
      <div className="flex items-center justify-between w-full">
      <button className="mt-10 p-3 max-w-[16rem] mr-10 w-full min-w-fit h-fit bg-petroil text-white rounded-lg text-base font-semibold hover:brightness-125" onClick={() => onBack(3)}>Go back</button>
      </div>
    </div>
  );
};

const FourthStep = ({
  onComplete,
  onNewEmail,
  emailList,
  deleteSelectedEmail,
  goBack,
}) => {
  return (
    <div className="mt-10 space-y-5">
      Not visible
      <div className="items-center justify-between hidden w-full ">
        <label
          className={"px-5 w-fit cursor-pointer hover:underline text-left"}
          onClick={() => goBack(3)}
        >
          Go back
        </label>
        <PrimaryButton
          className={"py-3 px-10 w-fit"}
          content={"Create your organization"}
          onClick={() => onComplete()}
        />
      </div>
    </div>
  );
};

export default OrganizationSetup;
