import React, { useState } from "react";

import { addDays, format } from "date-fns";
import { DateRange, DayPicker } from "react-day-picker";

const pastMonth = new Date(2020, 10, 15);

export const RangeDatePicker = ({
  inputClassName,
  className,
  placeholder,
  label,
  labelClassName,
  onClick,
  menu,
  onCompleted,
}) => {
  const [range, setRange] = useState();

  let footer = <p>Please pick the check-in day.</p>;
  if (range?.from) {
    if (!range.to) {
      footer = <p>{format(range.from, "PPP")}</p>;
    } else if (range.to) {
      footer = (
        <p>
          {format(range.from, "PPP")}-{format(range.to, "PPP")}
        </p>
      );
    }
  }

  return (
    <div
      className={
        className + " " + "flex flex-col items-start justify-start w-full"
      }
    >
      {label && (
        <label
          className={
            labelClassName + " " + "text-lg font-semibold text-blue w-full"
          }
        >
          {label}
        </label>
      )}
      <button
        onClick={onClick}
        className={
          inputClassName +
          " " +
          "w-full h-fit my-1 text-base bg-gray-100 border-2 rounded-md outline-none indent-2 text-blue text-left"
        }
        placeholder={placeholder}
      >
        {range ? footer : placeholder}
      </button>
      {menu && (
        <DayPicker
          className="absolute z-10 mt-[6rem] bg-white border-2 rounded-lg"
          mode="range"
          selected={range}
          onSelect={(range) => {
            setRange(range);
            if (range.to) {
              onCompleted(range);
            }
          }}
        />
      )}
    </div>
  );
};
