import React, { useContext, useRef, useState } from "react";
import SecondaryButton from "../../../../../components/buttons/secondary-button";
import { CurrencyField } from "../../../../../components/input/fields/currency-field";
import { NewDatePicker } from "../../../../../components/input/fields/date-pickers/newDatePicker";
import TextField from "../../../../../components/input/fields/text-field";
import { UnitField } from "../../../../../components/input/fields/unit-field";
import { PercentField } from "../../../../../components/input/fields/percent-field";
import PrimaryButton from "../../../../../components/buttons/primary-button";
import { useNavigate } from "react-router-dom";
import { SelectMenu } from "../../../../../components/input/select/select-menu";
import Modal from "../../../../../components/dialogs/dialog";
import { toast } from "react-toastify";
import { format } from "date-fns";
import {
    createCostRule,
    getAccomodation,
    setSupplier,
    setBookingProvider
} from "../../../util/firestore-functions";
import { DataContext } from "../../..";
import { MdOutlinePostAdd } from "react-icons/md";
import { NewSupplier } from "./new-supplier";
import { NewBookingProvider } from "./new-booking-provider";
import { Checkbox, FormControlLabel } from "@mui/material";
import SubCategorySelect from "../components/sub-category-select";

export const NewCostRule = () => {
    const context = useContext(DataContext);
    const accommodationsList = context.accomodationsList;
    const [costTypology, setCostTypology] = useState(null);
    const [costCategory, setCostCategory] = useState(null);
    const [selectedSecondaryMenu, setSelectedSecondaryMenu] = useState();
    const [openNewSupplierModal, setOpenNewSupplierModal] = useState();
    const [openNewBookingProviderModal, setOpenNewBookingProviderModal] = useState();
    const [paymentType, setPaymentType] = useState(null);
    const [paymentDateType, setPaymentDateType] = useState(null);
    const [recurrencyType, setRecurrencyType] = useState(null);
    const [recurrencyDate, setRecurrencyDate] = useState(null);
    var newCategory = null;
    const [formValues, setFormValues] = useState({
        costrulename: null,
        name: null,
        amount: null,
        costTypology: null,
        costCategory: null,
        accomodationList: null,
        recurrency: null,
        recurrencyCustom: null,
        supplierId: null,
        paymentType: null,
        paymentDateType: null,
        creationDate: null,
        executionDate: null,
        endDate: null
    });
    const closeModal = () => {
        setOpenNewSupplierModal(false);
      };

    const navigator = useNavigate("");
    const costTypologyOptions = [
        {
            label: "Accommodation",
            value: 0,
        },
        {
            label: "Company",
            value: 1,
        },
        {
            label: "Booking",
            value: 2,
        },
    ];

    const paymentTypeOptions = [
        {
            label: "Fixed",
            value: "Fixed",
        },
        {
            label: "Percentage",
            value: "Percentage",
        }
    ];

    const recurrencyOption = [
        {
            label: "Daily",
            value: "daily"
        },
        {
            label: "Weekly",
            value: "weekly"
        },
        {
            label: "Monthly",
            value: "monthly",
        },
        {
            label: "Yearly",
            value: "yearly",
        },
        {
            label: "Custom",
            value: "custom"
        }
    ];

    const costDateOption = [
        {
            label: "Booking date",
            value: null
        },
        {
            label: "Check-In date",
            value: "check-in"
        },
        {
            label: "Check-Out date",
            value: "check-out",
        }
    ];

    const checkFields = () => {
        var regexNumbers = /^[0-9]*(\.[0-9]{0,2})?$/;
        if (
            formValues.costrulename &&
            formValues.amount &&
            formValues.costTypology &&
            formValues.costCategory &&
            formValues.paymentType             
        ) {
            if (!regexNumbers.test(formValues.amount)) {
                toast.error("Check amount field");
                return;
            }
            if (formValues.costTypology.value !== 2) {
                if (!formValues.recurrency) {
                    toast.error("Check all required fields");
                    return false;
                }
                if (formValues.recurrency.value == "custom") {
                    if (!formValues.recurrencyCustom) {
                        toast.error("Custom recurrency is required");
                        return false;
                    }
                    else if (formValues.recurrencyCustom <= 0) {
                        toast.error("Custom recurrency must be greater than 0");
                        return false;
                    }
                }
                if (formValues.endDate && recurrencyDate && formValues.endDate <= recurrencyDate) {
                    toast.error("End date must be greater than Recurrency start date");
                    return false;
                }
                if (recurrencyDate && recurrencyDate < new Date()) {
                    toast.error("The recurrency date must be greater than today");
                    return false;
                }

            }
            if (formValues.costTypology.value !== 1) {
                if (!formValues.accomodationList || formValues.accomodationList.length == 0) {
                    toast.error("This cost rule needs an accommodation");
                    return false;
                }
            }

            return true;
        } else {
            toast.error("Check all required fields");
            return false;
        }
    };

    const setExecutionDate = () => {
        formValues.executionDate = recurrencyDate ? recurrencyDate : new Date();
        if (!recurrencyDate) {
            switch (formValues.recurrency.value) {
                case "daily":
                    return formValues.executionDate.setDate((formValues.creationDate.getDate() + 1));
                case "weekly":
                    return formValues.executionDate.setDate((formValues.creationDate.getDate() + 7));
                case "monthly":
                    return formValues.executionDate.setMonth(formValues.creationDate.getMonth() + 1);
                case "yearly":
                    return formValues.executionDate.setFullYear(formValues.creationDate.getFullYear() + 1);
                case "custom":
                    return formValues.executionDate.setDate(formValues.creationDate.getDate() + formValues.recurrencyCustom);
            }
        }
    };

    return (
        <>
            <div className="w-full">
                <div className="mb-10">
                    <h1 className="text-3xl">New Cost Rule</h1>
                </div>
                <div className="w-full max-w-5xl p-10 bg-white border-2 rounded-lg shadow-md">
                    <h2 className="text-2xl">Cost rule details</h2>
                    <hr className="my-4 " />
                    <div className="flex flex-col justify-between ml-auto mr-auto h-fit">

                        <div className="flex flex-wrap items-center justify-start">
                            <TextField
                                label={"Cost rule name *"}
                                placeholder={"Insert the cost rule"}
                                className={"max-w-xs mr-10 m-1"}
                                inputClassName={"p-1"}
                                onChange={(e) => (formValues.costrulename = e.target.value)}
                                defaultValue={formValues.costrulename}
                            />
                        </div>
                        <div className="flex flex-wrap items-center justify-start mb-4">
                            <SelectMenu
                                label={"Cost typology *"}
                                className={"max-w-xs mr-10"}
                                isSearchable={false}
                                options={costTypologyOptions}
                                placeholder={"Select type of cost"}
                                onChange={(value) => {
                                    formValues.costTypology = value;
                                    formValues.accomodationList = null;
                                    formValues.costCategory = null;
                                    formValues.recurrency = null;
                                    formValues.recurrencyCustom = null;
                                    formValues.paymentDateType = null;
                                    setCostCategory(null);
                                    setCostTypology(value);
                                    setSelectedSecondaryMenu(null);
                                    setRecurrencyType(null);
                                    setPaymentDateType(null);
                                    if (value.value !== 2) {
                                        formValues.paymentType = {
                                            label: "Fixed",
                                            value: "Fixed",
                                        };
                                        setPaymentType("Fixed");
                                    }
                                    else {
                                        formValues.paymentType = null;
                                        setPaymentType(null);
                                    }
                                }}
                            />
                            {costTypology && costTypology.value != 1 && (
                                <SelectMenu
                                    label={
                                        "Select your accommodations *"
                                    }
                                    isClearable
                                    className={"max-w-xs min-w-[250px] "}
                                    isMulti={/* changed ever in true --> costTypology.value == 0 ? true : false*/ true}
                                    isSearchable
                                    onChange={(value) => {
                                        if (formValues.costTypology.value == 0) {
                                            formValues.accomodationList = value.map((item) => {
                                                return item.value;
                                            });
                                        }
                                        
                                        if (formValues.costTypology.value == 2) {
                                            formValues.accomodationList = value.map((item) => {
                                                return item.value;
                                            });
                                        }
                                        setSelectedSecondaryMenu(value);
                                    }}
                                    value={selectedSecondaryMenu}
                                    options={
                                        accommodationsList &&
                                        accommodationsList.map((item) => {
                                            return { label: item.data.name, value: item.id };
                                        })
                                    }
                                />
                            )}
                        </div>
                        <div className="flex flex-wrap items-end justify-start">
                            <SubCategorySelect
                                className={"max-w-xs min-w-[250px]"}
                                type={costTypology}
                                onChange={(e) => {
                                    formValues.name = null;
                                    formValues.costCategory = e;
                                    setCostCategory(e);
                                }}
                                value={costCategory}
                            />
                            {costCategory && costCategory.value === "ota-commission" && (
                                <React.Fragment>
                                    <SelectMenu
                                        label={"Booking Provider *"}
                                        className={"max-w-xs mr-5 ml-10"}
                                        options={
                                            context.bookingProvidersList
                                                ? context.bookingProvidersList.map((item) => {
                                                    return {
                                                        label: item.data.name,
                                                        value: item.data.name,
                                                    };
                                                })
                                                : null
                                        }
                                        onChange={(el) => (formValues.name = el.value)}
                                    />
                                    <SecondaryButton
                                        content={<MdOutlinePostAdd className="w-6 h-auto" />}
                                        className={"w-fit p-2 mb-2 max-w-[30px]"}
                                        onClick={() => setOpenNewBookingProviderModal(true)}
                                    />
                                  </React.Fragment>
                            )}
                            {costCategory && costCategory.value !== "ota-commission" && (
                                <TextField
                                    label={"Sub-Category "}
                                    placeholder={"Insert the sub-category "}
                                    className={"max-w-xs min-w-[250px] ml-10"}
                                    inputClassName={"p-1"}
                                    onChange={(e) => (formValues.name = e.target.value)}
                                    defaultValue={formValues.name}
                                />
                            )}

                            <div className="flex items-end justify-start w-full mt-3">
                                <SelectMenu
                                    label={"Supplier "}
                                    className={"max-w-xs mr-5"}
                                    isClearable
                                    options={
                                        context.suppliesList
                                            ? context.suppliesList.map((item) => {
                                                return {
                                                    label:
                                                        item.data.name +
                                                        (item.data.category
                                                            ? " (" + item.data.category + ")"
                                                            : ""),
                                                    value: item.id,
                                                };
                                            })
                                            : null
                                    }
                                    onChange={(el) => (formValues.supplierId =el ? el.value : null)}
                                />
                                <SecondaryButton
                                    content={<MdOutlinePostAdd className="w-6 h-auto" />}
                                    className={"w-fit p-2 mb-2 max-w-[30px]"}
                                    onClick={() => setOpenNewSupplierModal(true)}
                                />
                            </div>
                            {costTypology && costTypology.value === 2 && (
                                <div className="flex items-end justify-start w-full mt-3">
                                    <SelectMenu
                                        label={"Trigger"}
                                        className={"max-w-xs mr-5"}
                                        options={costDateOption}
                                        onChange={(el) => {
                                            if (el.value)
                                                formValues.paymentDateType = el;
                                            else
                                                formValues.paymentDateType = null;
                                            setPaymentDateType(el.value);
                                        }}
                                    />
                                </div>
                            )}
                            {costTypology && costTypology.value !== 2 && (
                                <div className="flex items-end justify-start w-full mt-3">
                                    <SelectMenu
                                        label={"Recurrency *"}
                                        className={"max-w-xs mr-10"}
                                        options={recurrencyOption}
                                        onChange={(el) => {
                                            formValues.recurrency = el;
                                            if (el.value !== "custom")
                                                formValues.recurrencyCustom = null;
                                            setRecurrencyType(el.value);
                                        }}
                                    />
                                    {recurrencyType == "custom" && (
                                        <UnitField
                                            label={"Number of days *"}
                                            inputClassName={"w-[150px] p-1 mb-2 mr-10"}
                                            placeholder={0}
                                            value={formValues.recurrencyCustom}
                                            className={"w-fit"}
                                            onChange={(e) =>
                                                (formValues.recurrencyCustom = Number(e.target.value))
                                            }
                                        />
                                    )}
                                    <NewDatePicker
                                        className={"w-[200px] "}
                                        label={"Start date"}
                                        placeholder={"Start Date"}
                                        onCompleted={(el) => {
                                            setRecurrencyDate(el);
                                        }}
                                    />
                                </div>
                            )}
                        </div>

                        {costTypology && costTypology.value === 2 && (
                            <div className="flex flex-wrap mt-6">
                                <SelectMenu
                                    label={"Payment type *"}
                                    className={"max-w-xs mr-10"}
                                    isSearchable={false}
                                    options={paymentTypeOptions}
                                    placeholder={"Select type of payment"}
                                    onChange={(value) => {
                                        formValues.paymentType = value;
                                        setPaymentType(value.value);
                                    }}
                                />
                                {paymentType == "Percentage" ?
                                    <PercentField
                                        className={"max-w-xs mr-10"}
                                        label={"Percentage amount *"}
                                        inputClassName={"p-[5px] w-[150px]"}
                                        value={formValues.amount}
                                        onChange={(e) => {
                                            formValues.amount = Number(e.target.value);
                                        }}
                                    /> :
                                    <CurrencyField
                                        className={"max-w-xs mr-10"}
                                        label={"Payment amount *"}
                                        inputClassName={"p-1"}
                                        value={0}
                                        onChange={(value) =>
                                            (formValues.amount = Number(value.target.value))
                                        }
                                    />}
                                <NewDatePicker
                                    className={"w-[200px]"}
                                    label={"End date"}
                                    placeholder={"End Date"}
                                    onCompleted={(el) => {
                                        formValues.endDate = el ? el : null
                                    }}
                                />
                            </div>
                        )}
                        {costTypology && costTypology.value !== 2 && (
                            <div className="flex flex-wrap mt-6">
                                <CurrencyField
                                    className={"max-w-xs mr-10"}
                                    label={"Payment amount *"}
                                    inputClassName={"p-1"}
                                    value={0}
                                    onChange={(value) =>
                                        (formValues.amount = Number(value.target.value))
                                    }
                                />
                                <NewDatePicker
                                    className={"w-[200px]"}
                                    label={"End date"}
                                    placeholder={"End Date"}
                                    onCompleted={(el) => {
                                        formValues.endDate = el ? el : null
                                    }}
                                />
                            </div>
                        )}
                    </div>
                </div>
                <div className="flex justify-end w-full max-w-5xl">
                    <SecondaryButton
                        content={"Go back"}
                        className={"mt-10 p-3 max-w-[16rem] mr-10"}
                        onClick={() => navigator("../rules")}
                    />
                    <PrimaryButton
                        content={"Add Cost Rule"}
                        className={"mt-10 p-3 max-w-[16rem]"}
                        onClick={() => {
                            if (checkFields()) {
                                formValues.creationDate = new Date();
                                if (formValues.costTypology.value !== 2) {
                                    setExecutionDate();
                                }
                                createCostRule(formValues, context.organizationId).then(() => {
                                    context.updateData("cost-rules");
                                    navigator("../rules");
                                });
                            }
                        }}
                    />
                </div>
            </div>

            <Modal
                externalControl
                status={openNewSupplierModal}
                onClose={() => setOpenNewSupplierModal(false)}
                body={
                    <NewSupplier
                    onCloseModal={closeModal}
                        onComplete={(value) => {
                            setOpenNewSupplierModal(false);
                            setSupplier(value, context.organizationId);
                            context.updateData("suppliers");
                        }}
                    />
                }
                title={"New Supplier"}
            />

            <Modal
                externalControl
                status={openNewBookingProviderModal}
                onClose={() => setOpenNewBookingProviderModal(false)}
                body={
                    <NewBookingProvider
                        onComplete={(value) => {
                            setOpenNewBookingProviderModal(false);
                            setBookingProvider(value, context.organizationId);
                            context.updateData("booking-providers");
                        }}
                    />
                }
                title={"New Booking Provider"}
            />
        </>
    );
}; 