
import { UilBuilding, UilMoneyBill } from "@iconscout/react-unicons"; 
import { useContext, useEffect, useState } from "react";
import { DataContext } from "..";
import {PayPalButton} from "../../../components/buttons/paypal-button"
import { format, formatISO, formatDistance, differenceInDays } from "date-fns";
import { it } from 'date-fns/locale';

const SubscriptionSetup = () => {
  const context = useContext(DataContext);
  const diffTime = differenceInDays(context.organizationInfo.subscription.expired.toDate(), new Date());
  

  const namePlan = context.organizationInfo.subscription.plan === "host" ? "Host" : "Property Manager";

  return (
        <>
        <div className="flex flex-col content-between ml-10 w-[45rem] h-full mr-10">
      
      <div className="text-4xl font-bold ">Subscription</div>
      <div className="my-5 text-2xl font-normal">Plan</div>
      <hr className="w-full mb-6" />
      <div className="flex flex-col ">
        <div className="items-center">
          <div className="big-screen:hidden font-bold mb-5 ml-[10px]">
            Plan
          </div>
          <div className="flex flex-row mb-[4rem] justify-center big-screen:justify-start">
            <div className="flex flex-col break-words">
              <p className="w-[13rem] hidden big-screen:flex ">
              This is your subscription plan:
              </p>
            </div>

            
            <div className="flex flex-col content-between w-full h-full mx-10">
        <div className="flex items-start justify-center h-fit mb-28">
        <div className="max-w-md">          
            <div className=" justify-center">   
              <>
               <div
                className={"px-10 py-5 bg-white border-2 min-w-[450px] rounded-lg shadow-sm border-1"}
            >

                {/* Main Section */}
                <div className="flex flex-col space-y-3 mb-[30px]">
                    <h1 className="text-2xl  text-center font-bold">{namePlan}</h1>
                </div>
                {/* Info Section */}
                <>
                <div className="my-4 space-y-2 text-base">
                    <h3 className="flex items-center font-semibold">
                        <UilBuilding className="w-5 h-auto mr-2 text-petroil" />
                        Number of properties: <p className="ml-1 font-normal">{context.organizationInfo.subscription.properties}</p>
                    </h3>
                </div>
                <div className="my-4 space-y-2 text-base ">
             
                    <h3 className="flex items-center font-semibold">
                    <UilMoneyBill className={"text-petroil w-6 h-auto mr-2"} />
                        Amount: <p className="ml-1 font-normal">  €{context.organizationInfo.subscription.amount}</p>
                    </h3>
                </div>
                {context.organizationInfo.subscription.plan === "propertyManager" && (
                <div className="my-4 space-y-2 text-base ">
             
             <h3 className="flex items-center font-semibold">
             <UilMoneyBill className={"text-petroil w-6 h-auto mr-2"} />
             Price for property: <p className="ml-1 font-normal">  €{context.organizationInfo.subscription.amountExtra}</p>
             </h3>
         </div>
                )}
                </>
                <hr className="w-full mt-8" />
                {/* Button Section */}
                <div className="flex items-center justify-between mt-4">
                    <h2 className="flex items-center font-semibold">
                    Your subscription plan will expire on:  <span className="ml-2 text-petroil">{format((context.organizationInfo.subscription.expired.toDate()), "dd/MM/yyyy", { locale: it }  )}</span>
                    </h2>                    
                </div>      
            </div>
                  </>
                

            </div>
     {diffTime < 3 && (
      <PayPalButton 
      amount={context.organizationInfo.subscription.amount} 
      description={"Monthly payment Plan " + namePlan + " - id: " + context.organizationId} subscriptionInfo={context.organizationInfo.subscription} 
      organizationId={context.organizationId} 
      />
     )} 

        </div>
      </div>
        </div>
          </div>
          <div className="font-bold absolute bottom-0 right-10  flex items-center justify-end p-4 text-petroil rounded-full ">
  <p className="mr-1">Do you need support?</p>
  <a href="mailto:support@bilemon.com" className="text-petroil hover:brightness-125 rounded-full bg-transparent"
  >
    Contact us.
  </a>
</div>

          
        </div>
      </div>
    </div>
        
    
        
        </>
    )
}





export default SubscriptionSetup;