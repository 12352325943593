import { useState, useRef } from "react";
import Modal from "../dialog";
import { getAuth, updatePhoneNumber } from "firebase/auth";
import { PhoneAuthProvider } from "firebase/auth";
import { toast } from "react-toastify";

export const PhoneVerificationDialog = ({
  status,
  phoneNumber,
  onComplete,
  onClose,
}) => {
  const [loading, setLoading] = useState(false);
  // Test otp
  const testOtp = (code) => {
    const phoneCredentials = PhoneAuthProvider.credential(
      window.verificationId,
      code
    );
    // Obtain the verificationCode from the user.
    const phoneCredential = phoneCredentials;
    updatePhoneNumber(getAuth().currentUser, phoneCredential)
      .then(() => {
        toast.success("Registration completed succesfully!");
        onComplete(true);
      })
      .catch((err) => {
        switch (err.code) {
          case "auth/account-exists-with-different-credential":
            toast.error(
              "The phone number is already linked to another account!"
            );
            setLoading(false);
            break;
          case "auth/code-expired":
            toast.error("OTP code expired, request a new one!");
            setLoading(false);
            break;

          default:
            toast.error("OTP Missmatch!");
            setLoading(false);
            break;
        }
        onComplete(false);
      });
  };
  return (
    <Modal
      externalControl={true}
      status={status}
      title={"Confirm your phone number"}
      subtitle={"You will recieve an OTP code at: " + phoneNumber}
      onClose={() => {
        if (onClose) onClose();
      }}
      body={
        <div className="pb-10">
          <InputCode
            length={6}
            label={"Fill the 6 digit code bellow to confirm your phone number"}
            loading={loading}
            onComplete={(code) => {
              setLoading(true);
              testOtp(code, verificationId);
              setTimeout(() => setLoading(false), 5000);
            }}
          />
          <p className="my-1 text-right cursor-pointer hover:underline text-blue">
            Request a new code
          </p>
        </div>
      }
    />
  );
};

const InputCode = ({ length, label, loading, onComplete }) => {
  const [code, setCode] = useState([...Array(length)].map(() => ""));
  const inputs = useRef([]);
  // Typescript
  // useRef<(HTMLInputElement | null)[]>([])

  const processInput = (e, slot) => {
    const num = e.target.value;
    if (/[^0-9]/.test(num)) return;
    const newCode = [...code];
    newCode[slot] = num;
    setCode(newCode);
    if (slot !== length - 1) {
      inputs.current[slot + 1].focus();
    }
    if (newCode.every((num) => num !== "")) {
      onComplete(newCode.join(""));
    }
  };

  const onKeyUp = (e, slot) => {
    if (e.keyCode === 8 && !code[slot] && slot !== 0) {
      const newCode = [...code];
      newCode[slot - 1] = "";
      setCode(newCode);
      inputs.current[slot - 1].focus();
    }
  };

  return (
    <div className="code-input">
      <label className="font-semibold code-label text-petroil">{label}</label>
      <div className="flex code-inputs">
        {code.map((num, idx) => {
          return (
            <input
              id={String(idx) + "_inputCode"}
              key={idx}
              type="text"
              className="w-full h-12 mx-1 my-1 bg-gray-100 border-2 rounded-lg outline-none indent-4 focus:border-petroil"
              inputMode="numeric"
              maxLength={1}
              value={num}
              autoFocus={!code[0].length && idx === 0}
              readOnly={loading}
              onChange={(e) => processInput(e, idx)}
              onKeyUp={(e) => onKeyUp(e, idx)}
              ref={(ref) => inputs.current.push(ref)}
            />
          );
        })}
      </div>
    </div>
  );
};
