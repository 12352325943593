import React, { useContext, useState } from "react";
import SecondaryButton from "../../../../../components/buttons/secondary-button";
import TextField from "../../../../../components/input/fields/text-field";
import { SelectMenu } from "../../../../../components/input/select/select-menu";
import { getAccomodation, setOwner, updateSubscriptionData } from "../../../util/firestore-functions";
import { UnitField } from "../../../../../components/input/fields/unit-field";
import PrimaryButton from "../../../../../components/buttons/primary-button";
import TertiaryButton from "../../../../../components/buttons/tertiary-button";
import { CurrencyField } from "../../../../../components/input/fields/currency-field";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Modal from "../../../../../components/dialogs/dialog";
import { NewOwner, NewOwnerForm } from "./new-owner-form";
import { DataContext } from "../../..";
import { createAccomodation,  createOwner } from "../../../util/firestore-functions";

export const NewAccomodation = () => {
  const context = useContext(DataContext);
  const [modal, setOpenModal] = useState(false);
  const navigator = useNavigate();
  const ownersList = context.ownersList;
  const [oldSubscription, setOldSubscription] = useState(context.organizationInfo.subscription);
  const createdAccomodations = context.accomodationsList.length;
    
  const onCloseModal = () => {
    setOpenModal(false)
  };

  const [formValues, setFormValues] = useState({
    name: null,
    maxPeople: null,
    numberRooms: null,
    contractTypology: null,
    numberBathroom: null,
    numberDoubleBed: null,
    numberSingleBed: null,
    touristTaxRate: null,
    address: {
      city: null,
      street: null,
      state: null,
      cap: null,
    },
    owner: null,
    status: null,
  });

  const statusTypology = [
    {
      label: "Active",
      value: "Active"
    },
    {
      label: "Disabled",
      value: "Disabled"
    }
  ];

  const contractTypology = [
    {
      label: "Fixed Rent",
      value: "fixed-rent",
    },
    {
      label: "Revenue Share",
      value: "revenue-share",
    },
    {
      label: "Commission-Based",
      value: "commission-based"
    },
    {
      label: "Guaranteed Income",
      value: "guaranteed-income"
    },
    {
      label: "Only Agency",
      value: "only-agency"
    },
    {
      label: "Fee-Based",
      value: "fee-based"
    },
    {
      label: "Hybrid Models",
      value: "hybrid-models"
    },
    {
      label: "Tiered Revenue Share",
      value: "tiered-revenue-share"
    },
    {
      label: "Performance-Based Bonus",
      value: "performance-based-bonus"
    },
    {
      label: "Booking Fee Model",
      value: "booking-fee-model"
    },
    {
      label: "Full Service with Variable Fee",
      value: "full-service-with-variable-fee"
    },
    {
      label: "Retainer Plus Commission",
      value: "retainer-plus-commission"
    },
    {
      label: "Equity Share Model",
      value: "equity-share-model"
    },
    {
      label: "Zero-Fee with Upsells",
      value: "zero-fee-with-upsells"
    },
  ];

  

  const checkFields = () => {

    if (!formValues.name || formValues.name === "") {
      toast.error("An accommodation needs a name");
      return;
    }
    if (!formValues.status || formValues.status === "") {
      //If is not selected, change it in Active automatically
      formValues.status = {label: "Active", value: "Active"};
    }

    if (!formValues.address.city || formValues.address.city === "") {
      toast.error("An accommodation needs a city");
      return;
    }

    if (!formValues.owner || formValues.owner == "") {
      toast.error("An accommodation needs an owner");
      return;
    }
    
    createAccomodation(formValues, context.organizationId).then(() => {
      
      // Add every time
      updateSubscriptionData({
        subscription: {
          amount: parseFloat(
            (
              oldSubscription.plan === "host"
                ? oldSubscription.amount
                : oldSubscription.properties >= 5
                ? oldSubscription.amount + oldSubscription.amountExtra
                : oldSubscription.amount
            ).toFixed(2)
          ),
          expired: oldSubscription.expired,
          plan: oldSubscription.plan,
          properties: oldSubscription.properties + 1,
          recurrency: oldSubscription.recurrency,
          amountExtra: oldSubscription.amountExtra,
          propertiesExtra: 0
        }
      }, context.organizationId).then(() => {
        context.updateData("organization-info");
      });
      context.updateData("accomodations");
      
      navigator("../accommodations");
    }).catch((error) => {
      toast.error("An error occurred: " + error);
    });
  };

  return (
    <>
      <div className="w-fu">
        <div className="mb-10">
          <h1 className="text-3xl">New Accommodation</h1>
        </div>

        <div className="w-full max-w-5xl p-10 bg-white border-2 rounded-lg shadow-md">
          <div className="flex justify-between">
          <h2 className="text-2xl">Accommodation details</h2>
          <SelectMenu
              className={"max-w-xs min-w-[250px] my-0 p-1 w-fit mb-3 mr-4 flex flex-col items-start justify-start"}
              isSearchable={false}
              options={statusTypology}
              placeholder={"Select the status..."}
              onChange={(value) => {
                formValues.status = value;
              }}
            />
</div>
          <hr className="my-4 " />
          <div className="flex flex-wrap justify-start mb-5 ml-auto mr-auto h-fit">

              <TextField
                label={"Name *"}
                inputClassName={"p-2 max-w-xs min-w-[350px]"}
                placeholder={"Accommodation name"}
                className={"w-fit mt-3 mr-4 flex flex-col"}
                onChange={(e) => {
                  formValues.name = e.target.value;
                }}
              />

                 <SelectMenu
                               label={"Contract Typology"}
                               className={"max-w-xs min-w-[250px] py-0 my-0 w-fit mt-3 mr-4 flex flex-col"}
                               isSearchable={false}
                               options={contractTypology}
                               placeholder={"Select the Typology of contract"}
                               onChange={(value) => {
                  formValues.contractTypology = value;
                               }}
                             />

            </div>
            <hr className="my-4 " />
            <div className="flex flex-wrap mb-5 ml-auto mr-auto h-fit w-fit"> 
    <UnitField
      label={"Number of rooms"}
      className={"w-1/4 mt-3 mr-4"}
      inputClassName={"w-fit p-2 max-w-[200px]"}
      placeholder={"0"}
      onChange={(e) => {
        formValues.numberRooms = Number(e.target.value);
      }}
    />
    <UnitField
      label={"Max number of people"}
      className={"w-1/4 mt-3 mr-4"}
      inputClassName={"w-fit p-2 max-w-[200px]"}
      placeholder={"0"}
      onChange={(e) => {
        formValues.maxPeople = Number(e.target.value);
      }}
    />
    <UnitField
      label={"Number of double bed"}
      className={"w-1/4 mt-3 mr-4"}
      placeholder={"0"}
      inputClassName={"w-fit p-2 max-w-[200px]"}
      onChange={(e) => {
        formValues.numberDoubleBed = Number(e.target.value);
      }}
    />
    <UnitField
      label={"Number of single bed"}
      className={"w-1/4 mt-3 mr-4"}
      placeholder={"0"}
      inputClassName={"w-fit p-2 max-w-[200px]"}
      onChange={(e) => {
        formValues.numberSingleBed = Number(e.target.value);
      }}
    />
    <UnitField
      label={"Number of bathroom"}
      className={"w-1/4 mt-3 mr-4"}
      placeholder={"0"}
      inputClassName={"w-fit p-2 max-w-[200px]"}
      onChange={(e) => {
        formValues.numberBathroom = Number(e.target.value);
      }}
    />
    <CurrencyField
      className={"w-1/2 mt-2 mr-2 max-w-[380px]"}
      placeholder={"0.00"}
      label={"Tourist tax rate"}
      inputClassName={"w-fit p-2 min-w-[150px]"}
      onChange={(e) =>
        formValues.touristTaxRate = Number(e.target.value)
      }
    />
</div>

          <h3 className="text-xl">Address info</h3>
          <hr className="my-4 " />
          <div className="flex flex-wrap justify-between mb-4 ml-auto mr-auto h-fit">
            <div>
              <TextField
                label={"Street"}
                className={"mb-2 "}
                inputClassName={"p-2 max-w-xs"}
                placeholder={"Insert.."}
                onChange={(e) => {
                  formValues.address.street = e.target.value;
                }}
              />
            </div>
            <div>
              <TextField
                label={"City *"}
                inputClassName={"p-2"}
                className={"max-w-xs mr-10"}
                placeholder={"Insert.."}
                onChange={(e) => {
                  formValues.address.city = e.target.value;
                }}
              />
            </div>
            <div>
              <TextField
                label={"State/Province"}
                inputClassName={"p-2"}
                className={"w-[200px] mr-10"}
                placeholder={"Insert.."}
                onChange={(e) => {
                  formValues.address.state = e.target.value;
                }}
              />
            </div>
            <div>
              <TextField
                label={"Zip/Postal Code"}
                inputClassName={"p-2 max-w-[150px]"}
                className={"w-fit mr-10"}
                placeholder={"Insert.."}
                onChange={(e) => {
                  formValues.address.cap = e.target.value;
                }}
              />
            </div>
          </div>
          <h3 className="text-xl">Ownership</h3>
          <hr className="my-4 " />
          <div className="flex items-end justify-start">
            <SelectMenu
              placeholder={"Select.."}
              className={"max-w-xs mr-10"}
              label={"Owner *"}
              options={
                ownersList &&
                ownersList.map((item) => {
                  return {
                    label: item.data.lastName + " " + item.data.firstName,
                    value: item.id,
                  };
                })
              }
              onChange={(value) => {
                value ? (formValues.owner = value.value) : undefined;
              }}
            />
            <SecondaryButton
              content={"Add new owner"}
              className={"w-fit p-2 mb-2 max-w-[80px]"}
              onClick={() => setOpenModal(true)}
            />
          </div>
        </div>
        <div className="flex justify-end w-full max-w-5xl">
          <SecondaryButton
            content={"Go back"}
            className={"mt-10 p-3 max-w-[8rem] mr-10"}
            onClick={() => navigator("../accommodations")}
          />

          {((oldSubscription.plan === "host" && createdAccomodations < 4) || oldSubscription.plan === "propertyManager") && (
                   <PrimaryButton
                   content={"Add Accommodation"}
                   className={"mt-10 p-3 max-w-[16rem]"}
                   onClick={checkFields}
                 />
          )
          }

        </div>
      </div>

      <Modal
        externalControl
        status={modal}
        body={          
          <NewOwnerForm
            onCloseModal={onCloseModal}
            onComplete={(owner) => {
              createOwner(owner, context.organizationId)
                .then(() => {
                  context.updateData("owners");
                  setOpenModal(false);
                })
                .catch((err) => {
                  toast.error("An error occurred: " + err);
                }
                );
            }}
          />
        }
        title={"New Owner"}
        className={""}
        onClose={() => setOpenModal(false)}
      />
    </>
  );
};
