import { sendEmailVerification, sendPasswordResetEmail } from "firebase/auth";
import { useState } from "react";
import { toast } from "react-toastify";
import { auth } from "../../../firebase/config";
import PrimaryButton from "../../buttons/primary-button";
import Modal from "../dialog";

export const PasswordResetDialog = () => {
  const [email, setEmail] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const passwordResetHandler = async () => {
    if (email == "") {
      toast.error("Empty email field");
      return;
    }
    await sendPasswordResetEmail(auth, email)
      .then(() => {
        toast.success("Reset email sent!");
        setIsOpen(false);
      })
      .catch((error) => {
        toast.error("An error occurred, check your email and try again:" + error);
      });
  };
  return (
    <Modal
      status={isOpen}
      primaryButton
      buttonLabel={"Did you forget the password?"}
      onClose={() => setIsOpen(false)}
      buttonClassName={"w-32 text-black bg-transparent text-xs hover:underline"}
      body={
        <div>
          <div>
            <h6>
              Fill in with your email to retrieve your account.
              <br /> If your email exists in our database you will receive an
              email to reset your password.
            </h6>
          </div>
          <div className="my-4">
            <label className="text-lg font-semibold text-petrol">Email</label>
            <input
              className="w-full h-10 my-1 bg-gray-100 border-2 rounded-lg outline-none indent-4 focus:border-petrol"
              placeholder="Insert your email address"
              type="text"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="flex justify-end mt-5">
            <PrimaryButton
              className={"p-2 w-fit max-w-[120px]"}
              onClick={passwordResetHandler}
              content={"Retrieve your account"}
            />
          </div>
        </div>
      }
      title={"Reset your password"}
      subtitle={"Write down you email to reset your email"}
    />
  );
};
