import {
  createUserWithEmailAndPassword,
  getAuth,
  PhoneAuthProvider,
  RecaptchaVerifier,
  updateProfile,
} from "firebase/auth";
import { sendVerificationEmail } from "../../dashboard/util/firestore-functions";
import React, { createContext, useContext, useEffect, useState } from "react";
import {
  formatPhoneNumberIntl,
  getCountryCallingCode,
} from "react-phone-number-input";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { PhoneVerificationDialog } from "../../../components/dialogs/presets/phone-verificationDialog";
import { FirstStep } from "./steps/first-step";
import { SecondStep } from "./steps/second-step";
export const SignUp = ({ step }) => {
  const auth = getAuth();
  const [currentStep, setCurrentStep] = useState(step ? step : 0);
  const [openPhoneVerifyModal, setOpenPhoneVerifyModal] = useState(false);
  const navigator = useNavigate();
  const [modalVisible, setModalVisible] = useState(false);
  const [formsValues, setFormsVlues] = useState({
    email: null,
    password: null,
    confirmPassword: null,
    selectedPrefix: null,
    fullName: null,
    phoneNumber: null,
    newsLetter: null,
    formattedPhoneNumber: null,
  });

  const setupRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        callback: (response) => {
        // TODO: manage
        },
      },
      auth
    );
  };

  const userRegistration = () => {
    var error = false;
    createUserWithEmailAndPassword(
      auth,
      formsValues.email,
      formsValues.password
    )
      .then(() => {
        /* Update displayName */
        updateProfile(auth.currentUser, { displayName: formsValues.fullName })
          .then(() =>
          {
            // TODO: manage DisplayName Updated!
          })
          .catch((error) => 
          {
            toast.error("An error occurred: " + error);
          });



        /* Verifing phone number */
        setupRecaptcha();
        let appVerifier = window.recaptchaVerifier;
        const provider = new PhoneAuthProvider(auth);
        provider
          .verifyPhoneNumber(formsValues.formattedPhoneNumber, appVerifier)
          .then((verificationId) => (window.verificationId = verificationId))
          .then(() => {
            
            setModalVisible(true);
            setOpenPhoneVerifyModal(true);
          });

          sendVerificationEmail();
          

      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;

        switch (errorCode) {
          case "auth/weak-password":
            toast.error("Password should be at least 6 characters.");
            break;
          case "auth/email-already-in-use":
            toast.error("Email already registred.");
            break;
          default:
            toast.error(
              "Something went wrong " + errorMessage
            );
            break;
        }
      });
  };

  return (
    <div className="flex justify-center w-full my-10 text-2xl">
      <div className="p-10">
        {/* Image Section */}
        <div className="flex flex-col items-center justify-center w-full text-petroil">
          <img
            className="w-full h-auto max-w-md "
            alt="Bilemon logo"
            src="/Logo-Petroil.png"
          />
          <p className="mt-4 text-base text-center">
            Already have an account?{" "}
            <Link className="font-bold hover:underline " to="/login">
              Login
            </Link>
          </p>
        </div>
        <p className="mt-4 text-sm font-bold text-center text-petroil">
          {currentStep + 1} of 2 steps completed
        </p>
        {/* Form Section */}
        {currentStep == 0 && (
          <FirstStep
            formsValue={formsValues}
            onComplete={(values) => {
              formsValues.fullName = values.fullName;
              formsValues.phoneNumber = values.phoneNumber;
              formsValues.selectedPrefix = values.selectedPrefix;
              formsValues.formattedPhoneNumber = values.formattedPhoneNumber;
              setCurrentStep(1);
            }}
          />
        )}
        {currentStep == 1 && (
          <SecondStep
            onGoBack={(values) => {
              formsValues.password = values.password;
              formsValues.confirmPassword = values.confirmPassword;
              formsValues.email = values.email;
              setCurrentStep(0);
            }}
            formsValues={formsValues}
            onComplete={(values) => {
              formsValues.password = values.password;
              formsValues.confirmPassword = values.confirmPassword;
              formsValues.email = values.email;
              userRegistration();
            }}
            buttonId={"sign-in-button"}
          />
        )}
      </div>
      
        <PhoneVerificationDialog
        status={openPhoneVerifyModal}
        onClose={() => setOpenPhoneVerifyModal(false)}
        phoneNumber={formatPhoneNumberIntl(formsValues.phoneNumber)}
        onComplete={(value) => {
          if (value) {
            setOpenPhoneVerifyModal(false);
            navigator("../organization-setup");
          }
        }}
      />
      

      
      
      <div id="recaptcha-container"></div>
    </div>
  );
};
