import { Tab } from "@headlessui/react";

import React, { useContext, useEffect, useState } from "react";
import { IoTrashBin } from "react-icons/io5";
import SecondaryButton from "../../../../../components/buttons/secondary-button";
import TertiaryButton from "../../../../../components/buttons/tertiary-button";
import { NewDatePicker } from "../../../../../components/input/fields/date-pickers/newDatePicker";
import { DatePicker } from "../../../../../components/input/fields/date-pickers/single";
import TextField from "../../../../../components/input/fields/text-field";
import { UnitField } from "../../../../../components/input/fields/unit-field";
import { format } from "date-fns";
import { SelectMenu } from "../../../../../components/input/select/select-menu";
import { DataContext } from "../../..";
import { CurrencyField } from "../../../../../components/input/fields/currency-field";
import { FaAirbnb } from "react-icons/fa";
import { TbBrandAirbnb, TbBrandBooking } from "react-icons/tb";
import { PercentField } from "../../../../../components/input/fields/percent-field";
import { createCost, updateBooking, updateCost, deleteCost } from "../../../util/firestore-functions";
import { comment } from "postcss";
import { toast } from "react-toastify";
const BookingManager = ({ bookingInfo, onComplete, onClose }) => {
  const context = useContext(DataContext);
  const [index, setIndex] = useState(0);
  const [selectedCommission, setSelectedCommission] = useState(
    bookingInfo.commissionChannel ? bookingInfo.commissionChannel : false
  );
  const [selectedCommissionType, setSelectedCommissionType] = useState(() => {
    if (bookingInfo.commissionType)
      if (bookingInfo.commissionType.label === "Fixed") {
        return "Fixed";
      } else {
        return "Percentage";
      }
  });

  /* Forms Values */
  const [bookingDate, setBookingDate] = useState(bookingInfo.bookingDate);
  const [checkInDate, setCheckInDate] = useState(bookingInfo.checkInDate);
  const [checkOutDate, setCheckOutDate] = useState(bookingInfo.checkOutDate);
  const [numberOfGuest, setNumberOfGuest] = useState(bookingInfo.numberOfGuest);
  const [amount, setAmount] = useState(bookingInfo.amount);
  const [accomodationReference, setAccomodationReference] = useState(bookingInfo.accomodationReference);
  const [commissionChannel, setCommissionChannel] = useState(bookingInfo.commissionChannel);
  const [commissionAmount, setCommissionAmount] = useState(bookingInfo.commissionAmount);
  const [commissionType, setCommissionType] = useState(bookingInfo.commissionType);


  const classNames = (...classes) => {
    return classes.filter(Boolean).join(" ");
  };
  var accomodationOptions = context.loading
    ? null
    : context.accomodationsList &&
    context.accomodationsList.map((item) => {
      return { label: item.data.name, value: item.id };
    });
  const commissionChannelOption = new Array();
  const bookingChannel = new Object();
  bookingChannel.label = <div className="flex items-center justify-start space-x-5 w-fit"><p className="text-base"></p></div>;
  bookingChannel.value = null;
  bookingChannel.amount = null;
  bookingChannel.type = null;
  commissionChannelOption.push(bookingChannel);

  context.loading ? null : context.costsRuleList && context.costsRuleList.map((rule) => {
    const bookingChannel = new Object();
    if (rule.data.costTypology.value === 2 && rule.data.costCategory.value === "ota-commission" && (!rule.data.accomodationList || (rule.data.accomodationList && rule.data.accomodationList.indexOf(accomodationReference ? accomodationReference : bookingInfo.accomodationReference) > -1)) && (!rule.data.endDate || (rule.data.endDate && (rule.data.paymentDateType ? (rule.data.paymentDateType.value == "check-in" ? bookingInfo.checkInDate : (rule.data.paymentDateType.value == "check-out" ? bookingInfo.checkOutDate : bookingInfo.bookingDate)) : bookingInfo.bookingDate) < rule.data.endDate.toDate()))) {
      bookingChannel.label = <div className="flex items-center justify-start space-x-5 w-fit"><p className="text-base">{rule.data.name}</p></div>;
      bookingChannel.value = rule.data.name;
      bookingChannel.amount = rule.data.amount;
      bookingChannel.type = rule?.data?.paymentType?.value ?? '';
      commissionChannelOption.push(bookingChannel);
    }
  });
  const typeOfCommissionOption = [
    {
      label: "Fixed",
      value: "Fixed",
    },
    {
      label: "Percentage",
      value: "Percentage",
    },
  ];
  return (
    <div className="w-full">
      <Tab.Group selectedIndex={index} onChange={(value) => setIndex(value)}>
        <Tab.List className="flex p-1 space-x-1 rounded-xl bg-blue-900/20 bg-neutral-50 text-blue">
          {/** Headers */}
          <Tab
            className={({ selected }) =>
              classNames(
                "w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-blue-700",
                "ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2",
                selected
                  ? "shadow bg-green font-extrabold"
                  : "text-blue-100 hover:bg-white/[0.12] hover:text-petroil"
              )
            }
          >
            Details
          </Tab>
          <Tab
            className={({ selected }) =>
              classNames(
                "w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-blue-700",
                "ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2",
                selected
                  ? "shadow bg-green font-extrabold"
                  : "text-blue-100 hover:bg-white/[0.12] hover:text-petroil"
              )
            }
          >
            Dates
          </Tab>
          {/* <Tab
            className={({ selected }) =>
              classNames(
                "w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-blue-700",
                "ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2",
                selected
                  ? "shadow bg-green font-extrabold"
                  : "text-blue-100 hover:bg-white/[0.12] hover:text-petroil"
              )
            }
          >
            Commission
          </Tab> */}
        </Tab.List>
        {/** Tabs */}
        <Tab.Panels className="my-1 min-h-[25rem]">
          {/** Details */}
          <Tab.Panel
            key={0}
            className={classNames(
              "rounded-xl bg-white p-3",
              "ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2 space-y-6"
            )}
          >
            <SelectMenu
              label={"Accommodation"}
              value={accomodationOptions.find((value) =>
                accomodationReference
                  ? accomodationReference === value.value
                  : bookingInfo.accomodationReference.value === value.value
              )}
              options={accomodationOptions}
              onChange={(value) => {
                commissionChannelOption.splice(0, commissionChannelOption.length);
                context.costsRuleList && context.costsRuleList.map((rule) => {
                  const bookingChannel = new Object();
                  bookingChannel.label = <div className="flex items-center justify-start space-x-5 w-fit"><p className="text-base"></p></div>;
                  bookingChannel.value = null;
                  bookingChannel.amount = null;
                  bookingChannel.type = null;
                  commissionChannelOption.push(bookingChannel);
                  if (rule.data.costTypology.value === 2 && rule.data.costCategory.value === "ota-commission" && (!rule.data.accomodationList || (rule.data.accomodationList && rule.data.accomodationList.indexOf(value.value) > -1)) && (!rule.data.endDate || (rule.data.endDate && (rule.data.paymentDateType ? (rule.data.paymentDateType.value == "check-in" ? bookingInfo.checkInDate : (rule.data.paymentDateType.value == "check-out" ? bookingInfo.checkOutDate : bookingInfo.bookingDate)) : bookingInfo.bookingDate) < rule.data.endDate.toDate()))) {
                    bookingChannel.label = <div className="flex items-center justify-start space-x-5 w-fit"><p className="text-base">{rule.data.name}</p></div>;
                    bookingChannel.value = rule.data.name;
                    bookingChannel.amount = rule.data.amount;
                    bookingChannel.type = rule.data.paymentType.value;
                    commissionChannelOption.push(bookingChannel);
                  }
                });
                setAccomodationReference(value.value)
              }}
            />
            <CurrencyField
              label={"Amount"}
              placeholder={amount}
              value={amount}
              onChange={(el) => setAmount(Number(el.target.value))}
              inputClassName={"p-1 max-w-[100px]"}
            />
            <UnitField
              label={"Number of Guests"}
              placeholder={numberOfGuest}
              value={numberOfGuest}
              onChange={(el) => setNumberOfGuest(Number(el.target.value))}
              inputClassName={"m-1 p-1 max-w-[100px]"}
            />
          </Tab.Panel>
          {/** Dates */}
          <Tab.Panel
            key={1}
            className={classNames(
              "rounded-xl bg-white p-3",
              "ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2 space-y-6"
            )}
          >
            <NewDatePicker
              label={"Booking date"}
              defaultValue={bookingDate}
              onCompleted={(el) => setBookingDate(el)}
            />
            <NewDatePicker
              label={"CheckIn date"}
              defaultValue={checkInDate}
              onCompleted={(el) => setCheckInDate(el)}
            />
            <NewDatePicker
              label={"CheckOut date"}
              defaultValue={checkOutDate}
              onCompleted={(el) => setCheckOutDate(el)}
            />
          </Tab.Panel>
          {/** Commissions */}
          <Tab.Panel
            key={2}
            className={classNames(
              "rounded-xl bg-white p-3",
              "ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2 space-y-6"
            )}
          >
            <SelectMenu
              label={"Commission channel"}
              isClearable
              options={commissionChannelOption}
              value={commissionChannelOption.find((item) => {
                return item.value == selectedCommission;
              })}
              onChange={(el) => {
                if (!el || !el.value) {
                  setCommissionChannel(null);
                  setSelectedCommission(false);
                  setCommissionType(null);
                  setCommissionAmount(0);
                } else {
                  let commissionAmount = 0;
                  if (el.type === "Percentage") {
                    setCommissionType({ label: el.type, value: el.amount })
                    commissionAmount = parseFloat(((amount * el.amount) / 100).toFixed(2));
                  }
                  if (el.type === "Fixed") {
                    commissionAmount = el.amount;
                    setCommissionType(null);
                  }
                  setSelectedCommissionType(el.type);
                  setCommissionAmount(commissionAmount);
                  setCommissionChannel(el.value);
                  setSelectedCommission(el.value);
                }
              }}
            />
            {selectedCommission && (
              <>
                <SelectMenu
                  label={"Commission type"}
                  options={typeOfCommissionOption}
                  value={typeOfCommissionOption.find(
                    (item) => item.value === selectedCommissionType
                  )}
                  onChange={(el) => {
                    setCommissionAmount(0);
                    setSelectedCommissionType(el ? el.value : false);
                  }}
                />
                {selectedCommissionType === "Percentage" && (
                  <PercentField
                    label={"Percentage"}
                    inputClassName={"p-1"}
                    placeholder={commissionType ? commissionType.value : "Insert.."}
                    value={commissionType ? commissionType.value : 0}
                    onChange={(e) => {
                      let commission = parseFloat((amount * Number(e.target.value) / 100).toFixed(2));
                      setCommissionAmount(commission);
                      setCommissionType({ label: "Percentage", value: e.target.value });
                    }}
                  />
                )}
                {selectedCommissionType === "Fixed" && (
                  <CurrencyField
                    label={"Amount"}
                    placeholder={commissionAmount ? commissionAmount : "Insert.."}
                    value={commissionAmount ? commissionAmount : 0}
                    inputClassName={"p-1"}
                    onChange={(e) => {
                      setCommissionType(null);
                      setCommissionAmount(Number(e.target.value));
                    }
                    }
                  />
                )}
              </>
            )}
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
      <div className="flex justify-end">
        <TertiaryButton
          onClick={onClose}
          className={"bg-red p-2 w-fit mr-5"}
          content={"Close"}
        />
        <SecondaryButton
          className={"p-2 h-auto w-fit"}
          content={"Update Booking"}
          onClick={() => {
            const formsValues = {
              accomodationReference: accomodationReference ? accomodationReference : bookingInfo.accomodationReference,
              amount: amount !== null ? amount : bookingInfo.amount,
              bookingDate: bookingDate ? bookingDate : bookingInfo.bookingDate,
              checkInDate: checkInDate ? checkInDate : bookingInfo.checkInDate,
              checkOutDate: checkOutDate ? checkOutDate : bookingInfo.checkOutDate,
              commissionChannel: commissionChannel,
              commissionAmount: commissionAmount !== null ? commissionAmount : bookingInfo.commissionAmount,
              numberOfGuest: numberOfGuest !== null ? numberOfGuest : bookingInfo.numberOfGuest,
              commissionType: commissionType
            };

            if (formsValues.bookingDate > formsValues.checkInDate) {
              toast.error("The booking date must be shorter than Check-in date");
              return;
            }

            if (formsValues.checkInDate > formsValues.checkOutDate) {
              toast.error("The Check-in date must be shorter than Check-out date");
              return;
            }
            if (
              !(
                formsValues.accomodationReference === bookingInfo.accomodationReference &&
                formsValues.amount === bookingInfo.amount &&
                formsValues.numberOfGuest === bookingInfo.numberOfGuest &&
                formsValues.bookingDate === bookingInfo.bookingDate &&
                formsValues.checkInDate === bookingInfo.checkInDate &&
                formsValues.checkOutDate === bookingInfo.checkOutDate &&
                formsValues.commissionChannel === bookingInfo.commissionChannel &&
                formsValues.commissionAmount === bookingInfo.commissionAmount &&
                formsValues.commissionChannel === bookingInfo.commissionChannel
              )
            ) {
              if (selectedCommissionType === "Percentage") {
                formsValues.commissionAmount = parseFloat(((formsValues.amount * commissionType.value) / 100).toFixed(2));
              }
              updateBooking(formsValues, bookingInfo.id, context.organizationId)
                .then(async () => {
                  onComplete();
                  context.updateData("bookings");

                  if (formsValues.commissionChannel) {
                    // Update ota commission cost
                    let otacommissioncosts = context.costsList.filter(x => (x.data.costCategory && x.data.costCategory.value === "ota-commission") && (x.data.costTypology && x.data.costTypology.value === 2) && x.data.bookingRef === bookingInfo.id);
                    if (otacommissioncosts.length > 0) {

                      let costDate = otacommissioncosts[0].data.costDate.toDate();
                      if (bookingInfo.bookingDate !== bookingDate && bookingInfo.bookingDate.toDateString() === costDate.toDateString()) {
                        costDate = bookingDate;
                      } else if (bookingInfo.checkInDate !== checkInDate && bookingInfo.checkInDate.toDateString()  === costDate.toDateString()) {
                        costDate = checkInDate;
                      } else if (bookingInfo.checkOutDate !== checkOutDate && bookingInfo.checkOutDate.toDateString()  === costDate.toDateString()) {
                        costDate = checkOutDate;
                      }

                      await updateCost(
                        { amount: formsValues.commissionAmount, costDate: costDate, paymentDate: costDate
                         },
                        otacommissioncosts[0].id,
                        context.organizationId
                      );
                    }
                    else {
                      const costRulesCommission = context.costsRuleList ? context.costsRuleList.filter(x => (x.data.costTypology && x.data.costTypology.value === 2) && (x.data.costCategory && x.data.costCategory.value == "ota-commission") && (x.data.name && x.data.name === commissionChannel) && (!x.data.accomodationList || (x.data.accomodationList && x.data.accomodationList.indexOf(formsValues.accomodationReference) > -1)) && (!x.data.endDate || (x.data.endDate && (x.data.paymentDateType ? (x.data.paymentDateType.value == "check-in" ? bookingInfo.checkInDate : (x.data.paymentDateType.value == "check-out" ? bookingInfo.checkOutDate : bookingInfo.bookingDate)) : bookingInfo.bookingDate) < x.data.endDate.toDate()))) : [];
                      
                      for (let i = 0; i < costRulesCommission.length; i++) {
                        let paymentDate = bookingInfo.bookingDate;
                        if (costRulesCommission[i].data.paymentDateType) {
                          if (costRulesCommission[i].data.paymentDateType.value == "check-in") {
                            paymentDate = bookingInfo.checkInDate;
                          }
                          else if (costRulesCommission[i].data.paymentDateType.value == "check-out") {
                            paymentDate = bookingInfo.checkOutDate;
                          }
                        }

                        await createCost(
                          {
                            name: costRulesCommission[i].data.name,
                            costDate: paymentDate,
                            paymentDate: paymentDate,
                            amount: formsValues.commissionAmount,
                            costTypology: { label: "Booking", value: 2 },
                            costCategory: costRulesCommission[i].data.costCategory,
                            accomodationList: [formsValues.accomodationReference],
                            bookingRef: bookingInfo.id,
                            costRuleRef: costRulesCommission[i].id,
                            supplierId: costRulesCommission[i].data.supplierId ? costRulesCommission[i].data.supplierId : null,
                          },
                          context.organizationId
                        );
                      }
                    }
                  }
                  else {
                    // Delete ota commission cost if commission channel is null
                    let otacommissioncosts = context.costsList.filter(x => (x.data.costCategory && x.data.costCategory.value === "ota-commission") && (x.data.costTypology && x.data.costTypology.value === 2) && x.data.bookingRef === bookingInfo.id);
                    if (otacommissioncosts.length > 0) {
                      for (let i = 0; i < otacommissioncosts.length; i++) {
                        try {
                          await deleteCost(otacommissioncosts[i].id, context.organizationId);
                        }
                        catch (ex) {
                          toast.error("Error while updating booking." + ex);
                        }
                      }
                    }
                  }

                  //Update other costs
                  let accomodationcosts = context.costsList.filter(x => (x.data.costCategory && x.data.costCategory.value !== "ota-commission") && (x.data.costTypology && x.data.costTypology.value === 2) && x.data.bookingRef === bookingInfo.id);
                  
                  //(x.data.paymentType && x.data.paymentType.value === "Percentage") &&
                  const costRulesAccomodation = context.costsRuleList ? context.costsRuleList.filter(x => (x.data.costTypology && x.data.costTypology.value === 2) && (x.data.costCategory && x.data.costCategory.value != "ota-commission") && (!x.data.accomodationList || (x.data.accomodationList && x.data.accomodationList.indexOf(formsValues.accomodationReference) > -1)) && (!x.data.endDate || (x.data.endDate && (x.data.paymentDateType ? (x.data.paymentDateType.value == "check-in" ? bookingInfo.checkInDate : (x.data.paymentDateType.value == "check-out" ? bookingInfo.checkOutDate : bookingInfo.bookingDate)) : bookingInfo.bookingDate) < x.data.endDate.toDate()))) : [];

                  for (let i = 0; i < costRulesAccomodation.length; i++) {
                    try {
                      let costCreated = accomodationcosts.find(x => x.data.costRuleRef === costRulesAccomodation[i].id);

                      if (costCreated) {
                        let costDate = costCreated.data.costDate.toDate();
                        if (bookingInfo.bookingDate !== bookingDate && bookingInfo.bookingDate.toDateString() === costDate.toDateString()) {
                          costDate = bookingDate;
                        } else if (bookingInfo.checkInDate !== checkInDate && bookingInfo.checkInDate.toDateString()  === costDate.toDateString()) {
                          costDate = checkInDate;
                        } else if (bookingInfo.checkOutDate !== checkOutDate && bookingInfo.checkOutDate.toDateString()  === costDate.toDateString()) {
                          costDate = checkOutDate;
                        }
                        //controlliamo amount se percentage o meno
                        let amountCost = costCreated.data.amount; 
                      if (costRulesAccomodation[i].data.paymentType && costRulesAccomodation[i].data.paymentType.value === "Percentage"){
                        amountCost = parseFloat(((formsValues.amount * costRulesAccomodation[i].data.amount) / 100).toFixed(2));
                      }

                      if (costCreated.data.amount != amountCost || costCreated.data.costDate.toDate() != costDate){

                        updateCost(
                          {
                            amount: amountCost,
                            costDate: costDate,
                            paymentDate: costDate
                          },
                          costCreated.id,
                          context.organizationId
                        );
                      }

                      }

                      else {
                        let paymentDate = bookingInfo.bookingDate;
                        if (costRulesAccomodation[i].data.paymentDateType) {
                          if (costRulesAccomodation[i].data.paymentDateType.value == "check-in") {
                            paymentDate = bookingInfo.checkInDate;
                          }
                          else if (costRulesAccomodation[i].data.paymentDateType.value == "check-out") {
                            paymentDate = bookingInfo.checkOutDate;
                          }
                        }
                        await createCost(
                          {
                            name: costRulesAccomodation[i].data.name,
                            costDate: paymentDate,
                            paymentDate: paymentDate,
                            amount: parseFloat(((formsValues.amount * costRulesAccomodation[i].data.amount) / 100).toFixed(2)),
                            costTypology: { label: "Booking", value: 2 },
                            costCategory: costRulesAccomodation[i].data.costCategory,
                            accomodationList: [formsValues.accomodationReference],
                            bookingRef: bookingInfo.id,
                            costRuleRef: costRulesAccomodation[i].id,
                            supplierId: costRulesAccomodation[i].data.supplierId ? costRulesAccomodation[i].data.supplierId : null,
                          },
                          context.organizationId
                        );
                      }
                    }
                    catch (e) {
                      toast.error("An error occurred: " + e);
                    }
                  }
                  context.updateData("costs");
                })
                .catch((err) => {
                  toast.error("An error occurred: " + err);
                });
            }
          }}
        />
      </div>
    </div>
  );
};

export default BookingManager;