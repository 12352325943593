import React, { useContext, useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import {
  IoIosArrowBack,
  IoIosArrowForward,
  IoMdAddCircleOutline,
} from "react-icons/io";
import { GoTriangleDown, GoTriangleUp } from "react-icons/go";
import { format, formatISO } from "date-fns";
import { it } from 'date-fns/locale';
import PrimaryButton from "../../../../components/buttons/primary-button";
import SecondaryButton from "../../../../components/buttons/secondary-button";
import { IoPencil, IoTrashBin } from "react-icons/io5";
import { DataContext } from "../..";
import Modal from "../../../../components/dialogs/dialog";
import { deleteBooking, deleteCost } from "../../util/firestore-functions";
import BookingManager from "./pages/booking-manager";
import { NewDatePicker } from "../../../../components/input/fields/date-pickers/newDatePicker";
import { SelectMenu } from "../../../../components/input/select/select-menu";
import BookingView from "./components/BookingView";
import { toast } from "react-toastify";
import InfoButton from "../../../../components/buttons/info-button";
import TertiaryButton from "../../../../components/buttons/tertiary-button";
import { IoDownload } from "react-icons/io5";
import ResetButton from "../../../../components/buttons/reset-button";
import DownloadButton from "../../../../components/buttons/download-button";
import UploadFileAccomodations from "./components/UploadFileAccommodations";
import ExcelSample from "../../util/sample.xlsx";

function Booking() {
  const context = useContext(DataContext);
  const timeNow = Date.now();
  let bookingList = context.bookingList;
  const [bookingOrder, setBookingOrder] = useState("reverse");
  const [amountOrder, setAmountOrder] = useState();
  const [checkInOrder, setCheckInOrder] = useState();
  const [viewModal, setViewModal] = useState();
  const [removeModal, setRemoveModal] = useState();
  const [editModal, setEditModal] = useState();
  const [importModal, setImportModal] = useState();
  const [bookingSelected, setBookingSelected] = useState();
  const [tablePage, setTablePage] = useState(1);
  const [filterDateFrom, setFilterDateFrom] = useState();
  const [filterDateTo, setFilterDateTo] = useState();
  const [filterAccomodation, setFilterAccomodation] = useState();
  const [runBookingOrder, setRunBookingOrder] = useState(0);
  const datePickerRef1 = useRef();
  const datePickerRef2 = useRef();
  const [selectedValue, setSelectedValue] = useState([]);

  const resetSelect = () => {
    setSelectedValue([]);
  };

  useEffect(() => {
    if (runBookingOrder < 2) {
      handleBookignOrder();
      setRunBookingOrder((prev) => prev + 1);
    }
  }, [runBookingOrder]);

  const showBookingInfo = () => {
    setViewModal(true);
  };

  const handleReset = () => {
    datePickerRef1.current.clearDate();
    datePickerRef2.current.clearDate();
    setFilterDateFrom();
    setFilterDateTo();
    setFilterAccomodation();
    resetSelect();
  };


  function formatNumberWithComma(number) {
    if (number == null || number == undefined) {
      return 0;
    }
    return number.toString().replace('.', ',');
  }

  var options = context.loading
    ? null
    : context.accomodationsList &&
    context.accomodationsList.map((item) => {
      return { label: item.data.name, value: item.id };
    });

  let filteredBookingData = bookingList.filter((value) => {
    if (filterDateFrom && filterDateTo && filterAccomodation) {
      return (
        format(filterDateFrom, "t") <=
        format(new Date(1000 * value.data.checkInDate.seconds), "t") &&
        format(filterDateTo, "t") >=
        format(new Date(1000 * value.data.checkInDate.seconds), "t") &&
        filterAccomodation.some(
          (valueFilter) => valueFilter.value === value.data.accomodationReference
        )
      );
    } else if (filterDateFrom && filterDateTo) {
      return (
        format(filterDateFrom, "t") <=
        format(new Date(1000 * value.data.checkInDate.seconds), "t") &&
        format(filterDateTo, "t") >=
        format(new Date(1000 * value.data.checkInDate.seconds), "t")
      );
    } else if (filterAccomodation) {
      return filterAccomodation.some(
        (valueFilter) => valueFilter.value === value.data.accomodationReference
      );
    }

  });




  const handleBookignOrder = () => {
    setCheckInOrder();
    setAmountOrder();
    if (bookingOrder === undefined) {
      bookingList = bookingList.sort((a, b) => a.data.amount - b.data.amount);
      setBookingOrder("sort");
    }
    if (bookingOrder === "reverse") {
      bookingList = bookingList.sort(
        (a, b) => a.data.bookingDate.seconds - b.data.bookingDate.seconds
      );
      setBookingOrder("sort");
    }
    if (bookingOrder === "sort") {
      bookingList = bookingList.reverse(
        (a, b) => a.data.bookingDate.seconds - b.data.bookingDate.seconds
      );
      setBookingOrder("reverse");
    }
  };

  const handleAmountOrder = () => {
    setBookingOrder();
    setCheckInOrder();
    if (amountOrder === undefined) {
      bookingList = bookingList.sort((a, b) => a.data.amount - b.data.amount);
      setAmountOrder("sort");
    }
    if (amountOrder === "sort") {
      bookingList = bookingList.reverse(
        (a, b) => a.data.amount - b.data.amount
      );
      setAmountOrder("reverse");
    }
    if (amountOrder === "reverse") {
      bookingList = bookingList.sort((a, b) => a.data.amount - b.data.amount);
      setAmountOrder("sort");
    }
  };

  const handleCheckInOrder = () => {
    setBookingOrder();
    setAmountOrder();
    if (checkInOrder === undefined) {
      bookingList = bookingList.sort(
        (a, b) => a.data.checkInDate.seconds - b.data.checkInDate.seconds
      );
      setCheckInOrder("sort");
    }
    if (checkInOrder === "sort") {
      bookingList = bookingList.reverse(
        (a, b) => a.data.checkInDate.seconds - b.data.checkInDate.seconds
      );
      setCheckInOrder("reverse");
    }
    if (checkInOrder === "reverse") {
      bookingList = bookingList.sort(
        (a, b) => a.data.checkInDate.seconds - b.data.checkInDate.seconds
      );
      setCheckInOrder("sort");
    }
  };

  const BookingRow = ({ bookingInfo, id }) => {

    const accomodationName = context.accomodationsList.find((item) =>
      item.id === bookingInfo.accomodationReference).data.name;

    const bookingDate = new Date(
      1000 * bookingInfo.bookingDate.seconds
    );
    const checkInDate = new Date(
      1000 * bookingInfo.checkInDate.seconds
    );
    const checkOutDate = new Date(
      1000 * bookingInfo.checkOutDate.seconds
    );
    //Channel Commission 
    let channelCommission = bookingInfo.commissionAmount ? bookingInfo.commissionAmount : 0;
    return (


      <tr className="border-b cursor-pointer hover:bg-neutral-100" id={id}>
        <td
          className="p-2 text-center border-r"
          onClick={() => {
            setBookingSelected({
              id: id,
              accomodationReference: accomodationName,
              bookingDate: bookingDate,
              checkInDate: checkInDate,
              checkOutDate: checkOutDate,
              numberOfGuest: bookingInfo.numberOfGuest,
              amount: bookingInfo.amount,
              commissionChannel: bookingInfo.commissionChannel,
              commissionAmount: bookingInfo.commissionAmount,
              costRuleRef: bookingInfo.costRuleRef,
              commissionType: bookingInfo.commissionType
            });
            showBookingInfo();
          }}
        >
          {accomodationName}
        </td>
        <td
          className="p-2 text-center border-r"
          onClick={() => {
            setBookingSelected({
              id: id,
              accomodationReference: accomodationName,
              bookingDate: bookingDate,
              checkInDate: checkInDate,
              checkOutDate: checkOutDate,
              numberOfGuest: bookingInfo.numberOfGuest,
              amount: bookingInfo.amount,
              commissionChannel: bookingInfo.commissionChannel,
              commissionAmount: bookingInfo.commissionAmount,
              costRuleRef: bookingInfo.costRuleRef,
              commissionType: bookingInfo.commissionType
            });
            showBookingInfo();
          }}
        >
          {format(bookingInfo.bookingDate.toDate(), "dd/MM/yyyy", { locale: it }  )}
          
        </td>
        <td
          className="p-2 text-center border-r"
          onClick={() => {
            setBookingSelected({
              id: id,
              accomodationReference: accomodationName,
              bookingDate: bookingDate,
              checkInDate: checkInDate,
              checkOutDate: checkOutDate,
              numberOfGuest: bookingInfo.numberOfGuest,
              amount: bookingInfo.amount,
              commissionChannel: bookingInfo.commissionChannel,
              commissionAmount: bookingInfo.commissionAmount,
              costRuleRef: bookingInfo.costRuleRef,
              commissionType: bookingInfo.commissionType
            });
            showBookingInfo();
          }}
        >
          {format(bookingInfo.checkInDate.toDate(), "dd/MM/yyyy", { locale: it }  )}
        </td>
        <td
          className="p-2 text-center border-r"
          onClick={() => {
            setBookingSelected({
              id: id,
              accomodationReference: accomodationName,
              bookingDate: bookingDate,
              checkInDate: checkInDate,
              checkOutDate: checkOutDate,
              numberOfGuest: bookingInfo.numberOfGuest,
              amount: bookingInfo.amount,
              commissionChannel: bookingInfo.commissionChannel,
              commissionAmount: bookingInfo.commissionAmount,
              costRuleRef: bookingInfo.costRuleRef,
              commissionType: bookingInfo.commissionType
            });
            showBookingInfo();
          }}
        >
          {format(bookingInfo.checkOutDate.toDate(), "dd/MM/yyyy", { locale: it }  )}
        </td>
        <td
          className="p-2 text-center border-r"
          onClick={() => {
            setBookingSelected({
              id: id,
              accomodationReference: accomodationName,
              bookingDate: bookingDate,
              checkInDate: checkInDate,
              checkOutDate: checkOutDate,
              numberOfGuest: bookingInfo.numberOfGuest,
              amount: bookingInfo.amount,
              commissionChannel: bookingInfo.commissionChannel,
              commissionAmount: bookingInfo.commissionAmount,
              costRuleRef: bookingInfo.costRuleRef,
              commissionType: bookingInfo.commissionType
            });
            showBookingInfo();
          }}
        >
          {bookingInfo.numberOfGuest}
        </td>
        <td
          className="p-2 text-center border-r"
          onClick={() => {
            setBookingSelected({
              id: id,
              accomodationReference: accomodationName,
              bookingDate: bookingDate,
              checkInDate: checkInDate,
              checkOutDate: checkOutDate,
              numberOfGuest: bookingInfo.numberOfGuest,
              amount: bookingInfo.amount,
              commissionChannel: bookingInfo.commissionChannel,
              commissionAmount: bookingInfo.commissionAmount,
              costRuleRef: bookingInfo.costRuleRef,
              commissionType: bookingInfo.commissionType
            });
            showBookingInfo();
          }}
        >
          {"€ " + formatNumberWithComma(bookingInfo.amount)}
        </td>
        <td
          className="p-2 text-center border-r"
          onClick={() => {
            setBookingSelected({
              id: id,
              accomodationReference: accomodationName,
              bookingDate: bookingDate,
              checkInDate: checkInDate,
              checkOutDate: checkOutDate,
              numberOfGuest: bookingInfo.numberOfGuest,
              amount: bookingInfo.amount,
              commissionChannel: bookingInfo.commissionChannel,
              commissionAmount: bookingInfo.commissionAmount,
              costRuleRef: bookingInfo.costRuleRef,
              commissionType: bookingInfo.commissionType
            });
            showBookingInfo();
          }}
        >
          {bookingInfo.commissionChannel || "Direct"}
        </td>
        <td
          className="p-2 text-center border-r"
          onClick={() => {
            setBookingSelected({
              id: id,
              accomodationReference: accomodationName,
              bookingDate: bookingDate,
              checkInDate: checkInDate,
              checkOutDate: checkOutDate,
              numberOfGuest: bookingInfo.numberOfGuest,
              amount: bookingInfo.amount,
              commissionChannel: bookingInfo.commissionChannel,
              commissionAmount: bookingInfo.commissionAmount,
              costRuleRef: bookingInfo.costRuleRef,
              commissionType: bookingInfo.commissionType
            });
            showBookingInfo();
          }}
        >
          {"€ " + formatNumberWithComma(bookingInfo.commissionAmount)}
        </td>
        <td className="">
          <div className="flex items-center justify-center">
            <PrimaryButton
              onClick={() => {
                setBookingSelected({
                  id: id,
                  accomodationReference: bookingInfo.accomodationReference,
                  bookingDate: bookingDate,
                  checkInDate: checkInDate,
                  checkOutDate: checkOutDate,
                  numberOfGuest: bookingInfo.numberOfGuest,
                  amount: bookingInfo.amount,
                  commissionChannel: bookingInfo.commissionChannel,
                  commissionAmount: bookingInfo.commissionAmount,
                  costRuleRef: bookingInfo.costRuleRef,
                  commissionType: bookingInfo.commissionType
                });
                setEditModal(true);
              }}
              content={<IoPencil className="mx-auto" />}
              className={"p-1 mx-2 max-w-[100px]"}
            />
            <SecondaryButton
              onClick={() => {
                setBookingSelected({
                  id: id,
                  accomodationReference: accomodationName,
                  bookingDate: bookingDate,
                  checkInDate: checkInDate,
                  checkOutDate: checkOutDate,
                  numberOfGuest: bookingInfo.numberOfGuest,
                  amount: bookingInfo.amount,
                  commissionChannel: bookingInfo.commissionChannel,
                  commissionAmount: bookingInfo.commissionAmount,
                  costRuleRef: bookingInfo.costRuleRef,
                  commissionType: bookingInfo.commissionType
                });
                setRemoveModal(true);
              }}
              content={<IoTrashBin className="mx-auto" />}
              className={"p-1 mx-2 max-w-[100px]"}
            />
          </div>
        </td>
      </tr>


    );


  }
  return (
    <>

      <InfoButton nameComponent={'bookings'} />
      <div className="w-full">
        <h1 className="text-3xl font-bold">Bookings</h1>
        <div className="flex justify-between mt-5">
          <Link
            className="flex flex-row items-center my-5 text-2xl font-normal cursor-pointer w-fit"
            to="new-booking"
          >
            <IoMdAddCircleOutline className="mr-1 w-[2rem]" />
            <p>Add a new booking</p>
          </Link>
          <div className="flex justify-end">

            <PrimaryButton
              className={"bg-green text-black p-2 w-fit min-w-[130px] mt-2 mr-10"}
              onClick={() => { setImportModal(true) }}
              content={"Import"}
            />        <DownloadButton
              className={"bg-blue text-white p-2 w-fit min-w-[170px] mt-2 "}
              content={"Download Sample"}
              download={"Sample"}
              to={ExcelSample}
            />
          </div>
        </div>
        <hr className="w-full max-w-5xl mb-6" />

        {
          /* Table */
          bookingList.length != 0 ? (
            <>
              <table className="w-full mb-10 bg-white rounded-lg shadow-md">
                <tbody className="">
                  <tr>
                    <th className="p-4 text-left w-fit">Filter Bookings</th>
                    <th className="text-left w-fit">
                      <div className="flex items-center justify-start space-x-5">
                        <h4>Check-In :</h4>
                        <NewDatePicker
                          className={"w-[200px]"}
                          ref={datePickerRef1}
                          placeholder={"From"}
                          onCompleted={(el) => {
                            setFilterDateFrom(el);
                          }}
                        />
                        <NewDatePicker
                          className={"w-[200px]"}
                          ref={datePickerRef2}
                          placeholder={"To"}
                          onCompleted={(el) => {
                            if (filterDateFrom && el < filterDateFrom) {
                              toast.error("Check dates");
                              setFilterDateTo();
                            } else {
                              setFilterDateTo(el);
                            }
                          }}
                        />

                      </div>
                    </th>
                    <th className="text-left w-fit">
                      <div className="flex items-center justify-start space-x-5">
                        <h4>Accommodation:</h4>
                        <SelectMenu
                          className={"w-[200px]"}
                          placeholder={"Select..."}
                          options={options}
                          isMulti={true}
                          isClearable
                          value={selectedValue}
                          // isLoading={context.loading}
                          isDisabled={context.loading}
                          isSearchable
                          onChange={(value) => {
                            if (value.length > 0) {
                              setSelectedValue(value);
                              setFilterAccomodation(value);
                            } else {
                              setSelectedValue([]);
                              setFilterAccomodation(options);
                            }
                          }}
                        />
                      </div>
                    </th>
                    <th>
                      <ResetButton onReset={handleReset} />
                    </th>
                  </tr>
                </tbody>
              </table>
              <table className="w-full bg-white rounded-lg shadow-md">
                <thead>
                  <tr className="border-b-2">
                    <th className="p-4 border-r w-96">Accommodation</th>
                    <th
                      className="p-4 border-r w-96 hover:cursor-pointer hover:bg-neutral-50"
                      onClick={handleBookignOrder}
                    >
                      <div className="flex items-center justify-center ">
                        {bookingOrder === "sort" && (
                          <GoTriangleUp className="" />
                        )}
                        {bookingOrder === "reverse" && (
                          <GoTriangleDown className="" />
                        )}
                        <p className="ml-2">Booking Date</p>
                      </div>
                    </th>
                    <th
                      className="p-4 border-r w-96 hover:cursor-pointer hover:bg-neutral-50"
                      onClick={handleCheckInOrder}
                    >
                      <div className="flex items-center justify-center ">
                        {checkInOrder != undefined && checkInOrder === "sort" && (
                          <GoTriangleUp className="" />
                        )}
                        {checkInOrder != undefined &&
                          checkInOrder === "reverse" && (
                            <GoTriangleDown className="" />
                          )}
                        <p className="ml-2">Check-In Date</p>
                      </div>
                    </th>
                    <th className="w-64 p-4 border-r">Check-Out Date</th>
                    <th className="p-4 border-r w-28">Guests</th>
                    <th
                      className="flex items-center justify-center w-40 p-4 border-r hover:cursor-pointer hover:bg-neutral-50"
                      onClick={handleAmountOrder}
                    >
                      {amountOrder != undefined && amountOrder === "sort" && (
                        <GoTriangleUp />
                      )}
                      {amountOrder != undefined && amountOrder === "reverse" && (
                        <GoTriangleDown />
                      )}
                      Booking Amount
                    </th>
                    <th className="p-4 border-r w-28">Channel</th>
                    <th className="p-4 border-r w-28">Channel Commission</th>
                    <th className="w-52">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {((filterDateFrom && filterDateTo) || filterAccomodation) ? (
                    filteredBookingData.length > 0 ? (
                      filteredBookingData.map((booking, key) => {
                        if (key >= tablePage * 10 - 10 && key < tablePage * 10) {

                          return (
                            <BookingRow bookingInfo={booking.data} id={booking.id} key={key} />
                          );
                        }
                        return null;
                      })
                    ) : (
                      <tr>
                        <td colSpan={9} className="text-lg font-semibold text-center">
                          No Bookings Available
                        </td>
                      </tr>
                    )
                  ) : (
                    !(filterDateFrom && filterDateTo) && !filterAccomodation && (
                      bookingList.length > 0 ? (
                        bookingList.map((booking, key) => {
                          if (key >= tablePage * 10 - 10 && key < tablePage * 10) {

                            return (
                              <BookingRow bookingInfo={booking.data} id={booking.id} key={key} />
                            );
                          }
                          return null;
                        })
                      ) : (
                        <tr>
                          <td colSpan={9} className="text-lg font-semibold text-center">
                            No Bookings Available
                          </td></tr>
                      )
                    )
                  )}
                </tbody>
                <tfoot>
                  <tr className="border-b-2 bg-neutral-50">
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th className="border-r"></th>
                    <th colSpan={5} className="flex items-center justify-center w-full px-4 space-x-4">
                      <SecondaryButton
                        content={"<"}
                        className={"w-[30px] p-1"}
                        onClick={() =>
                          tablePage != 1 ? setTablePage(tablePage - 1) : null
                        }
                      />

                      <p className="text-xs text-center">
                        {tablePage} of {Math.ceil(bookingList.length / 10)}
                      </p>
                      <div className="flex items-center justify-center space-x-2">
                        {
                          <SecondaryButton
                            content={">"}
                            className={"w-[30px] p-1"}
                            onClick={() =>
                              Math.ceil(bookingList.length / 10) != tablePage
                                ? setTablePage(tablePage + 1)
                                : null
                            }
                          />
                        }
                      </div>
                    </th>
                  </tr>
                </tfoot>
              </table>              
              
            </>
          ) : (
            <div className="text-lg font-semibold text-center">No booking</div>
          )
        }
        { (bookingList.length >= 10 && !filterDateFrom && !filterDateTo && !filterAccomodation) || selectedValue.length >= 10 ?
          <div className="font-bold flex items-center justify-end p-4 text-petroil rounded-full ">
          <p className="mr-1">Do you need support?</p>
          <a href="mailto:support@bilemon.com" className="text-petroil hover:brightness-125 rounded-full bg-transparent"
          >
            Contact us.
          </a>
        </div>
        :
        <div className="font-bold absolute bottom-0 right-10 flex items-center justify-end p-4 text-petroil rounded-full ">
  <p className="mr-1">Do you need support?</p>
  <a href="mailto:support@bilemon.com" className="text-petroil hover:brightness-125 rounded-full bg-transparent"
  >
    Contact us.
  </a>
</div>
        }
      </div>

      <Modal
        externalControl
        title={"Remove Booking"}
        body={
          <>
            <h1 className="text-lg">Are you sure to remove this booking?</h1>
            <h2>{ }</h2>
            <div className="flex justify-end">
              <TertiaryButton
                className={"bg-slate-400 mr-2 text-white p-2 w-fit max-w-[130px] mt-2"}
                content={"Cancel"}
                onClick={() => {
                  setRemoveModal(false)}}
              />
              <TertiaryButton
                className={"bg-red text-white p-2 w-fit max-w-[130px] mt-2"}
                content={"Yes, I'm sure"}
                onClick={() => {
                  deleteBooking(bookingSelected.id, context.organizationId)
                    .then(async () => {
                      setRemoveModal(false);
                      setBookingSelected();
                      context.updateData("bookings");
                      const costs = context.costsList.filter(x => (x.data.costTypology && x.data.costTypology.value === 2) && x.data.bookingRef === bookingSelected.id);
                      for (let i = 0; i < costs.length; i++) {
                        try {
                          await deleteCost(costs[i].id, context.organizationId);
                        }
                        catch (ex) {
                          toast.error("Error while deleting cost: " + ex);
                        }
                      }
                      context.updateData("costs");
                    })
                    .catch((error) =>   toast.error("Error while deleting booking:" + error));
                }}
              />
            </div>
          </>
        }
        status={removeModal}
        onClose={() => setRemoveModal(false)}
      />
      <Modal
        externalControl
        title={"Edit Booking"}
        className={"w-[400px]"}
        body={
          <BookingManager
            bookingInfo={bookingSelected}
            onComplete={() => setEditModal(false)}
            onClose={() => setEditModal(false)}
          />
        }
        status={editModal}
        onClose={() => setEditModal(false)}
      />
      <Modal
        externalControl
        title={"Upload accommodations"}
        className={"min-h-[20vh] min-w-[30vw]"}
        body={
          <UploadFileAccomodations onClose={() => setImportModal(false)} />
        }
        status={importModal}
        onClose={() => setImportModal(false)}
      />
      <Modal
        externalControl
        status={viewModal}
        title={"Booking details"}
        className={"w-[600px]"}
        body={
          <BookingView
            selectedCost={bookingSelected}
            onClose={() => setViewModal(false)}
          />
        }
        onClose={() => setViewModal(false)}
      />
      
    </>
  );
}

export default Booking;