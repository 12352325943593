import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { IoMdAddCircleOutline } from "react-icons/io";
// import { AccomodationsManager } from "./accomodations-manager";
import { AccomodationCard } from "./accomodation-card";
import { DataContext } from "../..";
import { SelectMenu } from "../../../../components/input/select/select-menu";
import InfoButton from "../../../../components/buttons/info-button";


export const Accomodations = () => {
  const context = useContext(DataContext);

  const accomodationsList = useContext(DataContext).accomodationsList;
  const [filterAccomodation, setFilterAccomodation] = useState();
  const [filterStatus, setFilterStatus] = useState(null);
  
  


  var options = context.loading
? null
: accomodationsList &&
  accomodationsList.map((item) => {
    return { label: item.data.name, value: item.id };
  });

  var optionsStatus = [
    {
      label: "Active",
      value: "Active"
    },
    {
      label: "Disabled",
      value: "Disabled"
    }
  ]
  
  let filtredAccomodationList = Array();

  if (filterAccomodation && filterStatus) {   
    accomodationsList && context.accomodationsList.map((x) => {
      filterAccomodation.map((valueFilter) => {
        if(valueFilter.value === x.id && x.data.status.value === filterStatus.value){          
          filtredAccomodationList.push(x);
        }
      })
    })
  }else if(filterAccomodation && !filterStatus){
    accomodationsList && context.accomodationsList.map((x) => {
      filterAccomodation.map((valueFilter) => {
        if(valueFilter.value === x.id){          
          filtredAccomodationList.push(x);
        }
      })
    })
  }else if(!filterAccomodation && filterStatus){
    accomodationsList && context.accomodationsList.map((x) => {
      if(x.data.status.value === filterStatus.value){          
        filtredAccomodationList.push(x);
      }
    })
  }
  return (
    
    <div className="w-full">
      <InfoButton nameComponent={'accommodations'}/>
      <div className="text-3xl font-bold ">Accommodations</div>

      {((context.organizationInfo.subscription.plan === "host" && accomodationsList.length < 4) || (context.organizationInfo.subscription.plan === "propertyManager")) && (
        <Link
        className="flex flex-row items-center my-5 text-2xl font-normal cursor-pointer w-fit"
        to="new-accommodation"
      >
        <IoMdAddCircleOutline className="mr-1 w-[2rem]" />
        <p>Add a new accommodation</p>
      </Link>
      )

      }
      
      <div className="flex items-center justify-start space-x-5">
                      <h4>Filter Accommodation name:</h4>
                      <SelectMenu
              className={"max-w-[500px]"}
              placeholder={"Select..."}
              options={options}
              isMulti={true}
              isClearable
              isLoading={context.loading}
              isDisabled={context.loading}
              isSearchable
              onChange={(value) => {
                if (value.length > 0) {
                  setFilterAccomodation(value);
                }else{
                  setFilterAccomodation(options)
                }
                }
              }
            />
             <h4>Filter Accommodation status:</h4>
             <SelectMenu
                className={"max-w-[250px]"}
                placeholder={"Select status..."}
                options={optionsStatus}
                isMulti={false}
                isClearable={true}
                isLoading={context.loading}
                isDisabled={context.loading}
                isSearchable
                onChange={(e) => setFilterStatus(e)}
              />
                    </div>
      <hr className="w-full max-w-20xl mb-6" />
      <div className="flex flex-wrap">
      {(filterAccomodation || filterStatus) && filtredAccomodationList.length > 0 ? (
        filtredAccomodationList.map((element, key) => (
          <AccomodationCard
            id={element.id}
            address={element.data.address}
            bathroom={element.data.numberBathroom}
            doubleBed={element.data.numberDoubleBed}
            singleBed={element.data.numberSingleBed}
            maxPeople={element.data.maxPeople}
            name={element.data.name}
            owner={element.data.owner}
            tax={element.data.touristTaxRate}
            status={element.data.status}
            key={key}
            contractTypology={element.data.contractTypology}
          />
          ))
          ) : (!filterAccomodation && !filterStatus) ? (
            accomodationsList.length > 0 ? (
              accomodationsList.map((element, key) => (
                <AccomodationCard
                  id={element.id}
                  address={element.data.address}
                  bathroom={element.data.numberBathroom}
                  doubleBed={element.data.numberDoubleBed}
                  singleBed={element.data.numberSingleBed}
                  maxPeople={element.data.maxPeople}
                  name={element.data.name}
                  owner={element.data.owner}
                  tax={element.data.touristTaxRate}
                  status={element.data.status}
                  key={key}
                  contractTypology={element.data.contractTypology}
                  numberRooms={element.data.numberRooms}
                />
              ))
            ) : (
              <div className="mx-auto text-xl font-semibold text-center">
                No accommodations
              </div>
            )
          ) : (
            <div className="mx-auto text-xl font-semibold text-center">
              No accommodations
            </div>
          )}
         
      </div>
      { (accomodationsList.length > 4 && !filterAccomodation && !filterStatus) || ( filtredAccomodationList.length > 3  ) ?
            <div className="font-bold  flex items-center justify-end p-4 text-petroil rounded-full ">
        <p className="mr-1">Do you need support?</p>
        <a href="mailto:support@bilemon.com" className="text-petroil hover:brightness-125 rounded-full bg-transparent"
        >
          Contact us.
        </a>
      </div>
      :
      <div className="font-bold absolute bottom-0 right-10 flex items-center justify-end p-4 text-petroil rounded-full ">
        <p className="mr-1">Do you need support?</p>
        <a href="mailto:support@bilemon.com" className="text-petroil hover:brightness-125 rounded-full bg-transparent"
        >
          Contact us.
        </a>
      </div>
          }
      
    </div>
    

      
    
  );
};
