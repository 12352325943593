import React, { useContext, useState, useEffect } from "react";
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import { DataContext } from "..";
import LoadingApp from "../../loading/LoadingApp";
import  SecondaryButton  from "../../../components/buttons/secondary-button";
import { HiRefresh } from "react-icons/hi";
import InfoButton from "../../../components/buttons/info-button";
import { toast } from "react-toastify";
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import Box from '@mui/material/Box';

import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

function Home() {
  const context = useContext(DataContext);
  const [loading, setLoading] = useState(true);
  const [param, setParams] = useState();

  const steps2 = [
    "Add a new Accommodation",
    "Add a new Cost Rules",
    "Add a new Booking",
  ];

  const addLineBreaks = (text) => {
    // Add <br> tags after periods to create line breaks
    return text.replace(/\. /g, ".<br><br>");
  };

  const steps = [
    {
      label: 'Add a new Accommodation ',
      description: `Let's start by configuring your accommodations. 

      Navigate to the “Accommodation" section, and let’s begin by clicking on the 'Add a New Accommodation' button. Here, you can effortlessly input key details that characterize each accommodation. 
      
      The setup for each accommodation is designed to be swift, requiring no more than a minute of your time. 
      
      Ready to get started? Let’s go!!! `,
    },
    {
      label: 'Add a new Cost Rules',
      description:
        `Welcome to the most innovative section of our solution—an invaluable tool for managing your business effectively. 

        Automate costs triggered by events you designate, instantly building and classifying your cost structure. 
        
        Each cost is meticulously allocated and accurately classified. 
        
        Click the 'I' button in each section for easy rule configuration.  
        
        Ready to take control? Let's master the Cost Rules! `,
    },
    {
      label: 'Add a new Booking',
      description: `The best is coming! 

      Add your reservations to unlock comprehensive insights through our powerful business intelligence. This enables you to maximize profitability for your business and accommodations. 
      
      For those with numerous reservations, streamline the process by mass importing using our user-friendly Excel spreadsheet.  
      
      Don't waste time—transform information into knowledge! `,
    },
  ];

  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  
  const isMobileTablet = window.matchMedia("(max-width: 991px)").matches;

  const details = {
    clientId: process.env.REACT_APP_CLIENT_ID_AZURE,
    clientSecret: process.env.REACT_APP_CLIENT_SECRET_AZURE,
    tenant: process.env.REACT_APP_TENANT_AZURE 
  };
  // && context.organizationInfo.powerBiDataSetId
  if (context.organizationInfo.powerBiWorkspaceId && context.organizationInfo.powerBiReportId && loading) {
    fetch(process.env.REACT_APP_POWERBI_TOKEN, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(details),
    }).then(async (response) => {
      if (response.ok) {
        let tokenObject = await response.json();
        setParams(await getEmbedParamsForSingleReport(context.organizationInfo.powerBiWorkspaceId, context.organizationInfo.powerBiReportId, tokenObject["access_token"]));
        setLoading(false);
      }
    }).catch(error =>  toast.error("An error occurred: " + error));
  }

  const refreshPowerBi = () => {
    setLoading(true);
    fetch(process.env.REACT_APP_POWERBI_TOKEN, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(details),
    }).then(async (response) => {
      if (response.ok) {
        let tokenObject = await response.json();
        setParams(await setRefreshDataSet(context.organizationInfo.powerBiWorkspaceId, context.organizationInfo.powerBiReportId, context.organizationInfo.powerBiDataSetId, tokenObject["access_token"]));
        setLoading(false);
      }
    }).catch(error => toast.error("An error occurred: " + error));
  };


  return (
    <>
    <InfoButton nameComponent={'dashboard'}/>  

    
    {context.organizationInfo.powerBiWorkspaceId && context.organizationInfo.powerBiReportId ?
    loading ? <LoadingApp /> :
    <>
        <SecondaryButton
  content={
    <>
      <HiRefresh 
      className={'opacity-50'}/>
      <span className={`ml-2 opacity-50 ${isMobileTablet ? 'text-sm hidden md:inline' : 'text-base'}`}>Refresh</span>
      <span className="premium-required-tooltip">Premium Required</span>
    </>
  }
  // onClick={refreshPowerBi}
  className={`absolute top-30 flex items-center justify-center hover:brightness-95 right-12 mx-8 mt-1 p-5 text-white rounded-xl w-6 h-6 cursor-not-allowed`}
/>


          <div className="w-full">
          <h1 className={` font-bold ${isMobileTablet ? 'text-2xl mt-2' : 'text-3xl'}`}>Dashboard</h1>
            <div className={`items-start justify-start mt-10 w-full ${isMobileTablet ? '' : 'px-5 '}  minWidth`}>
            <div className="w-full h-full mx-auto" >
              {<PowerBIEmbed
                embedConfig={{
                  type: 'report',   // Supported types: report, dashboard, tile, visual and qna
                  id: param.reportsDetail[0].Id,
                  embedUrl: param.reportsDetail[0].EmbedUrl,
                  accessToken: param.EmbedToken.token,
                  tokenType: models.TokenType.Embed,
                  settings: {
                    filterPaneEnabled: false,
                    navContentPaneEnabled: true,
                    layoutType: isMobileTablet ? models.LayoutType.MobilePortrait : models.DisplayOption.FitToWidth,
                    // layoutType: models.LayoutType.Custom,
                    // customLayout: {
                    //   displayOption: models.DisplayOption.FitToWidth
                    // }
                  }
                }}
                
              />
              
              }
              
            </div>
            </div>
            {context.organizationInfo.powerBiWorkspaceId && context.organizationInfo.powerBiReportId ? 
  <div className=" flex font-bold items-center justify-end p-4 text-petroil rounded-full ">
  <p className="mr-1">Do you need support?</p>
  <a href="mailto:support@bilemon.com" className="text-petroil hover:brightness-125 rounded-full bg-transparent"
  >
    Contact us.
  </a>
  </div> :
  
  <div className="font-bold flex items-center justify-end p-4 text-petroil rounded-full ">
          <p className="mr-1">Do you need support?</p>
          <a href="mailto:support@bilemon.com" className="text-petroil hover:brightness-125 rounded-full bg-transparent"
          >
            Contact us.
          </a>
        </div>
  
              
              }
          </div>
        </>
      :
      <>
      <div className="flex flex-wrap flex-col items-center justify-center mx-auto text-xl font-semibold min-h-full min-w-[800px]">
          <h2 className="text-4xl mb-8 font-bold">Welcome to BiLemon!</h2>

          <Box sx={{ maxWidth: 400, minWidth:400 }}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel
              optional={
                index === 2 ? (
                  <Typography variant="caption">Last step</Typography>
                ) : null
              }
            >
            <p ref={focus} className="font-semibold text-blue text-lg">{step.label}</p>   
            </StepLabel>
            <StepContent>
            <Typography dangerouslySetInnerHTML={{ __html: addLineBreaks(step.description) }} />
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {index === steps.length - 1 ? 'Finish' : 'Continue'}
                  </Button>
                  <Button
                    disabled={index === 0}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Back
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <div  className="p-3">
          <Typography><p className="mb-10 mt-10 text-blue font-bold text-lg">Enjoy your Business Intelligence!</p></Typography>
          <Button onClick={handleReset} sx={{ mt: 1, mr: 1, p: 0 }} className="text-blue">
            <p className="text-blue">Reset</p> 
          </Button>
        </div>
      )}
    </Box>
    </div>
    <div className="absolute bottom-0 right-10 font-bold flex items-center justify-end p-4 text-petroil rounded-full ">
          <p className="mr-1">Do you need support?</p>
          <a href="mailto:support@bilemon.com" className="text-petroil hover:brightness-125 rounded-full bg-transparent"
          >
            Contact us.
          </a>
        </div>
        </>
}

      </>
  );
  
}


/**
 * Get embed params for a single report for a single workspace
 * @param {string} workspaceId
 * @param {string} reportId
 * @param {string} additionalDatasetId - Optional Parameter
 * @param {string} accessToken
 * @return EmbedConfig object
 */


async function setRefreshDataSet(workspaceId, reportId, dataSetId, accessToken){
  const reportInGroupApi = `https://api.powerbi.com/v1.0/myorg/groups/${workspaceId}/datasets/${dataSetId}/refreshes`;

  const result = await fetch(reportInGroupApi, {
    method: 'POST',
    headers: {
      'Content-Type': "application/json",
      'Authorization': `Bearer ${accessToken}`
    },
    body: {
      type: "full",
      maxParallelism: 10
    }

  })

  if (!result.ok) {
    let error = await result.text();
    throw result;
  }

    // Convert result in json to retrieve values
    const resultJson = await result.json();



    let powerBiDetails = {
      Id: resultJson.id,
      Name: resultJson.name,
      EmbedUrl: resultJson.embedUrl
    }
  
    let powerBiConfig = {
      reportsDetail: [powerBiDetails]
    };
  
    // Create list of datasets
    let datasetIds = [resultJson.datasetId];
  
    // Get Embed token multiple resources
    powerBiConfig.EmbedToken = await getEmbedTokenForSingleReportSingleWorkspace(reportId, datasetIds, workspaceId, accessToken);
    return powerBiConfig;

}

async function getEmbedParamsForSingleReport(workspaceId, reportId, accessToken) {
  const reportInGroupApi = `https://api.powerbi.com/v1.0/myorg/groups/${workspaceId}/reports/${reportId}`;

  // Get report info by calling the PowerBI REST API
  const result = await fetch(reportInGroupApi, {
    method: 'GET',
    headers: {
      'Content-Type': "application/json",
      'Authorization': `Bearer ${accessToken}`
    },
  })

  if (!result.ok) {
    let error = await result.text();
    throw result;
  }

  // Convert result in json to retrieve values
  const resultJson = await result.json();



  let powerBiDetails = {
    Id: resultJson.id,
    Name: resultJson.name,
    EmbedUrl: resultJson.embedUrl
  }

  let powerBiConfig = {
    reportsDetail: [powerBiDetails]
  };

  // Create list of datasets
  let datasetIds = [resultJson.datasetId];

  // Get Embed token multiple resources
  powerBiConfig.EmbedToken = await getEmbedTokenForSingleReportSingleWorkspace(reportId, datasetIds, workspaceId, accessToken);
  return powerBiConfig;
}

/**
 * Get Embed token for single report, multiple datasets, and an optional target workspace
 * @param {string} reportId
 * @param {Array<string>} datasetIds
 * @param {string} targetWorkspaceId - Optional Parameter
 * @param {string} accessToken
 * @return EmbedToken
 */
async function getEmbedTokenForSingleReportSingleWorkspace(reportId, datasetIds, targetWorkspaceId, accessToken) {

  // Add report id in the request
  let formData = {
    'reports': [{
      'id': reportId
    }]
  };

  // Add dataset ids in the request
  formData['datasets'] = [];
  for (const datasetId of datasetIds) {
    formData['datasets'].push({
      'id': datasetId
    })
  }

  // Add targetWorkspace id in the request
  if (targetWorkspaceId) {
    formData['targetWorkspaces'] = [];
    formData['targetWorkspaces'].push({
      'id': targetWorkspaceId
    })
  }

  const embedTokenApi = "https://api.powerbi.com/v1.0/myorg/GenerateToken";

  // Generate Embed token for single report, workspace, and multiple datasets. Refer https://aka.ms/MultiResourceEmbedToken
  const result = await fetch(embedTokenApi, {
    method: 'POST',
    headers: {
      'Content-Type': "application/json",
      'Authorization': `Bearer ${accessToken}`
    },
    body: JSON.stringify(formData)
  });

  if (!result.ok) {
    let error = await result.text();
    throw result;
  }
  return result.json();
  
}



export default Home;
