import React, { useState } from 'react';

function Icon({ id, open }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={2}
      stroke="currentColor"
      className={`${id === open ? "rotate-180" : ""} h-5 w-5 transition-transform`}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
    </svg>
  );
}

const Faq = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const faqData = [
    { question: "Who benefits from the Host plan? ", answer: "Small-scale entrepreneurs/property renters with up to 4 properties who currently don't require immediate plan upgrades. ", position: 1 },
    { question: "Who is the Property Manager plan designed for? ", answer: "Entrepreneurs with 5 or more properties or those actively seeking to expand their property portfolio.", position: 2 },
    { question: "Who can benefit from the Enterprise plan? ", answer: "Customized plan, personalized reports, and additional features. ", position: 3 },
    { question: "How do I access the free trial plan? ", answer: "You'll access a 14-day trial for your chosen plan during account creation. ", position: 4 },
    { question: "Are there any obligations or contracts? ", answer: "No, there are no contractual obligations. You're free to cancel anytime. ", position: 5 },
    { question: "What occurs after the free trial ends? ", answer: "Upon trial expiration, you decide whether to renew the plan or not. ", position: 6 },
    { question: "Do I need to provide credit card details for the free trial? ", answer: "No, we provide access without initial payment constraints because we believe in our product. ", position: 7 },
    { question: "Is it possible to switch between plans or upgrade/downgrade? ", answer: "Yes, it's possible. Contact support for assistance, etc. ", position: 8 }
  ];

  const sortedFaqData = faqData.sort((a, b) => a.position - b.position);

  const toggleAccordion = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <>
      <div className="w-full min-w-[700px]">
        <h1 className="text-3xl font-bold">Frequently Asked Questions</h1>
        <hr className="w-full max-w-5xl mb-6 mt-6" />
        <div className="w-full max-w-[850px] mx-auto mt-16">
          {sortedFaqData.map((faq, index) => (
            <div key={index} className="mb-2">
              <button
                className="w-full p-4 text-left bg-gray-200 hover:bg-gray-300 focus:outline-none focus:bg-gray-300 flex justify-between items-center"
                onClick={() => toggleAccordion(index)}
              >
                {faq.question}
                <Icon id={index} open={activeIndex} />
              </button>
              {index === activeIndex && (
                <div className="p-4 bg-white border-t border-gray-200">
                  {faq.answer}
                </div>
              )}
            </div>
          ))}
        </div>
        <div className="font-bold absolute bottom-0 right-10 flex items-center justify-end p-4 text-petroil rounded-full ">
  <p className="mr-1">Do you need support?</p>
  <a href="mailto:support@bilemon.com" className="text-petroil hover:brightness-125 rounded-full bg-transparent"
  >
    Contact us.
  </a>
</div>
      </div>
    </>
  );
};

export default Faq;
