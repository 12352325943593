import { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import PrimaryButton from "../../../components/buttons/primary-button";
import { PhoneField } from "../../../components/input/fields/phone-field";
import TextField from "../../../components/input/fields/text-field";
import { DataContext } from "..";
import { SelectMenu } from "../../../components/input/select/select-menu";
import InfoButton from "../../../components/buttons/info-button";


const Organization = () => {
  const context = useContext(DataContext);
  const organization = context.organizationInfo;
  const [selectedRange, setSelectedRange] = useState();
  const [formValues, setFormValues] = useState({
    name: null,
    state: null,
    numberOfEmployee: null,
    collaboratorList: null,
    website: null,
    phoneNumber: null,
    companyEmail: null,
    address: {
      street: null,
      city: null,
      cap: null,
    },
    logoUrl: null,
  });
  useEffect(() => {
    organization &&
      setSelectedRange({
        label: organization.numberOfEmployee,
        value: organization.numberOfEmployee,
      });
  }, []);

  const options = [
    {
      label: "1-19",
      value: "1-19",
    },
    {
      label: "20-49",
      value: "20-49",
    },
    {
      label: "50-99",
      value: "50-99",
    },
    {
      label: "+100",
      value: "+100",
    },
  ];
  return (
    <div className="flex flex-col content-between w-full h-full mx-10 ">
      
    <InfoButton nameComponent={'organization'}/>
      <div className="space-y-4 text-3xl font-bold">
        <div className="flex items-center justify-start">
          <h1>Organization Settings</h1>
        </div>

        <div className="flex flex-col w-full p-5 space-y-4 font-normal bg-white rounded-lg shadow-md">
          <div className="my-1 space-y-2">
            <div className="text-xl font-bold ">General</div>
            <hr className="w-full" />
          </div>
          <div className="flex items-center justify-between w-full space-x-10">
            <TextField
              label={"Name"}
              placeholder={
                organization && organization.name
                  ? organization.name
                  : "Organization's name"
              }
              labelClassName={"text-sm"}
              inputClassName={"p-1"}
              className={"max-w-xl"}
              onChange={(e) => {
                null;
              }}
            />
            <TextField
              label={"Commercial email"}
              inputClassName={"p-1"}
              labelClassName={"text-sm"}
              placeholder={
                organization && organization.companyEmail
                  ? organization.companyEmail
                  : "Company's email"
              }
              onChange={(e) => {
                null;
              }}
            />
          </div>
          <div className="flex items-center space-x-10">
            <PhoneField
              label={"Phone number"}
              placeholder={
                organization && organization.phoneNumber
                  ? organization.phoneNumber
                  : "Organization's phone number"
              }
              labelClassName={"text-sm"}
              inputClassName={"p-1"}
              onChange={(e) => {
                null;
              }}
            />
            <TextField
              label={"Website"}
              inputClassName={"p-1"}
              labelClassName={"text-sm"}
              placeholder={
                organization && organization.website
                  ? organization.website
                  : "Company's website"
              }
              onChange={(e) => {
                null;
              }}
            />
            <SelectMenu
              className={"w-[550px]"}
              inputClassName={"p-1 w-[100px]"}
              label={"Range of employee"}
              labelClassName={"text-sm"}
              options={options}
              defaultValue={selectedRange}
            />
          </div>
          <div className="space-y-2">
            <div className="text-xl font-bold ">Address</div>
            <hr className="w-full" />
          </div>
          <div className="flex items-center justify-between w-full space-x-10">
            <TextField
              label={"Street"}
              placeholder={
                organization && organization.address.street
                  ? organization.address.street
                  : "Company's street"
              }
              labelClassName={"text-sm"}
              inputClassName={"p-1"}
              onChange={(e) => {
                null;
              }}
            />
            <TextField
              label={"City"}
              inputClassName={"p-1"}
              labelClassName={"text-sm"}
              placeholder={
                organization && organization.address.city
                  ? organization.address.city
                  : "Company's city"
              }
              onChange={(e) => {
                null;
              }}
            />
          </div>
          <div className="flex items-center space-x-10">
            <TextField
              label={"CAP"}
              placeholder={
                organization && organization.address.cap
                  ? organization.address.cap
                  : "Company's CAP code"
              }
              labelClassName={"text-sm"}
              inputClassName={"p-1"}
              onChange={(e) => {
                null;
              }}
            />
            <TextField
              label={"State"}
              inputClassName={"p-1"}
              labelClassName={"text-sm"}
              placeholder={
                organization && organization.address.state
                  ? organization.address.state
                  : "Organization state"
              }
              onChange={(e) => {
                null;
              }}
            />
          </div>
        </div>
      </div>
      <div className="flex justify-end">
        <PrimaryButton
          content={"Apply changes"}
          className="px-4 py-2 mt-4 max-w-fit"
          //TODO: implementare update organization onclick
        />
      </div>
        <div className="font-bold absolute bottom-0 right-10 flex items-center justify-end p-4 text-petroil rounded-full ">
  <p className="mr-1">Do you need support?</p>
  <a href="mailto:support@bilemon.com" className="text-petroil hover:brightness-125 rounded-full bg-transparent"
  >
    Contact us.
  </a>
</div>
    </div>
  );
};

export default Organization;
