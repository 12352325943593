import TextField from "../../../../../components/input/fields/text-field";
import React, { useState } from "react";
import PrimaryButton from "../../../../../components/buttons/primary-button";
import { toast } from "react-toastify";

export const NewBookingProvider = ({ onComplete, provider }) => {
  const [name, setName] = useState(provider ? provider : null);
  const checkFields = () => {
    if (!name) {
      toast.error("Please, provide a name");
      return;
    }
    return true;
  };
  return (
    <div>
      <div className="flex flex-wrap items-center justify-start">
        <TextField
          label={"Name *"}
          placeholder={name ? name : "Insert.."}
          value={name}
          className={"max-w-xs"}
          inputClassName={"p-1"}
          onChange={(e) => setName(e.target.value)}
        />
      </div>
      <div className="flex items-center justify-end w-full">
        {" "}
        <PrimaryButton
          content={provider ? "Edit provider" : "Add new booking provider"}
          className={"p-2 w-fit mt-5"}
          onClick={() => {
            if (checkFields())
              onComplete(provider
                ? {
                  id: provider.id,
                  name: name,
                }
                : { name: name }
              );
          }}
        />
      </div>
    </div>
  );
};
