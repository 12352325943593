import { FormControlLabel, Slide, Switch } from "@mui/material";
import React, { useState } from "react";
import { toast } from "react-toastify";
import PrimaryButton from "../../../../../components/buttons/primary-button";
import TertiaryButton from "../../../../../components/buttons/tertiary-button";
import TextField from "../../../../../components/input/fields/text-field";

export const NewOwnerForm = ({ onComplete, owner, onCloseModal }) => {
  const [hasVat, setHasVat] = useState(owner ? owner.hasVat : false);
  const [firstName, setFirstName] = useState(owner ? owner.firstName : null);
  const [lastName, setLastName] = useState(owner ? owner.lastName : null);
  const checkValues = () => {
    if ((firstName)) {
      return true;
    }
    toast.error("The name is a mandatory field");
    return false;
  };
  return (
    <div className="flex flex-col justify-start">
      <div>
        <TextField
          label={"Name"}
          defaultValue={firstName}
          placeholder={"Insert..."}
          inputClassName={"p-1 pr-10 mr-10"}
          className={"max-w-xs"}
          onChange={(e) => setFirstName(e.target.value)}
        />
        {!hasVat && (
          <TextField
            label={"Last Name"}
            defaultValue={lastName}
            placeholder={"Insert..."}
            inputClassName={"p-1 pr-10 mr-10"}
            className={"max-w-xs"}
            onChange={(e) => setLastName(e.target.value)}
          />
        )}
        <FormControlLabel
          className="mt-3"
          control={
            <Switch
              value={hasVat}
              checked={hasVat}
              onChange={() => setHasVat(!hasVat)}
            />
          }
          label={"Has VAT"}
        />
      </div>
      <div className="flex justify-end mt-4">
        <TertiaryButton
          onClick={onCloseModal}
          className={"bg-red p-2 w-fit mr-5"}
          content={"Close"}
        />
        <PrimaryButton
          className={"w-fit p-2 "}
          content={owner ? "Edit owner" : "Add New owner"}
          onClick={() => {
            if (checkValues())
              owner
                ? onComplete({
                  id: owner.id,
                  firstName: firstName,
                  lastName: lastName,
                  hasVat: hasVat,
                })
                : onComplete({
                  firstName: firstName,
                  lastName: lastName,
                  hasVat: hasVat,
                });
          }}
        />
      </div>
    </div>
  );
};
