import React, { useContext, useState } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { IoPencil, IoTrashBin, IoPersonAdd } from "react-icons/io5";
import { DataContext } from "..";
import PrimaryButton from "../../../components/buttons/primary-button";
import SecondaryButton from "../../../components/buttons/secondary-button";
import Modal from "../../../components/dialogs/dialog";
import { NewOwnerForm } from "../main-sections/accomodation/pages/new-owner-form";
import { createOwner, deleteOwner, updateOwner } from "../util/firestore-functions";
import InfoButton from "../../../components/buttons/info-button";
import TertiaryButton from "../../../components/buttons/tertiary-button";
import { toast } from "react-toastify";

export const OwnersSettings = () => {
  const context = useContext(DataContext);
  const ownersList = context.ownersList;
  const [selectedOwner, setSelectedOwner] = useState();
  const [createModal, setCreateModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [removeModal, setRemoveModal] = useState(false);

  const handleCreate = (owner) => {
    setSelectedOwner(owner);
    setCreateModal(true);
  }

  const handleEdit = (owner) => {
    setSelectedOwner(owner);
    setEditModal(true);
  };
  const handleRemove = (owner) => {
    setSelectedOwner(owner);
    setRemoveModal(true);
  };
  const closeModal = () => {
    setCreateModal(false);
    setEditModal(false);
  };
  return (
    <>
    
    <InfoButton nameComponent={'owners'}/>
      <div className="flex flex-col content-between w-full h-full mx-10 ">
        <div className="space-y-4 text-3xl font-bold">
          <div className="flex items-center justify-start">
            <h1>Owners Management</h1>
          </div>
          <div className="flex flex-col p-5 space-y-4 font-normal bg-white rounded-lg shadow-md w-fit">
            <div className="my-1 space-y-2">
              <div className="flex flex-row justify-between">
                <div className="text-xl font-bold ">General</div>
                <SecondaryButton content={<IoPersonAdd className="mx-auto" />}
                  className={"p-1 mx-2 max-w-[100px]"}
                  onClick={() =>
                    handleCreate({
                      firstName: '',
                      lastName: '',
                      hasVat: '',
                    })
                  }
                />
              </div>
              <hr className="w-full" />
            </div>
            <div className="z-10 inline-block border rounded-xl">
              {/* Table */}
              <table className="w-full max-w-6xl ">
                {/* Header */}
                <thead className="text-lg">
                  <tr className="border-b-2">
                    <th className="p-4 border-r w-fit">Name</th>
                    <th className="w-48 p-4 border-r">Last name</th>
                    <th className="p-4 border-r w-36">Has VAT</th>
                    <th className="w-52">Actions</th>
                  </tr>
                </thead>
                <tbody className="text-base">
                  {ownersList &&
                    ownersList.map((item) => {
                      return (
                        <tr
                          key={item.id}
                          className="border-b cursor-pointer hover:bg-neutral-100"
                        >
                          <td className="p-2 text-left border-r">
                            {item.data.firstName}
                          </td>
                          <td className="text-center border-r">
                            {item.data.lastName}
                          </td>
                          <td className="text-center border-r">
                            {String(item.data.hasVat)}
                          </td>

                          <td className="">
                            <div className="flex items-center justify-center">
                              <PrimaryButton
                                content={<IoPencil className="mx-auto" />}
                                className={"p-1 mx-2 max-w-[100px]"}
                                onClick={() =>
                                  handleEdit({
                                    id: item.id,
                                    firstName: item.data.firstName,
                                    lastName: item.data.lastName,
                                    hasVat: item.data.hasVat,
                                  })
                                }
                              />
                              <SecondaryButton
                                content={<IoTrashBin className="mx-auto" />}
                                className={"p-1 mx-2 max-w-[100px]"}
                                onClick={() =>
                                  handleRemove({
                                    id: item.id,
                                    firstName: item.data.firstName,
                                    lastName: item.data.lastName,
                                    hasVat: item.data.hasVat,
                                  })
                                }
                              />
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="font-bold absolute bottom-0 right-10 flex items-center justify-end p-4 text-petroil rounded-full ">
  <p className="mr-1">Do you need support?</p>
  <a href="mailto:support@bilemon.com" className="text-petroil hover:brightness-125 rounded-full bg-transparent"
  >
    Contact us.
  </a>
</div>
      </div>
      <Modal
        externalControl
        status={createModal}
        onClose={() => setCreateModal(false)}
        body={
          <NewOwnerForm
          onCloseModal={closeModal}
            onComplete={(newOwner) => {
                createOwner(
                  {
                    firstName: newOwner.firstName,
                    lastName: newOwner.lastName,
                    hasVat: newOwner.hasVat,
                  },
                  context.organizationId
                ).then(() => {
                  context.updateData("owners");
                  setCreateModal(false);
                  setSelectedOwner();
                });
            }}
            owner={selectedOwner}
          />
        }
        title={"Add Owner"}
      />
      <Modal
        externalControl
        status={editModal}
        onClose={() => setEditModal(false)}
        body={
          <NewOwnerForm
          onCloseModal={closeModal}
            onComplete={(newOwner) => {
              if (
                !(JSON.stringify(newOwner) === JSON.stringify(selectedOwner))
              ) {
                updateOwner(
                  {
                    firstName: newOwner.firstName,
                    lastName: newOwner.lastName,
                    hasVat: newOwner.hasVat,
                  },
                  newOwner.id,
                  context.organizationId
                ).then(() => {
                  context.updateData("owners");
                  setEditModal(false);
                  setSelectedOwner();
                });
              }
            }}
            owner={selectedOwner}
          />
        }
        title={"Edit Owner"}
      />
      <Modal
        externalControl
        status={removeModal}
        onClose={() => setRemoveModal(false)}
        body={
          <>
            <h1 className="text-lg">Are you sure to remove this owner?</h1>
            <h2>
              {selectedOwner
                ? selectedOwner.firstName + " " + selectedOwner.lastName
                : ""}
            </h2>
            <h2 className="flex items-end justify-center my-5">
              Every relationship with this owner{" "}
              <p className="ml-2 text-base text-red">will be lost</p>.
            </h2>
            <div className="flex justify-end">
            <TertiaryButton
                className={"bg-slate-400 mr-2 text-white p-2 w-fit max-w-[130px] mt-2"}
                content={"Cancel"}
                onClick={() => {
                  setRemoveModal(false)}}
              />
              <TertiaryButton
                className={"bg-red text-white p-2 w-fit max-w-[130px] mt-2"}
                content={"Yes, I'm sure"}
                onClick={() => {
                  deleteOwner(selectedOwner.id, context.organizationId)
                    .then(() => {
                      context.updateData("owners");
                      setRemoveModal(false);
                      setSelectedOwner();
                    })
                    .catch((error) => toast.error("Error while deleting owner: " + error));
                }}
              />              
            </div>
          </>
        }
        title={"Remove Owner"}
      />
    </>
  );
};
