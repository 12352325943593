import React, { useContext, useEffect, useState } from "react";

import { UilBath, UilUsersAlt, UilMoneyBill, UilBed, UilBedDouble, UilLocationPoint, UilEdit, UilUserCircle, UilBuilding} from "@iconscout/react-unicons";
import SecondaryButton from "../../../../components/buttons/secondary-button";

import Modal from "../../../../components/dialogs/dialog";
import { useNavigate } from "react-router-dom";
import { NewAccomodation } from "./pages/new-accomodation";
import { set } from "date-fns";
import { EditAccomodation } from "./pages/edit-accomodation";
import { DataContext } from "../..";



export const AccomodationCard = ({
  id,
  address,
  maxPeople,
  bathroom,
  singleBed,
  doubleBed,
  tax,
  name,
  owner,
  status,
  numberRooms,
  contractTypology
}) => {
  const [openModal, setOpenModal] = useState(false);
  const ownerList = useContext(DataContext).ownersList;
  let classOpacity = status && status.value === "Disabled" ? "opacity-50" : "opacity-100";
  var ownerString = null;
  if (!useContext(DataContext).loading) {
    ownerString = ownerList.find((object) => {
      return object.id === owner;
    });
  }
  const editHandler = () => {
    setOpenModal(true);
  };

  return (
    <>
      {/* Card */}
      <div
        id={id}
        className={"p-5 bg-white	border-2 rounded-lg shadow-sm border-1 text-black w-[350px] mr-10 mt-5 " + classOpacity}
      >
        {/* Main Section */}
        <div className="flex flex-col space-y-3">
        <div className="flex justify-between">
          <h1 className="text-2xl font-bold">{name}</h1>
          <p className="text-sm font-bold mt-2">{!status ? "Active" : status.value }</p></div>
          <h3 className="flex items-start text-base min-h-[50px]">
            <UilLocationPoint className="w-5 h-auto mr-2 text-petroil" />
            {(address.street ? address.street + ", " : " ") +  (address.city ? address.city : " ")  +
             (address.state ? ", " + address.state : " ") +  (address.cap ? " - " + address.cap : " ")}
          </h3>
          <hr className="w-full" />
        </div>
        {/* Info Section */}
        <div className="my-4 space-y-2 text-base">
        <h3 className="flex items-center font-semibold">
            <UilBuilding className="w-5 h-auto mr-2 text-petroil" />
            Rooms: <p className="ml-1 font-normal">{numberRooms}</p>
          </h3>
          <h3 className="flex items-center font-semibold">
            <UilUsersAlt className="w-5 h-auto mr-2 text-petroil" />
            Max People: <p className="ml-1 font-normal">{maxPeople}</p>
          </h3>
          <h3 className="flex items-center font-semibold">
            <UilBath className="w-5 h-auto mr-2 text-petroil" />
            Bathrooms: <p className="ml-1 font-normal">{bathroom}</p>
          </h3>
          <h3 className="flex items-center font-semibold">
            <UilBed className="w-5 h-auto mr-2 text-petroil" />
            Single beds: <p className="ml-1 font-normal">{singleBed}</p>
          </h3>
          <h3 className="flex items-center font-semibold">
            <UilBedDouble className="w-5 h-auto mr-2 text-petroil" />
            Double beds: <p className="ml-1 font-normal">{doubleBed}</p>
          </h3>
          <h3 className="flex items-center font-semibold">
            <UilMoneyBill className="w-5 h-auto mr-2 text-petroil" />
            Contract Typology:  <p className="ml-1 font-normal"> {contractTypology ? contractTypology.label : ""}</p>
          </h3>
        </div>
        <hr className="w-full mt-8" />
        {/* Button Section */}
        <div className="flex items-center justify-between mt-4">
          <SecondaryButton
            className={"py-2 w-fit px-5 max-w-[50px]"}
            content={
              <h1 className="flex items-center justify-center text-sm">
                <UilEdit className="w-5 h-auto mr-1" /> Edit
              </h1>
            }
            onClick={editHandler}
          />
          <div className="flex items-center space-x-1 text-base">
            <UilUserCircle className={"text-petroil w-6 h-auto"} />{" "}
            <h3>
              {ownerString &&
                ownerString.data.lastName + " " + ownerString.data.firstName}
            </h3>
          </div>
        </div>
      </div>
      <Modal
        externalControl
        status={openModal}
        onClose={() => setOpenModal(false)}
        title={"Edit accommodation"}
        className={"w-[500px]"}
        body={
          <EditAccomodation
            accomodationInfo={{
              id,
              address,
              maxPeople,
              bathroom,
              singleBed,
              doubleBed,
              tax,
              name,
              owner,
              status,
              contractTypology,
              numberRooms
            }}
            onClose={() => setOpenModal(false)}
          />
        }
      />
    </>
  );
};
