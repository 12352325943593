import React, { useContext, useState } from "react";
import { CurrencyField } from "../../../../../components/input/fields/currency-field";
import { RangeDatePicker } from "../../../../../components/input/fields/date-pickers/multiple";
import { DatePicker } from "../../../../../components/input/fields/date-pickers/single";
import { UnitField } from "../../../../../components/input/fields/unit-field";
import { FaAirbnb } from "react-icons/fa";
import { TbBrandBooking } from "react-icons/tb";
import { PercentField } from "../../../../../components/input/fields/percent-field";
import PrimaryButton from "../../../../../components/buttons/primary-button";
import SecondaryButton from "../../../../../components/buttons/secondary-button";
import { useNavigate } from "react-router-dom";
import { SelectMenu } from "../../../../../components/input/select/select-menu";
import { DataContext } from "../../..";
import { toast } from "react-toastify";
import { createBooking, createCost } from "../../../util/firestore-functions";
export const NewBooking = () => {
  const context = useContext(DataContext);
  const [bookingMenu, setBookingMenu] = useState(false);
  const [checkDatesMenu, setCheckDatesMenu] = useState(false);
  const [bookingChannelOpt, setBookingChannelOpt] = useState(null);
  const [commissionChannelSel, setCommissionChannelSel] = useState();


  const navigator = useNavigate();
  const [formsValues, setFormsValues] = useState({
    bookingDate: null,
    checkInDate: null,
    checkOutDate: null,
    numberOfGuest: null,
    amount: null,
    accomodationReference: null,
    commissionChannel: null,
    commissionAmount: null,
    commissionType: null
  });

  var options = context.loading
    ? null
    : context.accomodationsList &&
    context.accomodationsList.map((item) => {
      return { label: item.data.name, value: item.id };
    });



  const submit = () => {
    if (
      !(
        formsValues.bookingDate &&
        formsValues.checkInDate &&
        formsValues.checkOutDate &&
        formsValues.accomodationReference &&
        formsValues.numberOfGuest != null &&
        formsValues.amount != null
      )
    ) {
      toast.error("Check required fields");
      return;
    }

    if (formsValues.bookingDate > formsValues.checkInDate) {
      toast.error("The booking date must be shorter than Check-in date");
      return;
    }
    if (formsValues.checkInDate > formsValues.checkOutDate) {
      toast.error("The Check-in date must be shorter than Check-out date");
      return;
    }

    if (formsValues.amount == 0 || formsValues.numberOfGuest == 0) {
      toast.error("Invalid amount or number of guest");
      return;
    }

    let commissionCostRule = context.costsRuleList && context.costsRuleList.filter(x => x.data.costTypology.value === 2 && x.data.costCategory.value === "ota-commission" && x.data.name === formsValues.commissionChannel && (!x.data.accomodationList || (x.data.accomodationList && x.data.accomodationList.indexOf(formsValues.accomodationReference) > -1)) && (!x.data.endDate || (x.data.endDate && new Date() < x.data.endDate.toDate())));
    if (commissionCostRule.length > 0) {
      if (commissionCostRule[0].data.paymentType.value === "Fixed") {
        formsValues.commissionAmount = commissionCostRule[0].data.amount;
        formsValues.commissionType = { label: "Fixed", value: null };
      }
      else if (commissionCostRule[0].data.paymentType.value === "Percentage") {
        formsValues.commissionAmount = parseFloat(((commissionCostRule[0].data.amount * formsValues.amount) / 100).toFixed(2));
        formsValues.commissionType = { label: "Percentage", value: commissionCostRule[0].data.amount };
      }
    }
    createBooking(formsValues, context.organizationId)
      .then(async (bookingId) => {
        navigator("../bookings");
        context.updateData("bookings");
        if (formsValues.commissionChannel) {
          //Adding ota commission cost
          if (commissionCostRule.length > 0) {
            commissionCostRule = commissionCostRule[0];
            let commissionBooking = 0;
            if (commissionCostRule.data.paymentType.value === "Fixed") {
              commissionBooking = commissionCostRule.data.amount;
            }
            else if (commissionCostRule.data.paymentType.value === "Percentage") {
              commissionBooking = parseFloat(((commissionCostRule.data.amount * formsValues.amount) / 100).toFixed(2));
            }
            let paymentDate = formsValues.bookingDate;
            if (commissionCostRule.data.paymentDateType) {
              if (commissionCostRule.data.paymentDateType.value == "check-in") {
                paymentDate = formsValues.checkInDate;
              }
              else if (commissionCostRule.data.paymentDateType.value == "check-out") {
                paymentDate = formsValues.checkOutDate;
              }
            }

            try {
              await createCost(
                {
                  name: formsValues.commissionChannel,
                  costDate: paymentDate,
                  paymentDate: paymentDate,
                  amount: commissionBooking,
                  costTypology: { label: "Booking", value: 2 },
                  costCategory: commissionCostRule.data.costCategory,
                  accomodationList: [formsValues.accomodationReference],
                  bookingRef: bookingId,
                  costRuleRef: commissionCostRule.id,
                  supplierId: commissionCostRule.data.supplierId ? commissionCostRule.data.supplierId : null,
                },
                context.organizationId
              )
            }
            catch (e) {

            }
          }
        }
        // Adding other costs
        const costRulesAccomodation = context.costsRuleList ? context.costsRuleList.filter(x => x.data.costTypology.value === 2 && x.data.costCategory.value != "ota-commission" && (!x.data.accomodationList || (x.data.accomodationList && x.data.accomodationList.indexOf(formsValues.accomodationReference) > -1)) && (!x.data.endDate || (x.data.endDate && new Date() < x.data.endDate.toDate()))) : [];
        for (let i = 0; i < costRulesAccomodation.length; i++) {
          try {
            let commissionAccomodation = 0;
            if (costRulesAccomodation[i].data.paymentType.value === "Fixed") {
              commissionAccomodation = costRulesAccomodation[i].data.amount;
            } else if (costRulesAccomodation[i].data.paymentType.value === "Percentage") {
              commissionAccomodation = parseFloat(((costRulesAccomodation[i].data.amount * formsValues.amount) / 100).toFixed(2));
            }
            let paymentDate = formsValues.bookingDate;
            if (costRulesAccomodation[i].data.paymentDateType) {
              if (costRulesAccomodation[i].data.paymentDateType.value == "check-in") {
                paymentDate = formsValues.checkInDate;
              }
              else if (costRulesAccomodation[i].data.paymentDateType.value == "check-out") {
                paymentDate = formsValues.checkOutDate;
              }
            }
            await createCost(
              {
                name: costRulesAccomodation[i].data.name,
                costDate: paymentDate,
                paymentDate: paymentDate,
                amount: commissionAccomodation,
                costTypology: { label: "Booking", value: 2 },
                costCategory: costRulesAccomodation[i].data.costCategory,
                accomodationList: [formsValues.accomodationReference],
                bookingRef: bookingId,
                costRuleRef: costRulesAccomodation[i].id,
                supplierId: costRulesAccomodation[i].data.supplierId ? costRulesAccomodation[i].data.supplierId : null,
              },
              context.organizationId
            )
          } catch (e) {

          }
        }
        toast.success("Automatic costs added for the booking");
        context.updateData("costs");

      })
      .catch((err) => 
      {
        toast.error(err);
      });
  };

  return (
    <div className="w-full">
      <div className="mb-10">
        <h1 className="text-3xl">New Booking</h1>
      </div>
      <div className="w-full max-w-5xl p-10 bg-white border-2 rounded-lg shadow-md">
        <h2 className="text-2xl">Booking details</h2>
        <hr className="my-4 " />
        <div className="flex flex-wrap justify-start ml-auto mr-auto h-fit">
          <DatePicker
            className={"w-[250px] m-1 mr-10"}
            inputClassName={"p-[5px] font-normal"}
            placeholder={"Booking date"}
            label={"Booking Date *"}
            onClick={() => {
              setBookingMenu(!bookingMenu);
              setCheckDatesMenu(false);
            }}
            onCompleted={(value) => {
              setBookingMenu(false);
              formsValues.bookingDate = value;
            }}
            menu={bookingMenu}
          />
          <RangeDatePicker
            className={"w-[400px] m-1"}
            inputClassName={"p-[5px] font-normal"}
            label={"Check-In/Out dates *"}
            placeholder={"Select the Check-In/Out dates"}
            onClick={() => {
              setCheckDatesMenu(!checkDatesMenu);
              setBookingMenu(false);
            }}
            menu={checkDatesMenu}
            onCompleted={(value) => {
              setCheckDatesMenu(false);
              formsValues.checkInDate = value.from;
              formsValues.checkOutDate = value.to;
            }}
          />
          <div className="flex flex-wrap justify-between w-full mt-10 font-normal">
            <div className="flex items-center mr-9 space-x-9">
              <UnitField
                label={"N° Guests *"}
                inputClassName={"ml-1 max-w-[100px] text-left p-[5px]"}
                className={"max-w-fit"}
                defaultValue={formsValues.numberOfGuest}
                placeholder={"0"}
                onChange={(e) =>
                  (formsValues.numberOfGuest = Number(e.target.value))
                }
              />
              <CurrencyField
                className={""}
                label={"Amount *"}
                placeholder={"0"}
                value={formsValues.amount}
                inputClassName={"p-[5px]"}
                onChange={(e) => (formsValues.amount = Number(e.target.value))}
              />
            </div>
            <SelectMenu
              className={"max-w-fit mr-auto"}
              label={"Accommodation *"}
              placeholder={"Select accommodation"}
              options={options}
              isLoading={context.loading}
              isDisabled={context.loading}
              isMulti={false}
              isSearchable
              onChange={(value) => {
                
                formsValues.accomodationReference = value.value;
                const bookingChannelOptTemp = new Array();
 
                context.loading ? null : context.costsRuleList && context.costsRuleList.map((rule) => {
                const bookingChannel = new Object();
                const costRelated = rule.data.accomodationList ? rule.data.accomodationList.filter((x) => x === value.value) : [];

                                  
                  if (rule.data.costTypology.value === 2 && rule.data.costCategory.value === "ota-commission" && costRelated.length > 0 && (!rule.data.endDate || (rule.data.endDate && new Date() < rule.data.endDate.toDate()))) {
                    bookingChannel.label = <div className="flex items-center justify-start space-x-5 w-fit"><p className="text-base">{rule.data.name}</p></div>;
                    bookingChannel.value = rule.data.name;
                    bookingChannelOptTemp.push(bookingChannel);
                  }
                });
                setBookingChannelOpt(bookingChannelOptTemp);
                setCommissionChannelSel(null);
              }
              }
            />
          </div>
        </div>
        <h2 className="mt-10 text-2xl">Commission details</h2>
        <hr className="my-4 " />
        <div className="flex flex-wrap justify-start font-normal w-fit h-fit">
          <SelectMenu
            className={"max-w-fit mr-16"}
            label={"Booking Channel"}
            isClearable
            value={commissionChannelSel}
            options={bookingChannelOpt}
            onChange={(el) => {
              if (el) {
                setCommissionChannelSel(el);
                formsValues.commissionChannel = el.value;
              } else {
                setCommissionChannelSel(null);
                formsValues.commissionChannel = null;
                // formsValues.commissionRate = null;
                formsValues.fixedCommission = null;
              }
            }}
            inputClassName={"min-w-[250px] max-w-fit p-2"}

          />
        </div>
      </div>
      <div className="flex justify-end w-full max-w-5xl">
        <SecondaryButton
          content={"Go back"}
          className={"mt-10 p-3 max-w-[16rem] mr-10"}
          onClick={() => navigator("../bookings")}
        />
        <PrimaryButton
          content={"Add booking"}
          className={"mt-10 p-3 max-w-[16rem]"}
          onClick={() => {
            submit();
          }}
        />
      </div>
    </div>
  );
};

export default NewBooking;