import React, { useContext, useRef, useState } from "react";
import SecondaryButton from "../../../../../components/buttons/secondary-button";
import { CurrencyField } from "../../../../../components/input/fields/currency-field";
import { DatePicker } from "../../../../../components/input/fields/date-pickers/single";
import TextField from "../../../../../components/input/fields/text-field";
import PrimaryButton from "../../../../../components/buttons/primary-button";
import TertiaryButton from "../../../../../components/buttons/tertiary-button";
import { UnitField } from "../../../../../components/input/fields/unit-field";
import { useNavigate } from "react-router-dom";
import { SelectMenu } from "../../../../../components/input/select/select-menu";
import Modal from "../../../../../components/dialogs/dialog";
import { toast } from "react-toastify";
import { format } from "date-fns";
import {
    setSupplier,
    updateCostRule,
    setBookingProvider
} from "../../../util/firestore-functions";
import { DataContext } from "../../..";
import { MdOutlinePostAdd } from "react-icons/md";
import { NewSupplier } from "../pages/new-supplier";
import { NewBookingProvider } from "../pages/new-booking-provider";
import { Checkbox, FormControlLabel } from "@mui/material";
import SubCategorySelect from "../components/sub-category-select";
import { NewDatePicker } from "../../../../../components/input/fields/date-pickers/newDatePicker";
import { FaPray } from "react-icons/fa";
import { PercentField } from "../../../../../components/input/fields/percent-field";
import CostEdit from "../../costs/components/CostEdit";

const CostRuleEdit = ({ selectedCostRule, onClose }) => {
    const context = useContext(DataContext);
    const accommodationsList = context.accomodationsList;
    const [costTypology, setCostTypology] = useState(selectedCostRule.costTypology);
    const [costCategory, setCostCategory] = useState(selectedCostRule.costCategory);
    const [selectedSecondaryMenu, setSelectedSecondaryMenu] = useState(accommodationsList.filter(x => selectedCostRule.accomodationList && selectedCostRule.accomodationList.filter(accomodation => x.id == accomodation).length > 0).map(y => { return { label: y.data.name, value: y.id }; }));
    const [selectedBookingProvider, setSelectedBookingProvider] = useState({ label: selectedCostRule.name, value: selectedCostRule.name });
    const [openNewBookingProviderModal, setOpenNewBookingProviderModal] = useState();
    const [openNewSupplierModal, setOpenNewSupplierModal] = useState();
    const [paymentType, setPaymentType] = useState(selectedCostRule.paymentType);
    const [recurrencyType, setRecurrencyType] = useState(selectedCostRule.recurrency ? selectedCostRule.recurrency.value : null);
    const [supplier, setSupplierForm] = useState(context.suppliesList && selectedCostRule.supplierId ? context.suppliesList.filter(x => x.id === selectedCostRule.supplierId).map((item) => {
        return { label: item.data.name + (item.data.category ? " (" + item.data.category + ")" : ""), value: item.id };
    })[0] : null);
    const [paymentDateType, setPaymentDateType] = useState(selectedCostRule.paymentDateType ? selectedCostRule.paymentDateType.value : null);
    const [subcategory, setSubcategory] = useState(selectedCostRule.name || "");
    var newCategory = null;
    function getPaymentType(formValues, selectedCostRule) {
        if (formValues && formValues.paymentType && formValues.paymentType.value != null) {
            return formValues.paymentType.value;
        } else if (selectedCostRule && selectedCostRule.paymentType && selectedCostRule.paymentType.value != null) {
            return selectedCostRule.paymentType.value;
        } else {
            // Gestisci il caso in cui entrambi i valori sono null
            return {
                label: "Percentage",
                value: "Percentage"
            };
        }
    }
    const closeModal = () => {
        setOpenNewSupplierModal(false);
      };
    const [formValues, setFormValues] = useState({
        costrulename: selectedCostRule.costrulename,
        name: selectedCostRule.name,
        amount: selectedCostRule.amount,
        costTypology: selectedCostRule.costTypology,
        costCategory: selectedCostRule.costCategory,
        accomodationList: selectedCostRule.accomodationList,
        recurrency: selectedCostRule.recurrency,
        recurrencyCustom: selectedCostRule.recurrencyCustom,
        paymentDateType: selectedCostRule.paymentDateType,
        supplierId: selectedCostRule.supplierId,
        paymentType: selectedCostRule.paymentType,
        creationDate: selectedCostRule.creationDate.toDate(),
        executionDate: selectedCostRule.executionDate ? selectedCostRule.executionDate.toDate() : null,
        endDate: selectedCostRule.endDate ? selectedCostRule.endDate.toDate() : null
    });
    const navigator = useNavigate("");
    const costTypologyOptions = [
        {
            label: "Accommodation",
            value: 0,
        },
        {
            label: "Company",
            value: 1,
        },
        {
            label: "Booking",
            value: 2,
        },
    ];

    const paymentTypeOptions = [
        {
            label: "Fixed",
            value: "Fixed",
        },
        {
            label: "Percentage",
            value: "Percentage",
        }
    ];

    const recurrencyOption = [
        {
            label: "Daily",
            value: "daily"
        },
        {
            label: "Weekly",
            value: "weekly"
        },
        {
            label: "Monthly",
            value: "monthly",
        },
        {
            label: "Yearly",
            value: "yearly",
        },
        {
            label: "Custom",
            value: "custom"
        }
    ];

    const costDateOption = [
        {
            label: "Booking date",
            value: null
        },
        {
            label: "Check-In date",
            value: "check-in"
        },
        {
            label: "Check-Out date",
            value: "check-out",
        }
    ];

    const checkFields = () => {
        var regexNumbers = /^[0-9]*(\.[0-9]{0,2})?$/;
        if (
            formValues.costrulename &&
            formValues.amount &&
            formValues.costTypology &&
            formValues.costCategory &&
            formValues.paymentType
        ) {
            if (!regexNumbers.test(formValues.amount)) {
                toast.error("Check amount field");
                return;
            }
            if (formValues.costTypology.value !== 2) {
                if (!formValues.recurrency) {
                    toast.error("Check all required fields");
                    return false;
                }
                if (formValues.recurrency.value == "custom") {
                    if (!formValues.recurrencyCustom) {
                        toast.error("Custom recurrency is required");
                        return false;
                    }
                    else if (formValues.recurrencyCustom <= 0) {
                        toast.error("Custom recurrency must be greater than 0");
                        return false;
                    }
                    if (formValues.endDate && formValues.executionDate && formValues.endDate <= formValues.executionDate) {
                        toast.error("End date must be greater than Recurrency start date");
                        return false;
                    }
                    if (formValues.executionDate && formValues.executionDate < new Date()) {
                        toast.error("The recurrency date must be greater than today");
                        return false;
                    }
                    
                }
                if (formValues.accomodationList.length == 0) {
                    toast.error("This cost rule needs an accommodation");
                    return false;
                }
            }
            return true;
        } else {
            toast.error("Check all required fields");
            return false;
        }
    };

    const setExecutionDate = () => {
        if (!formValues.executionDate) {
            formValues.executionDate = new Date();
            switch (formValues.recurrency.value) {
                case "daily":
                    formValues.executionDate.setDate(formValues.executionDate.getDate() + 1);
                    break;
                case "weekly":
                    formValues.executionDate.setDate(formValues.executionDate.getDate() + 7);
                    break;
                case "monthly":
                    formValues.executionDate.setMonth(formValues.executionDate.getMonth() + 1);
                    break;
                case "yearly":
                    formValues.executionDate.setFullYear(formValues.executionDate.getFullYear() + 1);
                    break;
                case "custom":
                    formValues.executionDate.setDate(formValues.executionDate.getDate() + formValues.recurrencyCustom);
                    break;
            }
        }
    };

    const submit = () => {
        if (!checkFields()) {
            return;

        } else {
            updateCostRule(formValues, selectedCostRule.id, context.organizationId)
            .then(() => {
                if (formValues.costTypology.value !== 2) {
                    setExecutionDate();
                } else {
                    formValues.executionDate = null;
                }
                context.updateData("cost-rules");
                onClose();
            });
        }
    }

    let checkPaymentType = getPaymentType(formValues, selectedCostRule);

    return (
        <>
            <div className="w-full p-5 ">

                <div className="flex flex-col justify-between ml-auto h-fit">

                    <div className=" flex-wrap items-center ">
                        <TextField
                            label={"Cost rule name *"}
                            placeholder={"Insert the cost rule"}
                            className={"max-w-xs m-1"}
                            inputClassName={"p-1"}
                            onChange={(e) => (formValues.costrulename = e.target.value)}
                            defaultValue={formValues.costrulename}
                        />
                    </div>
                    <div className="flex mb-4">
                        <SelectMenu
                            label={"Cost typology *"}
                            className={"max-w-xs mr-10 min-w-[320px]"}
                            isSearchable={false}
                            value={formValues.costTypology}
                            options={costTypologyOptions}
                            placeholder={"Select type of cost"}
                            onChange={(value) => {
                                formValues.costTypology = value;
                                formValues.accomodationList = null;
                                formValues.costCategory = null;
                                formValues.recurrency = null;
                                formValues.recurrencyCustom = null;
                                formValues.paymentDateType = null;
                                setCostCategory(null);
                                setCostTypology(value);
                                setSelectedSecondaryMenu(null);
                                setRecurrencyType(null);
                                setPaymentDateType(null);
                                if (value.value !== 2) {
                                    formValues.paymentType = {
                                        label: "Fixed",
                                        value: "Fixed",
                                    };
                                    setPaymentType("Fixed");
                                }
                                else {
                                    formValues.paymentType = null;
                                    setPaymentType(null);
                                }
                            }}
                        />
                        {costTypology && costTypology.value != 1 && (
                            
                            <SelectMenu
                                label={
                                    "Select your accommodations *" //+ costTypology.label.toLowerCase() + "s *"
                                }
                                isClearable
                                className={" max-w-xs  "}
                                isMulti={/* changed ever in true --> costTypology.value == 0 ? true : false*/ true}
                                isSearchable
                                onChange={(value) => {
                                    if (formValues.costTypology.value == 0) {
                                        formValues.accomodationList = value.map((item) => {
                                            return item.value;
                                        });
                                    }

                                    if (formValues.costTypology.value == 2) {
                                        formValues.accomodationList = value.map((item) => {
                                            return item.value;
                                        });
                                    }
                                    setSelectedSecondaryMenu(value);
                                }}
                                value={selectedSecondaryMenu}
                                options={
                                    accommodationsList &&
                                    accommodationsList.map((item) => {
                                        return { label: item.data.name, value: item.id };
                                    })
                                }
                            />
                        )}
                    </div>
                    <div className="flex flex-wrap items-end ">
                        <SubCategorySelect
                            className={"max-w-xs min-w-[250px]"}
                            type={costTypology}
                            onChange={(e) => {
                                formValues.costCategory = e;
                                const bookingProvider = context.bookingProvidersList.find(x => x.data.name === selectedCostRule.name);
                                if (formValues.costTypology && formValues.costTypology.value === 2 && e.value === "ota-commission" && bookingProvider) {
                                        formValues.name = selectedCostRule.name;
                                        setSubcategory(selectedCostRule.name);
                                        setSelectedBookingProvider({ label: selectedCostRule.name, value: selectedCostRule.name });
                                }
                                else {
                                    formValues.name = null;
                                    setSubcategory("");
                                    setSelectedBookingProvider(null);
                                }
                                setCostCategory(e);
                            }}
                            value={costCategory}
                        />
                        {costCategory && costCategory.value === "ota-commission" && (
                            <React.Fragment>
                                <SelectMenu
                                    label={"Booking Provider *"}
                                    className={"max-w-xs mr-5 ml-10"}
                                    value={selectedBookingProvider}
                                    options={
                                        context.bookingProvidersList
                                            ? context.bookingProvidersList.map((item) => {
                                                return {
                                                    label: item.data.name,
                                                    value: item.data.name,
                                                };
                                            })
                                            : null
                                    }
                                    onChange={(el) => {
                                        formValues.name = el.value;
                                        setSelectedBookingProvider(el);
                                    }}
                                />
                                <SecondaryButton
                                    content={<MdOutlinePostAdd className="w-6 h-auto" />}
                                    className={"w-fit p-2 mb-2 max-w-[30px]"}
                                    onClick={() => setOpenNewBookingProviderModal(true)}
                                />
                            </React.Fragment>
                        )}
                        {costCategory && costCategory.value !== "ota-commission" && (
                            <TextField
                                label={"Sub-Category "}
                                placeholder={"Insert the sub-category "}
                                className={"max-w-xs ml-10"}
                                inputClassName={"p-1"}
                                value={formValues.name}
                                onChange={(e) => {
                                    formValues.name = e.target.value;
                                    setSubcategory(e.target.value);
                                }}
                                notEditableValue={subcategory}
                            />
                        )}

                        <div className="flex items-end  w-full mt-3">
                            <SelectMenu
                                label={"Supplier"}
                                isClearable
                                className={"max-w-xs mr-5"}
                                value={supplier}
                                options={
                                    context.suppliesList
                                        ? context.suppliesList.map((item) => {
                                            return {
                                                label:
                                                    item.data.name +
                                                    (item.data.category
                                                        ? " (" + item.data.category + ")"
                                                        : ""),
                                                value: item.id,
                                            };
                                        })
                                        : null
                                }
                                onChange={(el) => {
                                    if (el) {
                                        formValues.supplierId = el.value;
                                        setSupplierForm(
                                            context.suppliesList && formValues.supplierId 
                                                ? context.suppliesList.filter(x => x.id === el.value).map((item) => {
                                                    return { 
                                                        label: item.data.name + (item.data.category ? " (" + item.data.category + ")" : ""), 
                                                        value: item.id 
                                                    };
                                                  })[0] 
                                                : null
                                        );
                                    } else {
                                        formValues.supplierId = null;
                                        setSupplierForm(null);
                                    }
                                }
                                }
                            />
                            <SecondaryButton
                                content={<MdOutlinePostAdd className="w-6 h-auto" />}
                                className={"w-fit p-2 mb-2 max-w-[30px]"}
                                onClick={() => setOpenNewSupplierModal(true)}
                            />
                        </div>
                        {costTypology && costTypology.value === 2 && (
                            <div className="flex items-end  w-full mt-3">
                                <SelectMenu
                                    label={"Trigger"}
                                    className={"max-w-xs"}
                                    defaultValue={formValues.paymentDateType}
                                    options={costDateOption}
                                    onChange={(el) => {
                                        if (el.value)
                                            formValues.paymentDateType = el;
                                        else
                                            formValues.paymentDateType = null;
                                        setPaymentDateType(el.value);
                                    }}
                                />
                            </div>
                        )}
                        {costTypology && costTypology.value !== 2 && (
                            <div className="flex items-end  w-full mt-3">
                                <SelectMenu
                                    label={"Recurrency *"}
                                    className={"max-w-xs mr-10"}
                                    value={formValues.recurrency}
                                    options={recurrencyOption}
                                    onChange={(el) => {
                                        formValues.recurrency = el;
                                        if (el.value !== "custom")
                                            formValues.recurrencyCustom = null;
                                        setRecurrencyType(el.value);
                                    }}
                                />
                                {recurrencyType == "custom" && (
                                    <UnitField
                                        label={"Number of days *"}
                                        inputClassName={"w-[150px] p-1 mb-2 mr-10"}
                                        placeholder={0}
                                        value={formValues.recurrencyCustom}
                                        className={"w-fit"}
                                        onChange={(e) =>
                                            (formValues.recurrencyCustom = Number(e.target.value))
                                        }
                                    />
                                )}
                                <NewDatePicker
                                    className={"w-[200px]"}
                                    label={"Execution date"}
                                    placeholder={"Execution Date"}
                                    defaultValue={formValues.executionDate}
                                    onCompleted={(el) => {
                                        formValues.executionDate = el ? el : null
                                    }}
                                />
                            </div>
                        )}
                    </div>

                    {costTypology && costTypology.value === 2 && (
                        <div className="flex flex-wrap mt-5">
                            <SelectMenu
                                label={"Payment type *"}
                                className={"max-w-xs mr-10"}
                                isSearchable={false}
                                options={paymentTypeOptions}
                                value={formValues.paymentType}
                                placeholder={"Select type of payment"}
                                onChange={(value) => {
                                    formValues.paymentType = value;
                                    setPaymentType(value.value);
                                }}
                            />

                            {checkPaymentType === "Percentage" ?
                                <PercentField
                                    className={"max-w-xs mr-10"}
                                    label={"Payment amount *"}
                                    inputClassName={"p-[5px] w-[150px]"}
                                    value={formValues.amount}
                                    onChange={(value) =>
                                        (formValues.amount = Number(value.target.value))
                                    }
                                /> :
                                <CurrencyField
                                    label={"Payment amount *"}
                                    className={"max-w-xs mr-5"}
                                    inputClassName={"p-1"}
                                    value={formValues.amount}
                                    onChange={(value) =>
                                        (formValues.amount = Number(value.target.value))
                                    }
                                />}
                            <NewDatePicker
                                className={"w-[200px]"}
                                label={"End date"}
                                placeholder={"End Date"}
                                defaultValue={formValues.endDate}
                                onCompleted={(el) => {
                                    formValues.endDate = el ? el : null
                                }}
                            />
                        </div>
                    )}
                    {costTypology && costTypology.value !== 2 && (
                        <div className="flex flex-wrap mt-5">
                            {checkPaymentType == "Percentage" ?
                                <PercentField
                                    label={"Payment amount *"}
                                    className={"max-w-xs mr-5"}
                                    inputClassName={"p-1"}
                                    value={formValues.amount}
                                    onChange={(value) =>
                                        (formValues.amount = Number(value.target.value))
                                    }
                                /> :
                                <CurrencyField
                                    label={"Payment amount *"}
                                    className={"max-w-xs mr-5"}
                                    inputClassName={"p-1"}
                                    value={formValues.amount}
                                    onChange={(value) =>
                                        (formValues.amount = Number(value.target.value))
                                    }
                                />}
                            <NewDatePicker
                                className={"w-[200px] ml-7"}
                                label={"End date"}
                                placeholder={"End Date"}
                                defaultValue={formValues.endDate}
                                onCompleted={(el) => {
                                    formValues.endDate = el ? el : null
                                }}
                            />
                        </div>
                    )}
                </div>
                                
                <div className="flex justify-end w-full max-w-5xl mt-4">
                    <TertiaryButton
                      onClick={onClose}
                      className={"bg-red h-auto p-2 w-fit mr-5 max-w-[80px]"}
                      content={ "Close "}
                    />
                    <PrimaryButton
                        content={"Edit Cost Rule"}
                        className={" p-3 max-w-[16rem]"}
                        onClick={() => {
                            if (!checkFields()) {
                                return;                              
                            }
                            submit();
                        }}
                    />
                </div>
            </div>

            <Modal
        externalControl
        status={openNewSupplierModal}
        onClose={() => setOpenNewSupplierModal(false)}
        body={
          <NewSupplier
            onCloseModal={closeModal}
            onComplete={(value) => {
              setOpenNewSupplierModal(false);
              setSupplier(value, context.organizationId);
              context.updateData("suppliers");
            }}
          />
        }
        title={"New Supplier"}
        className={"w-fit"}
      />

            <Modal
                externalControl
                status={openNewBookingProviderModal}
                onClose={() => setOpenNewBookingProviderModal(false)}
                body={
                    <NewBookingProvider
                        onComplete={(value) => {
                            setOpenNewBookingProviderModal(false);
                            setBookingProvider(value, context.organizationId);
                            context.updateData("booking-providers");
                        }}
                    />
                }
                title={"New Booking Provider"}
            />
        </>
    );
};

export default CostRuleEdit;
