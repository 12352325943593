import React from "react";

function MiniLoadingApp() {
  return (
    <div className="flex items-center justify-center w-full min-h-[30vh] bg-trasparent">
      <div className="animate-bounce">
        <img className="w-full h-auto" src="/Icon.png" />
      </div>
    </div>
  );
}

export default MiniLoadingApp;
